import { Form } from 'formik';
import styled, { css } from 'styled-components';

import FlexDiv from 'components/FlexDiv';
import FormField from 'components/FormField';

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;

export const Content = styled(Form)`
  ${contentStyles}
`;

export const ButtonsContainer = styled(FlexDiv)`
  column-gap: 24px;
`;

export const StyledFormField = styled(FormField)`
  width: 372px;
  margin-bottom: 24px;
`;

export const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  margin-top: 24px;
`;
