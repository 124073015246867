import React, { memo } from 'react';

import usePrefix from 'utils/usePrefix';

import Search from 'components/Search';
import PageTitle from 'components/PageTitle';

import { Wrapper, ListHeaderStyled } from './styles';

interface Props {
  children?: React.ReactNode;
  title: string;
  filter?: string;
  setFilter?: (val: string) => void;
  resetFilter?: () => void;
  isSearchClearDisabled?: boolean;
}

const ListContainer: React.FC<Props> = ({
  title,
  filter,
  setFilter,
  children,
  resetFilter,
  isSearchClearDisabled,
}) => {
  const t = usePrefix('General');

  return (
    <Wrapper>
      <ListHeaderStyled>
        <PageTitle title={title} />
        {setFilter && (
          <Search
            name="filter"
            placeholder={t('search')}
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
            onClear={resetFilter}
            isDisabled={isSearchClearDisabled}
            isResetShow={!isSearchClearDisabled}
          />
        )}
      </ListHeaderStyled>
      {children}
    </Wrapper>
  );
};
export default memo(ListContainer);
