import React, { FC, memo } from 'react';

import Prompt from 'components/Prompt';
import usePrefix from 'utils/usePrefix';

const SavedPasswordPrompt: FC = () => {
  const t = usePrefix('Login');

  return <Prompt text={t('link_sent')} />;
};

export default memo(SavedPasswordPrompt);
