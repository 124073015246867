import React, { useState } from 'react';
import { FormikProps } from 'formik';

import { useApp } from 'App';
import { ActionTypes } from 'App/types';

import usePrefix from 'utils/usePrefix';
import { PostUserRequest } from 'utils/api/users';
import { userRoles } from 'utils/userRoles';
import { getApiUrl, api, ResponseError } from 'utils/api';
import { notificationTypes } from 'utils/constants';
import { languages } from 'utils/languages';

import RadioInput from 'components/RadioInput';
import Button from 'components/_Redesign/Button';
import DetailsTitle from 'components/DetailsTitle';
import ConfirmModal from 'components/ConfirmModal';
import {
  Content,
  ButtonsContainer,
  FieldsContainer,
  StyledInputField,
  StyledFormField,
} from './styles';
import FlexDiv from 'components/FlexDiv';

const url = getApiUrl('/users');

interface Props {
  onClose: () => void;
  userId?: number;
  isActive?: boolean;
  fetchData: () => Promise<any>;
}

const DetailsForm: React.FC<
  Pick<FormikProps<PostUserRequest>, 'errors' | 'touched' | 'isSubmitting' | 'values'> & Props
> = ({ errors, touched, isSubmitting, onClose, values, userId, isActive, fetchData, ...props }) => {
  const [, dispatch] = useApp();

  const [isModalOpen, setModal] = useState(false);

  const t = usePrefix('General');
  const userName = `${values.first_name} ${values.last_name}`;
  // eslint-disable-next-line no-magic-numbers
  const isUserPlaceholder = values.first_name.length < 1 && values.last_name.length < 1;

  const handleUserStatus = async () => {
    try {
      const response = await api(`${url}/${userId}/${isActive ? 'not-active' : 'active'}`, {
        method: 'PATCH',
        payload: {
          ...values,
        },
      });

      if (response) {
        await fetchData();
        onClose();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <Content {...props} autoComplete="off">
      <FlexDiv justifyContent="space-between" alignItems="center">
        <DetailsTitle
          title={isUserPlaceholder ? t('user_placeholder') : userName}
          empty={isUserPlaceholder}
        />
        <ButtonsContainer alignItems="center">
          {!!userId && (
            <Button
              onClick={() => setModal(true)}
              color="text-error"
              label={isActive ? t('deactivate_user') : t('activate_user')}
              variant="text"
              size="lg"
            />
          )}
          <Button onClick={onClose} color="text-primary" size="lg" variant="text">
            {t('dont_save_changes')}
          </Button>
          <Button
            label={t('save_changes')}
            color="primary"
            size="lg"
            type="submit"
            isDisabled={isSubmitting}
          />
        </ButtonsContainer>
      </FlexDiv>
      <FieldsContainer>
        <StyledFormField label={t('role')} margin={'0'}>
          <RadioInput
            name="role"
            error={touched.role && !!errors.role}
            label={t(userRoles.admin)}
            value={userRoles.admin}
          />
          <RadioInput
            name="role"
            error={touched.role && !!errors.role}
            label={t(userRoles.intervener)}
            value={userRoles.intervener}
          />
          <RadioInput
            name="role"
            error={touched.role && !!errors.role}
            label={t(userRoles.director)}
            value={userRoles.director}
          />
        </StyledFormField>
        <div />
        <FlexDiv justifyContent="space-between" alignItems="flex-start">
          <StyledInputField
            name="first_name"
            error={touched.first_name && errors.first_name}
            label={t('firstname')}
          />
          <StyledInputField
            name="last_name"
            error={touched.last_name && errors.last_name}
            label={t('lastname')}
          />
        </FlexDiv>
        <FlexDiv justifyContent="space-between" alignItems="flex-start">
          <StyledInputField
            name="email"
            error={touched.email && errors.email}
            label={t('email')}
            type="email"
          />
          <StyledInputField
            name="phone"
            error={touched.phone && errors.phone}
            label={t('phone_no')}
            type="tel"
          />
        </FlexDiv>
        <StyledFormField label={t('language')} margin={'0'}>
          <RadioInput
            name="user_language"
            error={touched.user_language && !!errors.user_language}
            label="PL"
            value={languages.pl}
          />
          <RadioInput
            name="user_language"
            error={touched.user_language && !!errors.user_language}
            label="EN"
            value={languages.en}
          />
        </StyledFormField>
      </FieldsContainer>
      {isModalOpen && (
        <ConfirmModal
          onCancel={() => setModal(false)}
          onConfirm={handleUserStatus}
          title={t('confirmation')}
          text={
            isActive
              ? t('deactivate_user_confirm', { userName })
              : t('activate_user_confirm', { userName })
          }
        />
      )}
    </Content>
  );
};

export default DetailsForm;
