import React from 'react';

import { TruncatedTextStyled } from './styles';

interface Props {
  text: string;
  maxWidth?: string;
}

const TruncatedText: React.FC<Props> = ({ text, maxWidth }) => (
  <TruncatedTextStyled maxWidth={maxWidth}>{text}</TruncatedTextStyled>
);

export default TruncatedText;
