import React, { FC, memo, ReactNode } from 'react';

import { IconName } from 'components/Icons';
import { CtaIcon, CtaLabelStyled, CtaStyledProps } from 'components/_Redesign/CallToAction/styles';
import { CtaColor, CtaShape, CtaSize, CtaVariant } from 'components/_Redesign/CallToAction/types';
import { setIconSize } from 'components/_Redesign/CallToAction/setIconSize';

import { ButtonStyled } from './styles';

export interface ButtonProps extends CtaStyledProps {
  $isActive?: boolean;
  children?: ReactNode;
  className?: string;
  color?: CtaColor;
  icon?: IconName;
  id?: string;
  isDisabled?: boolean;
  isFetching?: boolean;
  label?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  role?: string;
  size?: CtaSize;
  shape?: CtaShape;
  tabIndex?: number;
  title?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: CtaVariant;
}

const Button: FC<ButtonProps> = ({
  $isActive,
  children,
  className,
  color,
  icon,
  id,
  isDisabled,
  label,
  onClick,
  role,
  shape,
  size,
  tabIndex,
  title,
  type,
  variant,
}) => (
  <ButtonStyled
    $color={color}
    $isActive={$isActive}
    $shape={shape}
    $size={size}
    $variant={variant}
    className={className}
    disabled={isDisabled}
    id={id}
    onClick={onClick}
    role={role}
    tabIndex={tabIndex}
    title={title}
    type={type || 'button'}
  >
    {!!icon && <CtaIcon type={icon} width={setIconSize(size)} height={setIconSize(size)} />}
    {!!label && <CtaLabelStyled>{label}</CtaLabelStyled>}
    {children}
  </ButtonStyled>
);

export default memo(Button);
