import React, { useState } from 'react';

import { getApiUrl, api, PaginatedList, ResponseError } from 'utils/api';
import usePrefix from 'utils/usePrefix';
import { useApi } from 'utils/api/useApi';
import { GetUsersResponse } from 'utils/api/users';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { notificationTypes } from 'utils/constants';

import Loader from 'components/Loader';
import { UsersList } from 'components/TableContainer/styles';
import Row from 'components/Row';
import Button from 'components/_Redesign/Button';

import Section from '../Section';
import PossibleUsersModal from './PossibleUsersModal';
import { ActionStyled, UsersListSectionStyled } from './styles';
import RemoveUserConfirmModal from './RemoveUserConfirmModal';

const url = getApiUrl(`/schools`);

const RECORD_COUNT = 9999999;

interface Props {
  schoolId: number;
  schoolName: string;
}

const SchoolUsers: React.FC<Props> = ({ schoolId, schoolName }) => {
  const [, dispatch] = useApp();

  const [isAddUserModalOpen, setAddUserModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<GetUsersResponse>();

  const { data, isLoading, fetchData } = useApi<PaginatedList<GetUsersResponse[]>>(
    `${url}/${schoolId}/users?page=0&size=${RECORD_COUNT}`,
    {
      method: 'GET',
    },
  );
  const t = usePrefix('Schools');
  const tg = usePrefix('General');

  const onAddUserModalOpen = () => {
    setAddUserModalIsOpen(true);
  };

  const onAddUserModalClose = () => {
    setAddUserModalIsOpen(false);
  };

  const onRemoveUserModalOpen = (int: any) => {
    setSelectedUser(int);
  };

  const onRemoveUserModalClose = () => {
    setSelectedUser(undefined);
  };

  const removeIntervener = async () => {
    try {
      const response = await api(`${url}/${schoolId}/users/${selectedUser?.id}`, {
        method: 'DELETE',
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        onRemoveUserModalClose();
        fetchData();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <Section title={t('users_list')}>
      {isLoading && <Loader label={t('fetching_users')} />}
      <UsersListSectionStyled>
        <UsersList>
          {data?.content &&
            data.content.map((int) => (
              <>
                <Row
                  key={int.id}
                  title={`${int.first_name} ${int.last_name}`}
                  labels={[`${int.first_name} ${int.last_name}`, int.email, tg(int.role)]}
                  clickable={false}
                  cellChildren={
                    <ActionStyled>
                      <Button
                        onClick={() => onRemoveUserModalOpen(int)}
                        title={t('delete')}
                        size="lg"
                        color="text-error"
                        variant="text"
                        icon="close"
                      />
                    </ActionStyled>
                  }
                />
              </>
            ))}
        </UsersList>
        <Button
          type="button"
          onClick={onAddUserModalOpen}
          icon="plus"
          size="lg"
          color="secondary"
          label={t('add_user')}
          shape="full"
        />
      </UsersListSectionStyled>
      <PossibleUsersModal
        schoolId={schoolId}
        onClose={onAddUserModalClose}
        fetchData={fetchData}
        isOpen={isAddUserModalOpen}
      />
      <RemoveUserConfirmModal
        onClose={onRemoveUserModalClose}
        isOpen={!!selectedUser}
        onRemove={removeIntervener}
        schoolName={schoolName}
        user={selectedUser}
      />
    </Section>
  );
};

export default SchoolUsers;
