import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import packageInfo from '../package.json';

const version = packageInfo.version;

const backendOptions = {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
  addPath: '/locales/add/{{lng}}/{{ns}}',
  crossDomain: false,
  withCredentials: false,
  overrideMimeType: false,
  requestOptions: {
    mode: 'cors',
    credentials: 'same-origin',
    cache: 'default'
  },
  queryStringParams: { v: version },
  reloadInterval: false // can be used to reload resources in a specific interval (milliseconds) (useful in server environments)
}


i18n
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    load: 'languageOnly',
    fallbackLng: 'pl',
    backend: backendOptions,
    ns: ['common', 'translation'],
    defaultNS: 'translation',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
