import { css } from 'styled-components';

import { FONT_PRIMARY } from './variables';

export const resetStyles = css`
  * {
    outline: none;
    box-sizing: border-box;
    scrollbar-width: thin;

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    overscroll-behavior: none;
  }

  html,
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  p,
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section,
  summary,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  fieldset,
  form,
  label,
  legend,
  a,
  hr,
  button,
  li,
  input,
  textarea,
  blockquote {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: ${FONT_PRIMARY};
    vertical-align: baseline;
  }
  ol,
  ul {
    border: 0;
    font-family: ${FONT_PRIMARY};
    vertical-align: baseline;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  img {
    border-style: none;
  }

  textarea {
    overflow: auto;
    resize: none;
  }

  input,
  select,
  textarea,
  button {
    font-family: ${FONT_PRIMARY};
  }

  button,
  input {
    overflow: visible; /* Correct the overflow in Edge */
  }

  :before,
  :after {
    box-sizing: border-box;
  }

  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  a,
  area,
  button,
  input,
  label,
  select,
  textarea,
  [tabindex] {
    touch-action: manipulation;
  }

  button {
    background: inherit;
    &:disabled {
      color: inherit;
    }
    &::-moz-focus-inner {
      padding: 0;
      border: 0;
    }
  }

  abbr[title] {
    text-decoration: none;
  }

  a {
    -webkit-text-decoration-skip: objects; /* Remove the gaps in underlines in iOS 8+ and Safari 8+. */
  }

  iframe {
    border: none;
  }

  @media print {
    @page {
      size: A4 portrait;
      margin: 1cm 1.5cm 1.5cm;
    }
    html,
    body {
      margin: 0;
      padding: 0;
      width: auto;
      max-width: 100%;
    }
  }
`;
