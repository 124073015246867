import React, { useEffect } from 'react';

import { useApi, getApiUrl } from 'utils/api/useApi';
import { ReceivedInstructionDetailsResponse, ReceivedInstructionResponse } from 'utils/api/inbox';
import usePrefix from 'utils/usePrefix';
import { PaginatedList } from 'utils/api';

import NoticeContent from 'components/NoticeContent';
import FetchingMessage from 'components/FetchingMessage';

const url = getApiUrl('/interveners/me/instructions');

interface Props {
  instructionId: number;
  fetchData: () => Promise<void | PaginatedList<ReceivedInstructionResponse[]>>;
}

const Advice: React.FC<Props> = ({ instructionId, fetchData }) => {
  const t = usePrefix('Instructions');

  const { data, isLoading } = useApi<ReceivedInstructionDetailsResponse>(
    `${url}/${instructionId}`,
    {
      method: 'GET',
    },
  );

  useEffect(() => {
    if (data) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data) return null;

  return !isLoading ? (
    <NoticeContent {...data.details} violenceId={data.violenceId} />
  ) : (
    <FetchingMessage title={t('loading')} />
  );
};

export default Advice;
