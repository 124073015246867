import { css } from 'styled-components';

import { CtaStyledProps } from './styles';
import { CtaColor } from './types';

export const primaryStyles = css`
  border-color: var(--cta-primary-border);
  background: var(--cta-primary-bg);
  color: var(--cta-primary-txt);
`;

export const primaryHoverColors = css`
  border-color: var(--cta-primary-border--hover);
  background: var(--cta-primary-bg--hover);
  color: var(--cta-primary-txt--hover);
`;

export const primaryHoverStyles = css`
  &:hover:not(:disabled) {
    ${primaryHoverColors};
  }
`;

export const primaryDisabledStyles = css`
  border-color: var(--cta-primary-border--disabled);
  background: var(--cta-primary-bg--disabled);
  color: var(--cta-primary-txt--disabled);
`;

export const secondaryStyles = css`
  border-color: var(--cta-secondary-border);
  background: var(--cta-secondary-bg);
  color: var(--cta-secondary-txt);
  box-shadow: 0 6px 20px 0 var(--cta-secondary-shadow);
`;

export const secondaryHoverColors = css`
  border-color: var(--cta-secondary-border--hover);
  background: var(--cta-secondary-bg--hover);
  color: var(--cta-secondary-txt--hover);
`;

export const secondaryHoverStyles = css`
  &:hover:not(:disabled) {
    ${secondaryHoverColors};
  }
`;

export const secondaryDisabledStyles = css`
  border-color: var(--cta-secondary-border--disabled);
  background: var(--cta-secondary-bg--disabled);
  color: var(--cta-secondary-txt--disabled);
  box-shadow: none;
`;

export const errorStyles = css`
  border-color: var(--cta-error-border);
  background: var(--cta-error-bg);
  color: var(--cta-error-txt);
`;

export const errorHoverColors = css`
  border-color: var(--cta-error-border--hover);
  background: var(--cta-error-bg--hover);
  color: var(--cta-error-txt--hover);
`;

export const errorHoverStyles = css`
  &:hover:not(:disabled) {
    ${errorHoverColors};
  }
`;

export const errorDisabledStyles = css`
  background: transparent;
  color: var(--cta-error-txt--disabled);
`;

export const textPrimaryStyles = css`
  background: transparent;
  color: var(--cta-text-primary-txt);
`;

export const textPrimaryHoverColors = css`
  background: transparent;
  color: var(--cta-text-primary-txt--hover);
`;

export const textPrimaryHoverStyles = css`
  &:hover:not(:disabled) {
    ${textPrimaryHoverColors};
  }
`;

export const textPrimaryDisabledStyles = css`
  background: transparent;
  color: var(--cta-text-primary-txt--disabled);
`;

export const textErrorStyles = css`
  background: transparent;
  color: var(--cta-text-error-txt);
`;

export const textErrorHoverColors = css`
  background: transparent;
  color: var(--cta-text-error-txt--hover);
`;

export const textErrorHoverStyles = css`
  &:hover:not(:disabled) {
    ${textErrorHoverColors};
  }
`;

export const textErrorDisabledStyles = css`
  background: transparent;
  color: var(--cta-text-error-txt--disabled);
`;

export const ctaColors: Record<CtaColor, any> = {
  primary: css<{ isDisabled?: boolean }>`
    ${primaryStyles};
    ${({ isDisabled }) => isDisabled && primaryDisabledStyles};

    @media (hover: hover) {
      ${({ isDisabled }) => !isDisabled && primaryHoverStyles};
    }

    &:disabled {
      ${primaryDisabledStyles};
    }
  `,
  secondary: css<{ isDisabled?: boolean }>`
    ${secondaryStyles};
    ${({ isDisabled }) => isDisabled && secondaryDisabledStyles};

    @media (hover: hover) {
      ${({ isDisabled }) => !isDisabled && secondaryHoverStyles};
    }

    &:disabled {
      ${secondaryDisabledStyles};
    }
  `,
  error: css<{ isDisabled?: boolean }>`
    ${errorStyles};
    ${({ isDisabled }) => isDisabled && errorDisabledStyles};

    @media (hover: hover) {
      ${({ isDisabled }) => !isDisabled && errorHoverStyles};
    }

    &:disabled {
      ${errorDisabledStyles};
    }
  `,
  'text-primary': css<{ isDisabled?: boolean }>`
    ${textPrimaryStyles};
    ${({ isDisabled }) => isDisabled && textPrimaryDisabledStyles};

    @media (hover: hover) {
      ${({ isDisabled }) => !isDisabled && textPrimaryHoverStyles};
    }

    &:disabled {
      ${textPrimaryDisabledStyles};
    }
  `,
  'text-error': css<{ isDisabled?: boolean }>`
    ${textErrorStyles};
    ${({ isDisabled }) => isDisabled && textErrorDisabledStyles};

    @media (hover: hover) {
      ${({ isDisabled }) => !isDisabled && textErrorHoverStyles};
    }

    &:disabled {
      ${textErrorDisabledStyles};
    }
  `,
};

export const ctaColorsFunc = ({ $color }: { $color?: CtaStyledProps['$color'] }) =>
  $color ? ctaColors[$color] : '';
