import styled from 'styled-components';

export const RegisterOfNotificationsStyled = styled.div`
  flex: 1;
  margin: 0;
  width: 100%;
  height: 100%;
`;

export const ChatsStyled = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  list-style: none;
  ul {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  > div {
    > div {
      padding: 0 192px;
      scrollbar-width: thin;
      overflow-y: auto;
    }
  }
  table {
    margin: auto;
  }
  th,
  td {
    padding: 15px;
    text-align: left;
  }
`;

export const ChatsTableStyled = styled.table`
  margin: auto;
  padding: 15px;
  text-align: left;
  white-space: pre-wrap;
`;
