import styled, { css } from 'styled-components';

const detailsTitleEmptyStyles = css`
  opacity: 0.2;
`;

export const DetailsTitleStyled = styled.h2<{ empty?: boolean }>`
  margin: 0;
  font-weight: 600;
  font-size: 22px;
  color: var(--app-txt--primary);
  word-break: break-word;
  ${({ empty }) => empty && detailsTitleEmptyStyles};
`;
