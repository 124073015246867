import styled from 'styled-components';

import {
  ctaModifyStyles,
  CtaStyledProps,
  ctaStyles,
} from 'components/_Redesign/CallToAction/styles';

export const ButtonStyled = styled('button')<CtaStyledProps>`
  ${ctaStyles};
  ${ctaModifyStyles};
  cursor: pointer;
`;
