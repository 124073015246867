import React, { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import usePrefix from 'utils/usePrefix';
import { chatlistTypes } from 'utils/constants';
import { ChatListTypes } from 'utils/api';

import { useApp } from 'App';

import PageTitle from 'components/PageTitle';
import RowShort from 'components/RowShort';
import Search from 'components/Search';
import { ChatWithLastMessageResponse } from 'utils/api/chats';
import EmptyState from 'components/EmptyState';
import Button from 'components/_Redesign/Button';
import FlexDiv from 'components/FlexDiv';

import { ListState } from '..';
import { PageTop } from '../styles';
import { CategoriesWrapper, ChatTabsStyled, Wrapper } from './styles';
import ChatTab from './ChatTab';

interface ListProps {
  setChatId: (chatId: number) => void;
  PAGE_START: number;
  PAGE_SIZE: number;
  totalElements?: number;
  loadMore: () => void;
  currentPage: number;
  chatId?: number;
  listFilter: ChatListTypes;
  handleListFilter: (filter: ChatListTypes) => void;
  query?: string;
  setQuery?: (query: string) => void;
  resetQuery?: () => void;
  isSearchClearDisabled?: boolean;
  onAddChat?: () => void;
  isAddingChat?: boolean;
}

type ListStateProps = Omit<ListState, 'page' | 'size'>;

const ChatList: React.FC<ListProps & ListStateProps> = ({
  setChatId,
  chatList,
  totalElements,
  PAGE_START,
  PAGE_SIZE,
  loadMore,
  currentPage,
  chatId,
  listFilter,
  handleListFilter,
  query,
  setQuery,
  resetQuery,
  isSearchClearDisabled,
  onAddChat,
  isAddingChat,
}) => {
  const t = usePrefix('Chat');
  const tg = usePrefix('General');

  const [{ schools }] = useApp();

  const scrollParentRef = useRef(null);

  const isUserAssignedAnySchool = schools?.length > 0;

  const filteredListByAttentionRequired = (attentionRequired: boolean) =>
    chatList.filter((chat) => chat.attention_required === attentionRequired);

  const getChatTitle = (chat: ChatWithLastMessageResponse): string => {
    switch (true) {
      case !!chat.nickname:
        return chat.nickname as string; // mimo, że sprawdzamy, czy wartość chat.nickname jest "truthy", i tak musimy potwierdzić, że zwróci stringa
      case chat.type === 'MANUAL':
        return t('user_added_manual_number', { number: chat.chat_number });
      case !!chat.chat_number:
        return t('username_number', { number: chat.chat_number });
      default:
        return t('username');
    }
  };

  return (
    <Wrapper
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="flex-start"
    >
      <PageTop>
        <FlexDiv flex={1} justifyContent="flex-start" alignItems="center">
          <PageTitle title={t('messages')} />
          {isUserAssignedAnySchool ? (
            <Button
              onClick={onAddChat}
              icon="plus"
              size="md"
              color="text-primary"
              variant="text"
              label={tg('add')}
              isDisabled={isAddingChat}
            />
          ) : null}
        </FlexDiv>
        {setQuery && (
          <Search
            name="filter"
            placeholder={t('search')}
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            onClear={resetQuery}
            isDisabled={isSearchClearDisabled}
            isResetShow={!isSearchClearDisabled}
          />
        )}

        <ChatTabsStyled>
          <ChatTab
            value={chatlistTypes.all}
            text={t('all')}
            listFilter={listFilter}
            onClick={handleListFilter}
          />
          <ChatTab
            value={chatlistTypes.important}
            text={t('my_important')}
            listFilter={listFilter}
            onClick={handleListFilter}
          />
          <ChatTab
            value={chatlistTypes.assigned}
            text={t('my_all')}
            listFilter={listFilter}
            onClick={handleListFilter}
          />
        </ChatTabsStyled>
      </PageTop>
      <CategoriesWrapper ref={scrollParentRef}>
        <InfiniteScroll
          pageStart={PAGE_START}
          loadMore={loadMore}
          hasMore={!!totalElements && totalElements > (currentPage + PAGE_START + 1) * PAGE_SIZE}
          getScrollParent={() => scrollParentRef.current}
          threshold={100}
          useWindow={false}
          initialLoad={false}
        >
          {chatList.length ? (
            <>
              {filteredListByAttentionRequired(true).map((chat) => (
                <RowShort
                  key={chat.id}
                  title={getChatTitle(chat)}
                  additionalInfo={chat.chat_case_number}
                  subtitle={chat?.last_message?.message}
                  onClick={() => setChatId(chat.id)}
                  selected={!!chatId && chat.id === chatId}
                  unread={!!chat.unread_messages_count}
                />
              ))}
              {filteredListByAttentionRequired(false).map((chat) => (
                <RowShort
                  key={chat.id}
                  title={getChatTitle(chat)}
                  additionalInfo={chat.chat_case_number}
                  subtitle={chat?.last_message?.message}
                  onClick={() => setChatId(chat.id)}
                  selected={!!chatId && chat.id === chatId}
                  unread={!!chat.unread_messages_count}
                />
              ))}
            </>
          ) : (
            <EmptyState title={t('no_chats')} />
          )}
        </InfiniteScroll>
      </CategoriesWrapper>
    </Wrapper>
  );
};

export default ChatList;
