import React from 'react';

import { TopPanelStyled } from './styles';

interface TopPanelProps {
  children?: React.ReactNode;
}

const TopPanel: React.FC<TopPanelProps> = ({ children }) => (
  <TopPanelStyled justifyContent="space-between" alignItems="center" flex={1}>
    {children}
  </TopPanelStyled>
);

export default TopPanel;
