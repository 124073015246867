import React, { memo } from 'react';

import { ReporterDataEditingHistoryResponse } from 'utils/api/chats';
import usePrefix from 'utils/usePrefix';

import { DataListStyled } from 'components/DataRow/styles';

import ReporterDataHistoryRow from './ReporterDataHistoryRow';

interface Props {
  reporterDataEditingHistory?: ReporterDataEditingHistoryResponse[];
}

const Content: React.FC<Props> = ({ reporterDataEditingHistory }) => {
  const t = usePrefix('Chat');

  return reporterDataEditingHistory && reporterDataEditingHistory.length > 0 ? (
    <DataListStyled>
      {reporterDataEditingHistory.map((item, index) => (
        <ReporterDataHistoryRow key={index} reporterDataEditingHistory={item} />
      ))}
    </DataListStyled>
  ) : (
    <p>{t('no_data')}</p>
  );
};

export default memo(Content);
