const screenSm = 767;
const screenMd = 1023;
const screenLg = 1279;
const screenXlg = 1439;

export const screenSmUnder = `${screenSm}px`;
export const screenSmAbove = `${screenSm + 1}px`;

export const screenMdUnder = `${screenMd}px`;
export const screenMdAbove = `${screenMd + 1}px`;

export const screenLgUnder = `${screenLg}px`;
export const screenLgAbove = `${screenLg + 1}px`;

export const screenXlgUnder = `${screenXlg}px`;
export const screenXlgAbove = `${screenXlg + 1}px`;
