import React, { memo } from 'react';

import { ReporterDataEditingHistoryResponse } from 'utils/api/chats';
import usePrefix from 'utils/usePrefix';

import Modal from 'components/_Redesign/Modal';
import {
  ModalActionsStyled,
  ModalBodyStyled,
  ModalHeaderStyled,
  ModalIcon,
} from 'components/_Redesign/Modal/styles';
import { MODAL_ICON_SIZE_CSS } from 'components/_Redesign/Modal/constants';
import Button from 'components/_Redesign/Button';

import Content from './Content';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  reporterDataEditingHistory?: ReporterDataEditingHistoryResponse[];
}

const ReporterDataHistoryModal: React.FC<Props> = ({
  isOpen,
  onClose,
  reporterDataEditingHistory,
}) => {
  const t = usePrefix('Chat');
  const tg = usePrefix('General');

  return isOpen ? (
    <Modal onClose={onClose} align="center" size="md">
      <ModalHeaderStyled>
        <ModalIcon
          type="clockCounterClockwise"
          width={MODAL_ICON_SIZE_CSS}
          height={MODAL_ICON_SIZE_CSS}
          $state="info"
        />
        <h3>{t('message_change_history')}</h3>
      </ModalHeaderStyled>
      <ModalBodyStyled>
        <Content reporterDataEditingHistory={reporterDataEditingHistory} />
      </ModalBodyStyled>
      <ModalActionsStyled>
        <Button onClick={onClose} color="secondary" size="lg" label={tg('cta_close')} />
      </ModalActionsStyled>
    </Modal>
  ) : null;
};

export default memo(ReporterDataHistoryModal);
