import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';

export const Wrapper = styled(FlexDiv)`
  width: 100%;
  height: 100%;
  list-style: none;
`;

export const UsersListSectionStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const IntervenersList = styled.div`
  flex: 1;
  padding: 24px 0 0;
  width: 100%;
  overflow-y: auto;
`;

export const ActionStyled = styled.div`
  width: 100%;
  text-align: right;
`;
