import { ResponseError, STATUS_CODE_UNAUTHORIZED, STATUS_CODE_NOT_PERMITTED } from 'utils/api';

import { useApp } from 'App';
import { ActionTypes } from 'App/types';

const useAuth = () => {
  const [, dispatch] = useApp();

  return (fn: Promise<any>) =>
    fn.catch((error: ResponseError) => {
      if (error.response.status === STATUS_CODE_UNAUTHORIZED)
        return dispatch({ type: ActionTypes.LOGOUT });
      if (error.response.status === STATUS_CODE_NOT_PERMITTED) return;
      throw error;
    });
};
export default useAuth;
