import React, { FC, memo } from 'react';

import {
  FieldHintStyled,
  FormItemStyled,
  LabelStyled,
} from 'components/_Redesign/FormElements/styles';

interface Props {
  children?: React.ReactNode;
  label?: string;
  error?: boolean | string;
}

const FormItem: FC<Props> = ({ label, children, error }) => (
  <FormItemStyled>
    {label ? <LabelStyled>{label}</LabelStyled> : null}
    {children ? <div>{children}</div> : null}
    <FieldHintStyled>{error ? error : null}</FieldHintStyled>
  </FormItemStyled>
);

export default memo(FormItem);
