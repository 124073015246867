import { ICON_SIZE } from 'components/Icons/constants';

import { CTA_ICON_LG_SIZE, CTA_ICON_MD_SIZE, CTA_ICON_SM_SIZE } from './constants';
import { CtaSize } from './types';

export const setIconSize = (size?: CtaSize) => {
  let CTA_ICON_SIZE = `${ICON_SIZE}`;

  if (size === 'sm') {
    CTA_ICON_SIZE = CTA_ICON_SM_SIZE;
  }

  if (size === 'md') {
    CTA_ICON_SIZE = CTA_ICON_MD_SIZE;
  }

  if (size === 'lg') {
    CTA_ICON_SIZE = CTA_ICON_LG_SIZE;
  }

  return CTA_ICON_SIZE;
};
