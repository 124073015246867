import * as React from 'react';

import { Wrapper, Content, Label, Error } from './styles';

interface Props {
  children?: React.ReactNode;
  label?: string;
  margin?: string;
  error?: boolean | string;
  login?: boolean;
}

const FormField: React.FC<Props> = ({
  label,
  children,
  margin = '0 0 32px 0',
  error,
  login = false,
  ...props
}) => (
  <Wrapper
    margin={margin}
    {...props}
    justifyContent="flex-start"
    alignItems="flex-start"
    flexWrap="wrap"
    login={login}
  >
    {label && <Label>{label}</Label>}
    <Content justifyContent="flex-start" alignItems="flex-start" flexWrap="wrap">
      {children}
    </Content>
    {error && <Error>{error}</Error>}
  </Wrapper>
);

export default FormField;
