import React, { memo, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useApp } from 'App';

import { ActionTypes } from 'App/types';

import usePrefix from 'utils/usePrefix';
import { api, getApiUrl, ResponseError } from 'utils/api';
import { allowedChatAttachmentTypes, notificationTypes } from 'utils/constants';
import { userRoles } from 'utils/userRoles';

import Loader from 'components/Loader';
import TextArea from 'components/TextArea';
import FileUploadIcon from 'components/FileUploadIcon';
import { TEXT_AREA_HEIGHT_CSS } from 'components/TextArea/constants';
import Button from 'components/_Redesign/Button';

import { MessageFormStyled, FormStyled } from './styles';

const url = getApiUrl('/interveners/me/chats');
const urlDirector = getApiUrl('/director/me/chats');

interface FormValues {
  message: string;
}

interface PanelProps {
  chatId?: number;
  disabled: boolean;
  onClearPanel: () => void;
  onClearAndReset: () => void;
}

const MessageForm: React.FC<PanelProps> = ({ chatId, disabled, onClearAndReset, onClearPanel }) => {
  const [{ profile }, dispatch] = useApp();
  const [loadingAttachment, setLoadingAttachment] = useState(false);

  const t = usePrefix('Chat');
  const isDirector = profile?.role === userRoles.director;

  const submit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    try {
      const response = await api(`${url}/${chatId}/messages/text-message`, {
        method: 'POST',
        payload: {
          ...values,
        },
      });

      if (response) {
        resetForm();
        onClearAndReset();
        onClearPanel();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const addAttachment = async (selectedFile: File | null) => {
    if (!selectedFile) {
      return;
    }

    setLoadingAttachment(true);

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      await fetch(`${isDirector ? urlDirector : url}/${chatId}/messages/attachment`, {
        method: 'POST',
        body: formData,
      });
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { type: notificationTypes.success },
      });
      setLoadingAttachment(false);
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
      setLoadingAttachment(false);
    }
  };

  return (
    <MessageFormStyled>
      <Formik initialValues={{ message: '' }} onSubmit={submit}>
        {({ isSubmitting }) => (
          <FormStyled autoComplete="off">
            <TextArea
              name="message"
              placeholder={t('type_message')}
              disabled={disabled || loadingAttachment}
              maxLength={1000}
              height={TEXT_AREA_HEIGHT_CSS}
            />
            <FileUploadIcon
              onFileChange={addAttachment}
              isSubmitting={isSubmitting}
              disabled={disabled || loadingAttachment}
              acceptedFileTypes={allowedChatAttachmentTypes}
            />
            <Button
              type="submit"
              icon={disabled || isSubmitting ? 'paperPlane' : 'paperPlaneFill'}
              isDisabled={disabled || isSubmitting || loadingAttachment}
              variant="text"
              color="text-primary"
              size="lg"
            />
          </FormStyled>
        )}
      </Formik>
      {loadingAttachment ? <Loader label={t('uploading_attachment')} /> : null}
    </MessageFormStyled>
  );
};

export default memo(MessageForm);
