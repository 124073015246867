import React from 'react';

import usePrefix from 'utils/usePrefix';
import { AttachmentResponse } from 'utils/api/chats';
import { filenameFromPathRegex } from 'utils/validation';

import FlexDiv from 'components/FlexDiv';

import {
  AttachmentAudio,
  AttachmentImage,
  AttachmentLink,
  AttachmentMediaLink,
  AttachmentVideo,
  AttachmentMediaNotSupported,
} from './styles';

const MessageAttachment: React.FC<{ attachment: AttachmentResponse }> = ({ attachment }) => {
  const t = usePrefix('General');

  const attachmentsBaseUrl = window.location.origin;
  let filename = attachment.name;
  if (!filename) {
    const regArray = attachment.uri.match(filenameFromPathRegex);
    // eslint-disable-next-line no-magic-numbers
    if (regArray?.length) filename = regArray[0];
  }

  return attachment.mimeType?.includes('image') ? (
    <AttachmentImage
      target="_blank"
      mimeType={attachment.mimeType}
      src={`${attachmentsBaseUrl}/${attachment.uri}`}
      alt={filename || t('file')}
    />
  ) : attachment.mimeType?.includes('audio') ? (
    <FlexDiv flexDirection="column">
      <AttachmentAudio controls>
        <source src={`${attachmentsBaseUrl}/${attachment.uri}`} type={attachment.mimeType} />
        <AttachmentMediaLink
          href={`${attachmentsBaseUrl}/${attachment.uri}`}
          download={`${attachmentsBaseUrl}/${attachment.uri}`}
        >
          {t('download_audio')}
        </AttachmentMediaLink>
        .
      </AttachmentAudio>
      <AttachmentLink
        type={attachment.mimeType}
        target="_blank"
        href={`${attachmentsBaseUrl}/${attachment.uri}`}
      >
        {filename || t('download_audio')}
      </AttachmentLink>
    </FlexDiv>
  ) : attachment.mimeType?.includes('video') ? (
    <FlexDiv flexDirection="column">
      <AttachmentVideo src={`${attachmentsBaseUrl}/${attachment.uri}`} controls width="250">
        <AttachmentMediaNotSupported>
          {t('video_not_supported')}
          <AttachmentMediaLink
            href={`${attachmentsBaseUrl}/${attachment.uri}`}
            download={`${attachmentsBaseUrl}/${attachment.uri}`}
          >
            {t('video_not_supported_link_label')}
          </AttachmentMediaLink>
        </AttachmentMediaNotSupported>
      </AttachmentVideo>
      <AttachmentLink
        type={attachment.mimeType}
        target="_blank"
        href={`${attachmentsBaseUrl}/${attachment.uri}`}
      >
        {filename || t('file')}
      </AttachmentLink>
    </FlexDiv>
  ) : (
    <AttachmentLink
      type={attachment.mimeType ? attachment.mimeType : undefined}
      target="_blank"
      href={`${attachmentsBaseUrl}/${attachment.uri}`}
    >
      {filename || t('file')}
    </AttachmentLink>
  );
};
export default MessageAttachment;
