import React from 'react';

import { DataCellStyled, Value } from './styles';

interface DataCellProps {
  children?: React.ReactNode;
  label?: string;
  cols?: number;
  alignItems?: 'normal' | 'start' | 'end' | 'center' | 'flex-start' | 'flex-end' | 'stretch';
  whiteSpace?: 'normal' | 'pre-line';
  truncateText?: boolean;
}

// eslint-disable-next-line no-magic-numbers
const DataCell: React.FC<DataCellProps> = ({
  children,
  label,
  cols = 1,
  alignItems = 'center',
  whiteSpace = 'normal',
  truncateText = true,
}) => (
  <DataCellStyled
    cols={cols}
    justifyContent="flex-start"
    alignItems={alignItems}
    whiteSpace={whiteSpace}
  >
    {label && <Value truncateText={truncateText}>{label}</Value>}
    {children && children}
  </DataCellStyled>
);

export default DataCell;
