import React from 'react';

import usePrefix from 'utils/usePrefix';
import { ReporterDataEditingHistoryResponse } from 'utils/api/chats';

import EmptyState from 'components/EmptyState';

import { Wrapper } from './styles';
import Content from './Content';

interface Props {
  chatId: number;
  onClear: () => void;
  handleInactiveChat: (deactivation_time: string | null) => void;
  deactivationTime: string | null;
  setChatPadding: (set: boolean) => void;
  setReporterDataEditingHistory: (data: ReporterDataEditingHistoryResponse[]) => void;
}

const ChatMeta: React.FC<Props> = ({
  chatId,
  onClear,
  handleInactiveChat,
  deactivationTime,
  setChatPadding,
  setReporterDataEditingHistory,
}) => {
  const t = usePrefix('Chat');

  return (
    <Wrapper>
      {chatId ? (
        <Content
          chatId={chatId}
          onClear={onClear}
          handleInactiveChat={handleInactiveChat}
          deactivationTime={deactivationTime}
          setChatPadding={setChatPadding}
          setReporterDataEditingHistory={setReporterDataEditingHistory}
        />
      ) : (
        <EmptyState title={t('no_messages')} />
      )}
    </Wrapper>
  );
};

export default ChatMeta;
