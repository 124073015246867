import { UserType } from 'utils/api/users';

const ADMIN: UserType = 'ADMIN';
const INTERVENER: UserType = 'INTERVENER';
const DIRECTOR: UserType = 'DIRECTOR';

export const parseRole = (role: UserType): string =>
  ({
    ADMIN: 'admin',
    INTERVENER: 'intervent',
    DIRECTOR: 'director',
  })[role];

export const userRoles = {
  admin: ADMIN,
  intervener: INTERVENER,
  director: DIRECTOR,
};
