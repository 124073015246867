import React, { memo } from 'react';
import { Formik } from 'formik';
import { find } from 'lodash/fp';
import * as Yup from 'yup';

import { useApp } from 'App';
import { ActionTypes } from 'App/types';

import { CreateSchoolRequest, SchoolResponse } from 'utils/api/schools';
import { api, getApiUrl, ResponseError } from 'utils/api';
import usePrefix from 'utils/usePrefix';
import { notificationTypes } from 'utils/constants';
import { languages } from 'utils/languages';

import Button from 'components/_Redesign/Button';
import SidePanel from 'components/SidePanel';
import { SidePanelContentStyled } from 'components/SidePanel/styles';

import BrandingSection from './BrandingSection';
import DetailsForm from './DetailsForm';
import Section from './Section';
import SchoolUsers from './SchoolUsers';
import Licence from './Licence';
import { CodeField, StyledInputField } from './styles';

const MAX_TERM_OF_USE_LENGTH = 2048;

const url = getApiUrl(`/schools`);

const validationSchema = (ty: (key: string, vars?: object) => string) =>
  Yup.object().shape({
    name: Yup.string().required(ty('field_required')),
    voivodeship: Yup.string().required(ty('field_required')),
    locality: Yup.string().required(ty('field_required')),
    postal_code: Yup.string().required(ty('field_required')),
    street: Yup.string().required(ty('field_required')),
    number: Yup.string().required(ty('field_required')),
    term_of_use: Yup.string()
      .nullable()
      .max(MAX_TERM_OF_USE_LENGTH, ty('max_char_length_is', { length: MAX_TERM_OF_USE_LENGTH })),
  });

interface Props {
  schools: SchoolResponse[];
  schoolId?: number;
  onClose: () => void;
  fetchData: () => Promise<any>;
  isOpen: boolean;
}

const School: React.FC<Props> = ({ schools, schoolId, onClose, fetchData, isOpen }) => {
  const [, dispatch] = useApp();

  const initialValues: CreateSchoolRequest = {
    name: '',
    voivodeship: '',
    locality: '',
    postal_code: '',
    street: '',
    number: '',
    name_and_mail_mandatory: false,
    term_of_use: undefined,
    school_language: languages.pl,
    management_by_director: false,
  };
  const t = usePrefix('Schools');
  const ty = usePrefix('YupErrors');

  const getSchool = (id?: number) => find((school) => school.id === id, schools) || initialValues;
  const schoolCode = schoolId ? find((school) => school.id === schoolId, schools)?.code : '';

  const submitSchool = async (values: CreateSchoolRequest) => {
    try {
      const response = await api(!schoolId ? url : `${url}/${schoolId}`, {
        method: !schoolId ? 'POST' : 'PUT',
        payload: {
          ...values,
        },
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        await fetchData();
        onClose();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const submitToken = async () => {
    try {
      const response = await api(`${url}/${schoolId}/code`, {
        method: 'POST',
      });

      if (response) {
        fetchData();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return isOpen ? (
    <SidePanel onClose={onClose}>
      <SidePanelContentStyled>
        <Formik
          initialValues={getSchool(schoolId)}
          onSubmit={submitSchool}
          validationSchema={() => validationSchema(ty)}
          enableReinitialize={true}
        >
          {({ errors, touched, isSubmitting, values }) => (
            <DetailsForm
              isNew={!schoolId}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              onClose={onClose}
              values={values}
            />
          )}
        </Formik>
        {/* eslint-disable-next-line react/no-children-prop */}
        <Section title={t('branding')} form={false}>
          <BrandingSection schoolId={schoolId} />
        </Section>
        {schoolId && (
          <>
            <Formik
              initialValues={{ code: schoolCode }}
              onSubmit={submitToken}
              enableReinitialize={true}
            >
              {({ isSubmitting }) => (
                <Section title={t('data_access')} form={true}>
                  <CodeField flexDirection="column" alignItems="flex-end">
                    <StyledInputField name="code" label={t('access_code')} disabled={true} />
                    <Button
                      label={t('generate_code')}
                      type="submit"
                      isDisabled={isSubmitting}
                      color="primary"
                      size="lg"
                    />
                  </CodeField>
                </Section>
              )}
            </Formik>
            <SchoolUsers schoolId={schoolId} schoolName={getSchool(schoolId).name} />
            <Licence schoolId={schoolId} />
          </>
        )}
      </SidePanelContentStyled>
    </SidePanel>
  ) : null;
};

export default memo(School);
