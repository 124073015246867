import { css } from 'styled-components';

import { CtaVariant } from './types';
import { CtaStyledProps } from './styles';

export const textVariant = css`
  padding: 0;
  border-radius: 0;
  border-width: 0;
  height: fit-content;
`;

export const ctaVariants: Record<CtaVariant, any> = {
  text: textVariant,
};

export const ctaVariantsFunc = ({ $variant }: { $variant?: CtaStyledProps['$variant'] }) =>
  $variant ? ctaVariants[$variant] : '';
