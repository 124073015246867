import React, { ChangeEvent } from 'react';

import FlexDiv from 'components/FlexDiv';
import Search from 'components/Search';

import { Content, FieldsContainer } from '../styles';
import { Value, SubHeader, Subtitle } from './styles';

interface Props {
  children?: React.ReactNode;
  title: string;
  name?: string;
  selected?: number;
  placeholder: string;
  onSubmit?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  resetFilter?: () => void;
  value?: string;
  isSearchClearDisabled?: boolean;
}

const List: React.FC<Props> = ({
  title,
  name,
  selected,
  placeholder,
  children,
  resetFilter,
  isSearchClearDisabled,
  ...props
}) => (
  <Content>
    <FieldsContainer>
      <SubHeader justifyContent="flex-start" alignItems="flex-start">
        <Subtitle>
          {title} {!!selected && <Value>({selected})</Value>}
        </Subtitle>
        {name && (
          <FlexDiv flex={1} justifyContent="flex-start" alignItems="flex-start">
            <Search
              name={name}
              placeholder={placeholder}
              onClear={resetFilter}
              isDisabled={isSearchClearDisabled}
              isResetShow={!isSearchClearDisabled}
              {...props}
            />
          </FlexDiv>
        )}
      </SubHeader>
      {children}
    </FieldsContainer>
  </Content>
);

export default List;
