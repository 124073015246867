import styled, { css } from 'styled-components';

interface Props {
  clickable?: boolean;
  paddingBottom?: string;
}

export const PageTitleStyled = styled.h1<Partial<Props>>`
  margin: 0;
  padding: 0;
  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: ${paddingBottom};
    `}
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1.1px;
  color: var(--page-title-txt);
  ${({ clickable }) => clickable && 'cursor: pointer;'};
`;
