import React from 'react';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';

import { notificationTypes } from 'utils/constants';
import Button from 'components/_Redesign/Button';

import { FileUploadStyled, InputFileHandler } from './styles';

interface Props {
  onFileChange: (file: File | null) => void;
  isSubmitting?: boolean;
  disabled?: boolean;
  acceptedFileTypes?: string;
}

const FileUploadIcon: React.FC<Props> = ({
  isSubmitting,
  disabled,
  onFileChange,
  acceptedFileTypes,
}) => {
  const [, dispatch] = useApp();

  const isValidFileType = (fileName: string): boolean => {
    if (!acceptedFileTypes) return true;

    const validExtensions = acceptedFileTypes.split(',').map((ext) => ext.trim().toLowerCase());
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    return fileExtension ? validExtensions.includes(`.${fileExtension}`) : false;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;

    if (file && isValidFileType(file.name)) {
      onFileChange(file);
    } else {
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: 'INCORRECT_FILE_TYPE', type: notificationTypes.error },
      });
      onFileChange(null);
    }
  };

  return (
    <FileUploadStyled>
      <InputFileHandler
        type="file"
        accept={acceptedFileTypes || '*'}
        onChange={handleFileChange}
        id="fileInput"
      />
      <Button
        icon="paperclip"
        isDisabled={disabled || isSubmitting}
        variant="text"
        color="text-primary"
        size="md"
        onClick={() => document.getElementById('fileInput')?.click()}
      />
    </FileUploadStyled>
  );
};
export default FileUploadIcon;
