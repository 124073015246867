import React, { FC, memo, MouseEvent, ReactNode, useCallback } from 'react';
import { createPortal } from 'react-dom';

import usePrefix from 'utils/usePrefix';

import Backdrop from 'components/Backdrop';
import { CtaIcon } from 'components/_Redesign/CallToAction/styles';
import { CTA_ICON_MD_SIZE } from 'components/_Redesign/CallToAction/constants';

import { MOUNT_NODE } from './constants';
import { ButtonClose, ModalDialogStyled, ModalStyled } from './styles';
import { ModalAlign, ModalContent, ModalSize } from './types';

interface ModalProps {
  children?: ReactNode;
  className?: string;
  onClose: () => void;
  align?: ModalAlign;
  size?: ModalSize;
  content?: ModalContent;
  title?: string;
}

const Modal: FC<ModalProps> = ({ children, className, onClose, size, align, content }) => {
  const t = usePrefix('General');

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const stopPropagation = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  return createPortal(
    <>
      <ModalStyled>
        <ModalDialogStyled
          $align={align}
          $content={content}
          $size={size}
          className={className}
          onClick={stopPropagation}
          role="dialog"
        >
          <ButtonClose onClick={handleClose} size="md" title={t('cta_close')}>
            <CtaIcon type="close" width={CTA_ICON_MD_SIZE} height={CTA_ICON_MD_SIZE} />
          </ButtonClose>
          {children || null}
        </ModalDialogStyled>
      </ModalStyled>
      <Backdrop />
    </>,
    MOUNT_NODE,
  );
};

export default memo(Modal);
