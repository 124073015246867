import React, { FC, memo } from 'react';

import SetLanguage from 'components/SetLanguage';

import { NavigationStyled } from './styles';
import BottomMenu from './BottomMenu';
import MainMenu from './MainMenu';
import Logo from './Logo';

interface Props {
  logoUrl?: string;
}

const Navigation: FC<Props> = ({ logoUrl }) => (
  <NavigationStyled>
    <Logo logoUrl={logoUrl} />
    <MainMenu />
    <BottomMenu />
    <SetLanguage />
  </NavigationStyled>
);

export default memo(Navigation);
