import styled, { css } from 'styled-components';

import { ANIMATION } from 'styles/variables';
import { IS_DISABLED_ROW_CLASS, IS_SELECTED_ROW_CLASS, IS_UNREAD_ROW_CLASS } from './constants';

export const DataListStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
`;

const isClickableStyles = css`
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      border-color: var(--data-item-border--hover);
      background: var(--data-item-bg--hover);
      color: var(--data-item-txt--hover);
    }
  }
`;

export const DataRowStyled = styled.li<{
  clickable: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
  padding: 4px 16px;
  min-height: 48px;
  border: 1px solid var(--data-item-border);
  background: var(--data-item-bg);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--data-item-txt);
  transition: background-color ${ANIMATION};

  &:not(:first-child) {
    border-top-width: 0;
  }

  &.${IS_SELECTED_ROW_CLASS} {
    background: var(--data-item-bg--selected);
    color: var(--data-item-txt--selected);
  }

  &.${IS_UNREAD_ROW_CLASS} {
    background: var(--data-item-bg--unread);
    color: var(--data-item-txt--unread);
  }

  &.${IS_DISABLED_ROW_CLASS} {
    background: var(--data-item-bg--selected);
    color: var(--data-item-txt--selected);
    pointer-events: none;
    cursor: default;
  }

  ${({ clickable }) => (clickable ? isClickableStyles : '')};
`;
