import React from 'react';
import ReactSelect from 'react-select';
import { FieldArray, Formik, FormikProps } from 'formik';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';

import usePrefix from 'utils/usePrefix';
import { notificationTypes, userRoleTypes } from 'utils/constants';
import { api, getApiUrl, ResponseError } from 'utils/api';
import { UserCompanyPermissions, UserType } from 'utils/api/assignments';

import FlexDiv from 'components/FlexDiv';
import ToggleSwitchFormik from 'components/ToggleSwitchFormik';
import DetailsTitle from 'components/DetailsTitle';
import FormField from 'components/FormField';
import CheckboxInput from 'components/CheckboxInput';
import { customSelectStyles } from 'components/Select/styles';
import Button from 'components/_Redesign/Button';

import { ButtonsContainer, Content, FieldsContainer, StyledFormField } from './styles';

interface Props {
  onClose: () => void;
  isNew: boolean;
  companyId?: number;
  userId?: number;
  userFirstName: string | null;
  userLastName: string | null;
}

interface UserTypeOption {
  value: string;
  label: string;
}

const AssignmentForm: React.FC<Pick<FormikProps<UserCompanyPermissions>, 'values'> & Props> = ({
  onClose,
  isNew,
  userId,
  companyId,
  userFirstName,
  userLastName,
  values,
  ...props
}) => {
  const [{ violenceTypes, profile }, dispatch] = useApp();
  const t = usePrefix('General');
  const ta = usePrefix('Assignments');
  const tv = usePrefix('Violence');

  const url = getApiUrl('/director/me/permissions/company');

  const userTypes: UserTypeOption[] = [
    { value: 'DIRECTOR', label: 'DIRECTOR' },
    { value: 'INTERVENER', label: 'INTERVENER' },
  ];

  const userPermissions = profile?.company_id_to_user_permission;
  const permissionForSelectedCompany =
    companyId && userPermissions ? userPermissions[companyId] : null;
  const canChangeRole = permissionForSelectedCompany?.can_change_roles;

  const valueFromId = (userTypeOptions: UserTypeOption[], user_type: UserType | null) =>
    userTypeOptions.find((o: UserTypeOption) => o.value === user_type);

  const submitUserPermissions = async (values: UserCompanyPermissions) => {
    try {
      const response = await api(`${url}/${companyId}/user/${userId}`, {
        method: 'PUT',
        payload: { ...values },
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        onClose();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <Formik enableReinitialize={true} initialValues={values} onSubmit={submitUserPermissions}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Content {...props} autoComplete="off">
          <FlexDiv justifyContent="space-between" alignItems="center">
            <DetailsTitle title={ta('access') + ' - ' + userFirstName + ' ' + userLastName} />
            <ButtonsContainer alignItems="center">
              <Button
                label={t('save_changes')}
                color="primary"
                size="lg"
                type="submit"
                isDisabled={isSubmitting || !values.user_type}
              />
            </ButtonsContainer>
          </FlexDiv>
          <FieldsContainer>
            <FormField label={t('role')}>
              <ReactSelect
                name="user_type"
                onChange={(val: any) => {
                  setFieldValue('user_type', val.value);
                  if (val.value === userRoleTypes.intervener) {
                    setFieldValue('can_change_role', false);
                  }
                }}
                options={
                  userTypes?.length
                    ? userTypes.map((userType) => ({
                        value: userType.value,
                        label: t(userType.label),
                      }))
                    : []
                }
                value={valueFromId(
                  userTypes.map((userType) => ({
                    value: userType.value,
                    label: t(userType.label),
                  })),
                  values.user_type,
                )}
                isDisabled={isSubmitting || !canChangeRole}
                styles={customSelectStyles}
                isSearchable={false}
              />
            </FormField>
            <FormField label={ta('only_assigned_to_me')}>
              <ToggleSwitchFormik
                checked={values.only_assigned_to_me}
                disabled={isSubmitting}
                name="only_assigned_to_me"
              />
            </FormField>
            <FormField label={ta('can_change_role')}>
              <ToggleSwitchFormik
                checked={values.can_change_role}
                disabled={isSubmitting || values.user_type === userRoleTypes.intervener}
                name="can_change_role"
              />
            </FormField>
            <StyledFormField label={ta('assigned_categories')}>
              <FieldArray name="assigned_categories">
                {({
                  push,
                  remove,
                }: {
                  push: (id: number) => void;
                  remove: (index: number) => void;
                }) => (
                  <>
                    {violenceTypes.map((category) => (
                      <CheckboxInput
                        key={category.id}
                        name="assigned_categories"
                        label={tv(category.key)}
                        value={category.id}
                        disabled={isSubmitting}
                        checked={
                          values.assigned_categories
                            ? values.assigned_categories.includes(category.id)
                            : false
                        }
                        onChange={(event) => {
                          if (event.target.checked) {
                            push(category.id);
                          } else {
                            const index = values.assigned_categories.indexOf(category.id);
                            // eslint-disable-next-line no-magic-numbers
                            if (index > -1) {
                              remove(index);
                            }
                          }
                        }}
                      />
                    ))}
                  </>
                )}
              </FieldArray>
            </StyledFormField>
          </FieldsContainer>
        </Content>
      )}
    </Formik>
  );
};

export default AssignmentForm;
