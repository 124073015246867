import styled from 'styled-components';

export const Value = styled.div`
  padding-left: 8px;
`;

export const Label = styled.span`
  white-space: nowrap;
`;

export const ButtonSaveItemStyled = styled('div')`
  position: relative;
`;

export const TooltipStyled = styled('div')`
  position: absolute;
  right: 0;
  bottom: 100%;
  z-index: 1;
  transform: translate3d(0, -10px, 0);
  padding: 8px;
  min-width: 144px;
  width: fit-content;
  max-width: 200px;
  border-radius: 4px;
  border: 1px solid var(--context-menu-border);
  background: var(--context-menu-bg);
  color: var(--context-menu-txt);
  filter: drop-shadow(0 0 8px var(--context-menu-shadow));

  &:before {
    content: '';
    position: absolute;
    right: 16px;
    bottom: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid var(--context-menu-bg);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
`;
