/* eslint-disable no-magic-numbers */
import styled from 'styled-components';

import { screenMdAbove } from 'styles/breakpoinst';
import { LAYER_MODAL } from 'styles/layers';
import { calcSize } from 'styles/calcSize';
import Button from 'components/_Redesign/Button';
import Icon from 'components/Icons';

import { modalSizesFunc } from './sizes';
import { ModalAlign, ModalContent, ModalSize, ModalState } from './types';
import { modalAlignsFunc } from './align';
import { modalStatesFunc } from './states';
import { modalContentsFunc } from './content';

export const ModalStyled = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  padding: 8px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: ${LAYER_MODAL};

  @media screen and (min-width: ${screenMdAbove}) {
    padding: 24px;
  }
`;

export interface ModalDialogStyledProps {
  $align?: ModalAlign;
  $content?: ModalContent;
  $size?: ModalSize;
}

export const ModalDialogStyled = styled('div')<ModalDialogStyledProps>`
  position: relative;
  margin: 0 auto;
  padding: 24px;
  width: 100%;
  border-radius: 12px;
  background: var(--modal-bg);
  color: var(--modal-txt);
  box-shadow: 0 0 24px var(--modal-shadow);
  overflow: hidden;

  ${modalAlignsFunc};
  ${modalContentsFunc};
  ${modalSizesFunc};

  @media screen and (min-width: ${screenMdAbove}) {
    padding: 40px;
  }

  p {
    font-size: ${calcSize(16)};
    line-height: ${calcSize(20)};

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  li:not[class] {
    font-size: ${calcSize(16)};
    line-height: ${calcSize(20)};
  }

  hr {
    margin: 16px 0;
    border-top: 1px solid var(--modal-line);
  }

  table {
    table-layout: fixed;
    margin-block: 16px;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--modal-line);

    th,
    td {
      padding: 8px;
      border: 1px solid var(--modal-line);
      font-size: ${calcSize(16)};
      line-height: ${calcSize(20)};
      text-align: left;
    }

    th {
      font-weight: 500;
    }
  }
`;

export const ModalHeaderStyled = styled('div')`
  h3 {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: ${calcSize(20)};
    line-height: ${calcSize(24)};

    @media screen and (min-width: ${screenMdAbove}) {
      font-size: ${calcSize(22)};
      line-height: ${calcSize(36)};
    }
  }
`;

export const ModalBodyStyled = styled('div')`
  color: var(--modal-txt--secondary);
  white-space: pre-wrap;
`;

export const ModalActionsStyled = styled('div')`
  position: sticky;
  z-index: 1;
  bottom: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 0 -16px -16px;
  padding: 16px;
  border-radius: 0 0 12px 12px;
  background: var(--modal-bg);

  @media screen and (min-width: ${screenMdAbove}) {
    bottom: -24px;
    margin: 0 -24px -24px;
    padding: 24px;
  }
`;

export const ButtonClose = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  justify-content: center;
  padding: 4px;
  border-radius: 0;
  border-width: 0;
  width: 40px;
  height: 40px;
  background: var(--modal-bg);
  color: var(--modal-txt);

  @media (hover: hover) {
    &:hover:not(:disabled) {
      color: var(--modal-txt--secondary);
    }
  }
`;

export interface ModalIconProps {
  $state?: ModalState;
}

export const ModalIcon = styled(Icon)<ModalIconProps>`
  ${modalStatesFunc};
`;
