import React, { InputHTMLAttributes, memo } from 'react';

import { FormFieldSize } from 'components/_Redesign/FormElements/types';

import { InputStyled } from './styles';

export interface InputProps {
  autoFocus?: boolean;
  isAction?: boolean;
  sizeField: FormFieldSize;
  placeholder?: string;
  error?: boolean | string;
  type?: string;
}

const Input: React.FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
  error,
  autoFocus = false,
  isAction = false,
  placeholder,
  sizeField = 'md',
  type = 'text',
  ...props
}) => (
  <InputStyled
    $isAction={isAction}
    $isError={error}
    $sizeField={sizeField}
    autoFocus={autoFocus}
    type={type}
    placeholder={placeholder}
    {...props}
  />
);

export default memo(Input);
