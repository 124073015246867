import styled from 'styled-components';

export const PromptStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  justify-content: flex-start;
  align-items: flex-start;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: inherit;
    text-align: center;
  }
`;
