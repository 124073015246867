/* eslint-disable no-magic-numbers */
import styled from 'styled-components';

import { calcSize } from 'styles/calcSize';

export const ChatAdditionalDataStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  font-size: ${calcSize(14)};
  line-height: 1.5;
  color: var(--chat-msg-txt--secondary);
`;

export const ChatUserHistoryStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
`;

export const ChatUserDataStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 4px;
`;

export const ChatCaseNumberStyled = styled.div`
  margin-left: auto;
`;
