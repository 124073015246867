import React, { memo } from 'react';
import { useApp } from 'App';
import ReactSelect from 'react-select';
import { Form, Formik } from 'formik';

import { ActionTypes } from 'App/types';

import usePrefix from 'utils/usePrefix';
import { notificationTypes } from 'utils/constants';
import { api, ResponseError } from 'utils/api';
import { getApiUrl } from 'utils/api/useApi';

import { customSelectStyles } from 'components/Select/styles';
import { SidePanelContentStyled, SidePanelHeaderStyled } from 'components/SidePanel/styles';
import TextArea from 'components/TextArea';
import FormField from 'components/FormField';
import Button from 'components/_Redesign/Button';
import SidePanel from 'components/SidePanel';

import { ActionsListItemStyled, ActionsListStyled } from './styles';

const url = getApiUrl('/interveners/me/chats');

interface Props {
  onChatAdd: () => void;
  onChatCancel: () => void;
}

interface ViolenceCategory {
  value: number;
  label: string;
}

interface Schools {
  value: number;
  label: string;
}

interface FormValues {
  violenceCategory: ViolenceCategory | null;
  organization: Schools | null;
  content: string | null;
}

const NewChat: React.FC<Props> = ({ onChatAdd, onChatCancel }) => {
  const t = usePrefix('Chat');
  const tg = usePrefix('General');
  const tv = usePrefix('Violence');
  const tr = usePrefix('Reports');
  const [{ violenceTypes, schools }, dispatch] = useApp();

  const initialValues: FormValues = {
    violenceCategory: null,
    organization: null,
    content: '',
  };

  const defaultOrganizationId = schools.length === 1 ? schools[0].id : null;

  const handleAddNewChat = async (values: FormValues) => {
    const organizationId = values.organization?.value
      ? values.organization.value
      : defaultOrganizationId;
    const newChatPayload = {
      violence_category_id: values.violenceCategory?.value,
      organization_id: organizationId,
    };

    try {
      const response = await api(`${url}`, {
        method: 'POST',
        payload: newChatPayload,
      });
      if (response) {
        sendInitialMessage(response.id, values.content);
        onChatAdd();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const sendInitialMessage = async (newChatId: number, content: string | null) => {
    if (!newChatId || !content) return;
    try {
      const response = await api(`${url}/${newChatId}/messages/text-message`, {
        method: 'POST',
        payload: { message: content },
      });
      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <SidePanel onClose={onChatCancel}>
      <SidePanelContentStyled>
        <Formik initialValues={initialValues} onSubmit={handleAddNewChat} enableReinitialize={true}>
          {({ values, setFieldValue }) => (
            <Form autoComplete="off">
              <SidePanelHeaderStyled>
                <h3>{t('cta_add_chat')}</h3>
                <ActionsListStyled role="list">
                  <ActionsListItemStyled>
                    <Button
                      type="button"
                      onClick={onChatCancel}
                      label={tg('cancel')}
                      color="text-primary"
                      size="lg"
                    />
                  </ActionsListItemStyled>
                  <ActionsListItemStyled>
                    <Button
                      type="submit"
                      label={t('cta_add_chat')}
                      color="primary"
                      size="lg"
                      isDisabled={
                        schools?.length > 1
                          ? values.violenceCategory === null ||
                            values.organization === null ||
                            values.content === ''
                          : values.violenceCategory === null || values.content === ''
                      }
                    />
                  </ActionsListItemStyled>
                </ActionsListStyled>
              </SidePanelHeaderStyled>
              <div>
                <FormField label={t('chat_category')} margin={'0'}>
                  <ReactSelect
                    placeholder={tr('choose')}
                    name="violenceCategory"
                    onChange={(val) => setFieldValue('violenceCategory', val)}
                    options={
                      violenceTypes.length
                        ? violenceTypes.map((violence) => ({
                            value: violence.id,
                            label: tv(violence.key),
                          }))
                        : []
                    }
                    value={values.violenceCategory}
                    isSearchable={false}
                    styles={customSelectStyles}
                  />
                </FormField>
                {schools?.length > 1 && (
                  <FormField label={t('chat_organization')} margin={'0'}>
                    <ReactSelect
                      placeholder={tr('choose')}
                      name="organization"
                      onChange={(val) => setFieldValue('organization', val)}
                      options={
                        schools?.length
                          ? schools.map((school) => ({
                              value: school.id,
                              label: school.name,
                            }))
                          : []
                      }
                      value={values.organization}
                      isSearchable={false}
                      styles={customSelectStyles}
                    />
                  </FormField>
                )}
                <FormField label={t('chat_content')}>
                  <TextArea
                    height={'100px'}
                    cols={100}
                    name="content"
                    placeholder={t('type_chat_content')}
                    maxLength={1000}
                  />
                </FormField>
                <p>{tg('all_fields_required')}</p>
              </div>
            </Form>
          )}
        </Formik>
      </SidePanelContentStyled>
    </SidePanel>
  );
};

export default memo(NewChat);
