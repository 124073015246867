import React, { useEffect } from 'react';
import { FormikProps } from 'formik';

import { SchoolLicenceResponse } from 'utils/api/schools';
import usePrefix from 'utils/usePrefix';

import DataRow from 'components/DataRow';
import DataCell from 'components/DataCell';
import InputField from 'components/InputField';
import FlexDiv from 'components/FlexDiv';
import Button from 'components/_Redesign/Button';

import { ButtonActionsStyled } from '../styles';
import { Value, Label, ButtonSaveItemStyled, TooltipStyled } from './styles';

import { FormValues } from '.';

interface Props {
  licence: SchoolLicenceResponse;
  clickable?: boolean;
  isChanged: boolean;
  isEditing: boolean;
  isDisabled: boolean;
  setIsEditing: (set: boolean) => void;
}

const Row: React.FC<Pick<FormikProps<FormValues>, 'errors' | 'touched' | 'resetForm'> & Props> = ({
  licence,
  clickable = false,
  errors,
  touched,
  isChanged,
  isEditing,
  isDisabled,
  setIsEditing,
  resetForm,
  ...props
}) => {
  const t = usePrefix('Schools');

  const COLS_NUMBER = 4;

  useEffect(() => {
    if (!isEditing) resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  return (
    <DataRow clickable={clickable} {...props}>
      <DataCell cols={COLS_NUMBER} label={`${t('from')} ${licence.date_from}`} />
      <DataCell cols={COLS_NUMBER} label={`${t('to')}`}>
        <Value>
          {!isEditing ? (
            <Label>{licence.date_to}</Label>
          ) : (
            <FlexDiv justifyContent="flex-start" alignItems="center" flex={1}>
              <InputField
                name="date_to"
                error={touched.date_to && !!errors.date_to}
                autoFocus
                type="date"
                dimension="sm"
              />
            </FlexDiv>
          )}
        </Value>
      </DataCell>
      <DataCell cols={COLS_NUMBER}>
        {!isEditing ? (
          <Label>
            {t('licensed_of_max', {
              licensed: licence.number_of_licensed_reporters,
              max: licence.max_number_of_reporters,
            })}
          </Label>
        ) : (
          <FlexDiv justifyContent="flex-start" alignItems="center" flex={1}>
            <Label>{t('max_slots')}</Label>
            <Value>
              <InputField
                name="max_number_of_reporters"
                error={touched.max_number_of_reporters && !!errors.max_number_of_reporters}
                type="number"
                dimension="sm"
              />
            </Value>
          </FlexDiv>
        )}
      </DataCell>
      <DataCell cols={COLS_NUMBER}>
        <ButtonActionsStyled>
          <Button
            icon="pencil"
            onClick={() => setIsEditing(true)}
            title={t('edit')}
            isDisabled={isEditing}
            size="lg"
            color="text-primary"
            variant="text"
          />
          <ButtonSaveItemStyled>
            <Button
              color="primary"
              isDisabled={isDisabled || !isEditing}
              label={t('save')}
              size="lg"
              type="submit"
            />
            {!isChanged && <TooltipStyled>{t('licence_not_saved')}</TooltipStyled>}
          </ButtonSaveItemStyled>
        </ButtonActionsStyled>
      </DataCell>
    </DataRow>
  );
};

export default Row;
