/* eslint-disable no-magic-numbers */
import React, { FC, memo, useCallback, useState } from 'react';

import { APP_URL } from 'App/constants';

import logoDesktop from 'images/signalink_logo_inverted.svg';
import logoMobile from 'images/signalink_logo_sygnet.svg';

import { LogoLink } from './styles';

interface Props {
  logoUrl?: string;
}

const Logo: FC<Props> = ({ logoUrl }) => {
  const isMobile = window.innerWidth <= 1279; // TODO - dopracowanie na resize
  let logoSrc = isMobile ? logoMobile : logoDesktop;
  const [isError, setIsError] = useState(false);

  const onError = useCallback(() => setIsError(true), []);

  if (logoUrl) {
    const logoBaseUrl = window.location.origin;
    logoSrc = `${logoBaseUrl}/${logoUrl}`;
  }

  return (
    <LogoLink to={`/${APP_URL}`}>
      {!isError ? <img src={logoSrc} onError={onError} alt="Logo" /> : null}
    </LogoLink>
  );
};

export default memo(Logo);
