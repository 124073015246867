import React from 'react';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';

import { ResponseError } from 'utils/api';
import { getApiUrl } from 'utils/api/useApi';
import { notificationTypes } from 'utils/constants';
import usePrefix from 'utils/usePrefix';

import Button from 'components/_Redesign/Button';

import { StyledFormField } from '../styles';
import { UploadActionStyled, UploadFileRowStyled, UploadInputStyled } from './styles';

interface Props {
  schoolId?: number;
}

const BrandingSection: React.FC<Props> = ({ schoolId }) => {
  const t = usePrefix('Schools');
  const [, dispatch] = useApp();

  const handleBrandingUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    isMobileBranding: boolean,
  ) => {
    const uploadUrl = isMobileBranding
      ? getApiUrl('/mobile-branding-by-file/company/')
      : getApiUrl('/www-branding-by-file/company/');
    const brandingFile = e.target.files && e.target.files[0];
    if (!brandingFile || !schoolId) {
      return;
    }

    const formData = new FormData();
    formData.append('file', brandingFile);

    try {
      await fetch(`${uploadUrl}${schoolId}`, {
        method: 'POST',
        body: formData,
      });
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { type: notificationTypes.success },
      });
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const handleBrandingDownload = async (isMobileBranding: boolean) => {
    const url = isMobileBranding
      ? getApiUrl('/mobile-branding/company/')
      : getApiUrl('/www-branding/company/');

    try {
      const response = await fetch(`${url}${schoolId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // eslint-disable-next-line no-magic-numbers
      if (response.status !== 200) {
        throw new Error();
      }
      const jsonData = await response.json();

      // eslint-disable-next-line no-magic-numbers
      const fileBlob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json' });
      const fileUrl = URL.createObjectURL(fileBlob);

      const downloadLink = document.createElement('a');
      downloadLink.href = fileUrl;
      downloadLink.download = isMobileBranding ? 'mobile-branding.json' : 'www-branding.json';
      document.body.appendChild(downloadLink);
      downloadLink.click();

      URL.revokeObjectURL(fileUrl);
      downloadLink.remove();
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const handleLogoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadUrl = getApiUrl('/www-branding/company/');

    const brandingFile = e.target.files && e.target.files[0];
    if (!brandingFile || !schoolId) {
      return;
    }

    const formData = new FormData();
    formData.append('file', brandingFile);

    try {
      await fetch(`${uploadUrl}${schoolId}/logo`, {
        method: 'POST',
        body: formData,
      });
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { type: notificationTypes.success },
      });
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const handleLogoDownload = async () => {
    const url = getApiUrl('/www-branding/company/');

    try {
      const response = await fetch(`${url}${schoolId}/logo`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch logo.');
      }
      const jsonData = await response.json();
      const logoBaseUrl = window.location.origin;
      const { uri } = jsonData.branding_logo;

      window.open(`${logoBaseUrl}/${uri}`, '_blank');
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const renderBrandingSection = (label: string, mobile: boolean) => (
    <UploadFileRowStyled>
      <StyledFormField label={label}>
        <UploadInputStyled
          type="file"
          accept=".json"
          onChange={(e) => handleBrandingUpload(e, mobile)}
        />
      </StyledFormField>
      <UploadActionStyled>
        <Button
          label={t('download_branding')}
          onClick={() => handleBrandingDownload(mobile)}
          size="lg"
          color="secondary"
        />
      </UploadActionStyled>
    </UploadFileRowStyled>
  );

  return (
    <>
      {renderBrandingSection(t('www_branding'), false)}
      {renderBrandingSection(t('mobile_branding'), true)}
      <UploadFileRowStyled>
        <StyledFormField label={t('www_logo')}>
          <UploadInputStyled type="file" onChange={handleLogoUpload} />
        </StyledFormField>
        <UploadActionStyled>
          <Button
            label={t('cta_download_logo')}
            size="lg"
            color="primary"
            onClick={handleLogoDownload}
          />
        </UploadActionStyled>
      </UploadFileRowStyled>
    </>
  );
};

export default BrandingSection;
