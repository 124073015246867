import { css } from 'styled-components';

import { NotificationType } from './types';

export const notificationType = (color: NotificationType) =>
  ({
    error: css`
      path {
        fill: var(--state-error);
      }
    `,
    success: css`
      path {
        fill: var(--state-success);
      }
    `,
    warning: css`
      path {
        fill: var(--state-warning);
      }
    `,
  })[color];
