import React, { memo } from 'react';

import usePrefix from 'utils/usePrefix';

import Button from 'components/_Redesign/Button';
import {
  ModalActionsStyled,
  ModalBodyStyled,
  ModalHeaderStyled,
  ModalIcon,
} from 'components/_Redesign/Modal/styles';
import { MODAL_ICON_SIZE_CSS } from 'components/_Redesign/Modal/constants';
import Modal from 'components/_Redesign/Modal';

interface Props {
  title: string;
  text: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmModal: React.FC<Props> = ({ title, text, onCancel, onConfirm }) => {
  const t = usePrefix('General');

  return (
    <Modal onClose={onCancel} align="center" size="sm">
      <ModalHeaderStyled>
        <ModalIcon
          type="trash"
          width={MODAL_ICON_SIZE_CSS}
          height={MODAL_ICON_SIZE_CSS}
          $state="error"
        />
        {title && <h3>{title}</h3>}
      </ModalHeaderStyled>
      <ModalBodyStyled>{text && <p>{text}</p>}</ModalBodyStyled>
      <ModalActionsStyled>
        <Button onClick={onCancel} color="secondary" size="lg" label={t('cancel')} />
        <Button onClick={onConfirm} color="error" size="lg" label={t('yes')} />
      </ModalActionsStyled>
    </Modal>
  );
};

export default memo(ConfirmModal);
