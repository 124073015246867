import React, { InputHTMLAttributes, memo } from 'react';
import { FieldProps, Field } from 'formik';

import { Checkmark, StyledInput, ValueStyled, CheckboxInputStyled } from './styles';

export interface InputProps {
  name: string;
  value: string | number;
  checked: boolean;
  placeholder?: string;
  error?: boolean;
  label?: string;
}

const CheckboxInput: React.FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
  name,
  value,
  placeholder,
  label,
  error = false,
  checked,
  ...props
}) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <CheckboxInputStyled>
        <StyledInput
          {...field}
          type="checkbox"
          name={name}
          value={value}
          error={error}
          placeholder={placeholder}
          checked={checked}
          {...props}
        />
        <ValueStyled>{label}</ValueStyled>
        <Checkmark />
      </CheckboxInputStyled>
    )}
  </Field>
);

export default memo(CheckboxInput);
