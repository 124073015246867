import React, { useEffect } from 'react';

import { useApi, getApiUrl } from 'utils/api/useApi';
import { ReceivedAdviceDetailsResponse, ReceivedAdviceResponse } from 'utils/api/inbox';
import usePrefix from 'utils/usePrefix';
import { PaginatedList } from 'utils/api';

import NoticeContent from 'components/NoticeContent';
import Loader from 'components/Loader';

const url = getApiUrl('/interveners/me/advices');

interface Props {
  adviceId: number;
  fetchData: () => Promise<void | PaginatedList<ReceivedAdviceResponse[]>>;
}

const Advice: React.FC<Props> = ({ adviceId, fetchData }) => {
  const t = usePrefix('Advices');

  const { data, isLoading } = useApi<ReceivedAdviceDetailsResponse>(`${url}/${adviceId}`, {
    method: 'GET',
  });

  useEffect(() => {
    if (data) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data) return null;

  return !isLoading ? <NoticeContent {...data.details} /> : <Loader label={t('loading')} />;
};

export default Advice;
