import React, { FC, memo } from 'react';

import usePrefix from 'utils/usePrefix';
import LoaderBouncing from 'components/LoaderBouncing';

import { FetchingMessageStyled } from './styles';

interface Props {
  title?: string;
}

const FetchingMessage: FC<Props> = ({ title }) => (
  <FetchingMessageStyled>
    <LoaderBouncing />
    <h3>{title}</h3>
  </FetchingMessageStyled>
);

export default memo(FetchingMessage);
