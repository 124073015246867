import React from 'react';

import Loader from 'components/Loader';
import usePrefix from 'utils/usePrefix';

const TranslationsLoader: React.FC = () => {
  const t = usePrefix('General');

  return <Loader label={t('fetching')} />;
};

export default TranslationsLoader;
