export const THEME = {
  '--app-bg': '#fff',
  '--app-txt--primary': '#191d21',
  '--app-txt--secondary': '#707171',
  '--app-shadow': 'rgba(0, 66, 117, 0.08)',

  '--state-error': '#c42724',
  '--state-error-bg': '#c42724',
  '--state-error-txt': '#fff',
  '--state-success': '#9bc53d',
  '--state-success-bg': '#9bc53d',
  '--state-success-txt': '#fff',
  '--state-warning': '#fdc420',
  '--state-warning-bg': '#fdc420',
  '--state-warning-txt': '#191d21',

  '--scrollbar-track-bg': '#f2f2f2',
  '--scrollbar-thumb-bg': '#ccc',
  '--scrollbar-thumb-bg--hover': '#bbb',

  '--focus-state': '#4de1d5',

  '--overlay-bg': 'rgba(0, 0, 0, 0.3)',

  '--notification-bg': '#191d21',
  '--notification-bg--hover': '#000',
  '--notification-txt': '#fff',
  '--notification-txt--hover': '#fff',

  '--access-page-bg': '#f6f8f8',
  '--access-page-card-bg': '#fff',
  '--access-page-card-txt': '#191d21',
  '--access-page-card-shadow': 'rgba(0, 66, 117, 0.08)',

  '--navigation-bg': '#0b5959',
  '--navigation-txt': '#fff',
  '--navigation-item-border': '#0b5959',
  '--navigation-item-border--hover': '#3c7a7a',
  '--navigation-item-border--active': '#fff',
  '--navigation-item-bg': '#0b5959',
  '--navigation-item-bg--hover': '#3c7a7a',
  '--navigation-item-bg--active': '#fff',
  '--navigation-item-txt': '#fff',
  '--navigation-item-txt--hover': '#fff',
  '--navigation-item-txt--active': '#0b5959',
  '--navigation-line': '#fff',

  '--status-page-bg': '#fff',
  '--status-page-txt--primary': '#191d21',
  '--status-page-txt--secondary': '#707171',

  '--cta-primary-border': '#0b5959',
  '--cta-primary-border--hover': '#3c7a7a',
  '--cta-primary-border--active': '#cedede',
  '--cta-primary-border--disabled': '#f3f5f5',
  '--cta-primary-bg': '#0b5959',
  '--cta-primary-bg--hover': '#3c7a7a',
  '--cta-primary-bg--active': '#cedede',
  '--cta-primary-bg--disabled': '#f3f5f5',
  '--cta-primary-txt': '#fff',
  '--cta-primary-txt--hover': '#fff',
  '--cta-primary-txt--active': '#191d21',
  '--cta-primary-txt--disabled': '#707171',
  '--cta-primary-shadow': 'rgba(0, 66, 117, 0)',

  '--cta-secondary-border': '#fff',
  '--cta-secondary-border--hover': '#d2f7f5',
  '--cta-secondary-border--active': '#0b3838',
  '--cta-secondary-border--disabled': '#f3f5f5',
  '--cta-secondary-bg': '#fff',
  '--cta-secondary-bg--hover': '#fff',
  '--cta-secondary-bg--active': '#fff',
  '--cta-secondary-bg--disabled': '#f3f5f5',
  '--cta-secondary-txt': '#0b3838',
  '--cta-secondary-txt--hover': '#191d21',
  '--cta-secondary-txt--active': '#191d21',
  '--cta-secondary-txt--disabled': '#707171',
  '--cta-secondary-shadow': 'rgba(0, 66, 117, 0.08)',

  '--cta-error-border': '#c42724',
  '--cta-error-border--hover': '#ce4845',
  '--cta-error-border--active': '#f18482',
  '--cta-error-border--disabled': '#f3f5f5',
  '--cta-error-bg': '#c42724',
  '--cta-error-bg--hover': '#ce4845',
  '--cta-error-bg--active': '#f18482',
  '--cta-error-bg--disabled': '#f3f5f5',
  '--cta-error-txt': '#fff',
  '--cta-error-txt--hover': '#fff',
  '--cta-error-txt--active': '#fff',
  '--cta-error-txt--disabled': '#707171',
  '--cta-error-shadow': 'rgba(0, 66, 117, 0.08)',

  '--cta-text-primary-txt': '#15656c',
  '--cta-text-primary-txt--hover': '#1b8e98',
  '--cta-text-primary-txt--active': '#49a5ad',
  '--cta-text-primary-txt--disabled': '#707171',

  '--cta-text-error-txt': '#c42724',
  '--cta-text-error-txt--hover': '#ce4845',
  '--cta-text-error-txt--active': '#f18482',
  '--cta-text-error-txt--disabled': '#707171',

  '--cta-link-txt': '#15656c',
  '--cta-link-txt--hover': '#15656c',

  '--modal-bg': '#fff',
  '--modal-txt--primary': '#191d21',
  '--modal-txt--secondary': '#707171',
  '--modal-line': '#dbdbdc',
  '--modal-shadow': 'rgba(0, 66, 117, 0.08)',

  '--form-label-txt': '#191d21',
  '--form-label-txt--error': '#c42724',
  '--form-field-border': '#fff',
  '--form-field-border--error': '#c42724',
  '--form-field-border--focus': '#4de1d5',
  '--form-field-bg': '#fff',
  '--form-field-bg--error': '#fff',
  '--form-field-txt': '#191d21',
  '--form-field-txt--error': '#c42724',
  '--form-field-txt--placeholder': '#707171',
  '--form-field-shadow': 'rgba(0, 66, 117, 0.08)',

  '--loader-msg-bg': '#0b5959',
  '--loader-msg-txt': '#fff',
  '--loader-dot-bg': '#0b5959',

  '--fetching-bg': 'rgba(255, 255, 255, 0.7)',
  '--fetching-txt': '#191d21',

  '--context-menu-border': '#fff',
  '--context-menu-bg': '#fff',
  '--context-menu-txt': '#191d21',
  '--context-menu-shadow': 'rgba(0, 66, 117, 0.08)',
  '--context-menu-btn-bg': '#fff',
  '--context-menu-btn-bg--hover': '#e7efef',
  '--context-menu-btn-bg--active': '#cedede',
  '--context-menu-btn-txt': '#191d21',
  '--context-menu-btn-txt--hover': '#191d21',
  '--context-menu-btn-txt--active': '#191d21',

  '--switch-track-border': '#0b5959',
  '--switch-track-border--active': '#0b5959',
  '--switch-track-border--disabled': '#f3f5f5',
  '--switch-track-bg': '#eee',
  '--switch-track-bg--active': '#0b5959',
  '--switch-track-bg--disabled': '#f3f5f5',
  '--switch-dot-bg': '#0b5959',
  '--switch-dot-bg--active': '#fff',
  '--switch-dot-bg--disabled': '#949495',

  '--radio-bg': '#fff',
  '--radio-txt': '#191d21',
  '--radio-txt--disabled': '#949495',
  '--radio-check-border': '#949495',
  '--radio-check-border--hover': '#3c7a7a',
  '--radio-check-border--disabled': '#dbdbdc',
  '--radio-check-border--active': '#0b5959',
  '--radio-check-bg': '#eee',
  '--radio-check-bg--hover': '#3c7a7a',
  '--radio-check-bg--disabled': '#f3f5f5',
  '--radio-check-bg--active': '#0b5959',

  '--list-border': '#dbdbdc',
  '--list-bg': '#fff',
  '--list-bg--hover': '#e5e5e5',
  '--list-txt--primary': '#191d21',

  '--page-title-txt': '#191d21',

  '--table-actions-txt': '#191d21',

  '--notice-border': '#dbdbdc',
  '--notice-txt--primary': '#191d21',
  '--notice-txt--secondary': '#707171',
  '--notice-attachment-txt': '#0b5959',
  '--notice-attachment-icon-txt': '#707171',

  '--target-border': '#dbdbdc',
  '--target-border--selected': '#cedede',
  '--target-txt': '#191d21',
  '--target-value-txt': '#191d21',

  '--criteria-border': '#dbdbdc',

  '--side-panel-border': '#dbdbdc',

  '--reports-txt--primary': '#191d21',
  '--reports-txt--secondary': '#707171',

  '--chat-panel-name-action-txt': '#0b5959',

  '--chat-meta-border': '#dbdbdc',
  '--chat-meta-bg': '#fff',
  '--chat-meta-txt': '#191d21',
  '--chat-meta-toolbar-border--transparent': '#e7efef',
  '--chat-meta-toolbar-bg': '#e7efef',
  '--chat-meta-toolbar-bg--transparent': '#fff',
  '--chat-meta-toolbar-txt': '#191d21',
  '--chat-meta-toolbar-txt--transparent': '#666',
  '--chat-meta-item-border': '#e7efef',
  '--chat-meta-item-bg': '#fff',
  '--chat-meta-item-txt': '#707171',

  '--chat-msg-border': '#fff',
  '--chat-msg-border--user': '#fff',
  '--chat-msg-border--intervent': '#0b5959',
  '--chat-msg-bg--user': '#fff',
  '--chat-msg-bg--intervent': '#0b5959',
  '--chat-msg-txt--intervent--primary': '#fff',
  '--chat-msg-txt--intervent--secondary': '#fff',
  '--chat-msg-txt--user--primary': '#191d21',
  '--chat-msg-txt--user--secondary': '#707171',
  '--chat-msg-author-txt--intervent': '#0b5959',
  '--chat-msg-author-txt--user': '#191d21',
  '--chat-msg-action-txt--intervent': '#fff',
  '--chat-msg-action-txt--intervent--hover': '#e7efef',
  '--chat-msg-action-txt--intervent--disabled': '#cedede',
  '--chat-msg-action-txt--user': '#0b5959',
  '--chat-msg-action-txt--user--hover': '#3c7a7a',
  '--chat-msg-action-txt--user--disabled': '#707171',
  '--chat-msg-form-bg': '#fff',
  '--chat-msg-link-txt': '#3e3e3e',
  '--chat-msg-dot-bg': '#bbb',
  '--chat-msg-shadow': 'rgba(0, 66, 117, 0.08)',

  '--chat-list-border': '#dbdbdc',

  '--chat-tab-border': '#dbdbdc',
  '--chat-tab-txt': '#0b3838',
  '--chat-tab-txt--active': '#0b3838',

  '--chart-label-txt': '#191d21',
  '--chart-bg--1': '#0b5959',
  '--chart-bg--2': '#3c7a7a',
  '--chart-bg--3': '#77b1b1',
  '--chart-bg--4': '#9dbdbd',
  '--chart-bg--5': '#cedede',

  '--search-btn-reset-bg': '#cedede',

  '--select-border': '#fff',
  '--select-border--hover': '#fff',
  '--select-bg': '#fff',
  '--select-bg--hover': '#fff',
  '--select-txt': '#191d21',
  '--select-txt--hover': '#191d21',
  '--select-shadow': 'rgba(0, 66, 117, 0.08)',
  '--select-option-bg': '#fff',
  '--select-option-bg--hover': '#e7efef',
  '--select-option-bg--selected': '#cedede',
  '--select-option-bg--selected--hover': '#e7efef',
  '--select-option-txt': '#191d21',
  '--select-option-txt--hover': '#191d21',
  '--select-option-txt--selected': '#fff',
  '--select-option-txt--selected--hover': '#191d21',
  '--select-option-txt--placeholder': '#949495',

  '--list-item-bg': '#fff',
  '--list-item-bg--unread': '#0b5959',
  '--list-item-bg--selected': '#fff',
  '--list-item-bg--hover': '#9dbdbd',
  '--list-item-txt': '#191d21',
  '--list-item-txt--unread': '#fff',
  '--list-item-txt--selected': '#191d21',
  '--list-item-txt--hover': '#191d21',
  '--list-item-dot-bg': '#ce4845',
  '--list-item-shadow': 'rgba(0, 66, 117, 0.08)',

  '--data-item-border': '#dbdbdc',
  '--data-item-border--selected': '#0b5959',
  '--data-item-border--unread': '#dbdbdc',
  '--data-item-border--hover': '#dbdbdc',
  '--data-item-bg': '#fff',
  '--data-item-bg--selected': '#cedede',
  '--data-item-bg--unread': '#0b5959',
  '--data-item-bg--hover': '#f3f5f5',
  '--data-item-txt': '#191d21',
  '--data-item-txt--selected': '#707171',
  '--data-item-txt--unread': '#fff',
  '--data-item-txt--hover': '#191d21',
};
