import styled, { css } from 'styled-components';
import { Form } from 'formik';

import InputField from 'components/InputField';
import FlexDiv from 'components/FlexDiv';
import FormField from 'components/FormField';

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  padding-block: 48px;
  width: 100%;
  border-bottom: 1px solid var(--list-border);
  overflow: auto;

  &:first-child {
    padding-top: 0;
  }
`;

export const Content = styled(Form)`
  ${contentStyles}
`;

export const SectionWrapper = styled.section`
  ${contentStyles}
`;

export const TextData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  min-width: 0;
  min-height: 48px;
`;

const subtitleEmptyStyles = css`
  opacity: 0.2;
`;

export const Subtitle = styled.h3<{ empty: boolean }>`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: var(--list-txt--primary);
  ${({ empty }) => empty && subtitleEmptyStyles};
`;

export const ButtonsContainer = styled(FlexDiv)`
  > button {
    margin-left: 20px;
  }
`;

const INPUT_GROUP_WIDTH = '372px';

export const FormSpacer = styled.div`
  min-height: 24px;
  height: 24px;
`;

export const StyledInputField = styled(InputField)`
  width: ${INPUT_GROUP_WIDTH};
  margin-bottom: 24px;
`;
export const StyledFullField = styled(InputField)`
  width: 100%;
  margin-bottom: 24px;
`;

export const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  width: 880px;
  margin-top: 24px;
`;

export const SectionTitle = styled.div`
  margin: 24px 0;
  font-weight: 700;
  font-size: 18px;
  color: var(--list-txt--primary);
`;

export const CodeField = styled(FlexDiv)`
  width: 100%;
  max-width: ${INPUT_GROUP_WIDTH};
`;

export const ButtonActionsStyled = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  width: 100%;
  min-width: 0;
`;

export const SwitchLabel = styled.label`
  margin-bottom: 4px;
  margin-left: 12px;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--form-label-txt);
`;

export const SwitchContainer = styled(FlexDiv)`
  margin-bottom: 24px;
`;

export const LanguagesContainer = styled(FlexDiv)``;

export const StyledFormField = styled(FormField)`
  max-width: 320px;
  margin-bottom: 24px;
`;
