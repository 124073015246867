import React, { FC, memo } from 'react';

import { NotificationsListTypes } from 'App/reducer';

import Notification from './Notification';
import { NotificationsStyled } from './styles';

const Notifications: FC<{ notifications: NotificationsListTypes[] }> = ({ notifications }) => (
  <NotificationsStyled>
    {notifications.map(({ notification, id }) => (
      <Notification key={`${id}${notification.code}`} notification={notification} id={id} />
    ))}
  </NotificationsStyled>
);

export default memo(Notifications);
