import React, { InputHTMLAttributes, memo } from 'react';
import { StyledInput } from './styles';

export type FieldDimensions = 'lg' | 'md' | 'sm';

export interface InputProps {
  placeholder?: string;
  error?: boolean | string;
  type?: string;
  dimension?: FieldDimensions;
  isButton?: boolean;
}

const TextInput: React.FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
  placeholder,
  type = 'text',
  error,
  dimension = 'lg',
  isButton,
  ...props
}) => (
  <StyledInput
    type={type}
    error={error}
    placeholder={placeholder}
    dimension={dimension}
    isButton={isButton}
    {...props}
  />
);

export default memo(TextInput);
