import styled, { css } from 'styled-components';

import { screenLgAbove } from 'styles/breakpoinst';
import {
  MAIN_AREA_CSS,
  NAVIGATION_AREA_CSS,
  NAVIGATION_WIDTH_ABOVE_LG_CSS,
  NAVIGATION_WIDTH_UNDER_LG_CSS,
} from './constants';

interface LayoutProps {
  $isLogged: boolean;
}

const isLoggedViewStyles = css`
  grid-template-areas: '${NAVIGATION_AREA_CSS} ${MAIN_AREA_CSS}';
  grid-template-columns: ${NAVIGATION_WIDTH_UNDER_LG_CSS} 1fr;

  @media screen and (min-width: ${screenLgAbove}) {
    grid-template-columns: ${NAVIGATION_WIDTH_ABOVE_LG_CSS} 1fr;
  }
`;

const isNotLoggedViewStyles = css`
  grid-template-columns: 1fr;
`;

export const LayoutStyled = styled.main<LayoutProps>`
  ${({ $isLogged }) => ($isLogged ? isLoggedViewStyles : isNotLoggedViewStyles)};
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-rows: 1fr;
  width: 100%;
  height: 100vh;
  height: 100dvh;
`;
