import React, { memo } from 'react';

import { NoticeContainerStyled } from './styles';

interface NoticeContainerProps {
  children?: React.ReactNode;
}

const NoticeContainer: React.FC<NoticeContainerProps> = ({ children }) => (
  <NoticeContainerStyled>{children}</NoticeContainerStyled>
);

export default memo(NoticeContainer);
