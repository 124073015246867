import styled, { css } from 'styled-components';

import FlexDiv from 'components/FlexDiv';

import { TEXT_AREA_HEIGHT_CSS } from './constants';

export const Wrapper = styled(FlexDiv)`
  width: 100%;
`;

// TODO - do poprawy przy redesign
export const StyledTextArea = styled.textarea<{ height?: string }>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 11px 12px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--form-field-border);
  background: var(--form-field-bg);
  color: var(--form-field-txt);
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  user-select: none;
  vertical-align: middle;
  box-shadow: 0 6px 20px 0 var(--form-field-shadow);
  appearance: none;
  min-height: ${TEXT_AREA_HEIGHT_CSS};
  resize: none;
  outline: none;
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
  &::placeholder {
    color: var(--form-field-txt--placeholder);
  }

  &:focus-visible {
    border-color: var(--form-field-border--focus);
  }
`;
