import React, { useState } from 'react';

import RegisterOfNotifications from 'pages/Reports/RegisterOfNotifications';

import usePrefix from 'utils/usePrefix';
import {
  ReportGeneralResponse,
  ReportDetailResponse,
  UsersMgmntReportResponse,
  ChatReportDetailsResponse,
} from 'utils/api/report';
import { getApiUrl } from 'utils/api';
import { userRoles } from 'utils/userRoles';
import { useApp } from 'App';
import circles from 'images/circles.svg';

import PageTitle from 'components/PageTitle';
import FlexDiv from 'components/FlexDiv';
import ButtonHref from 'components/_Redesign/ButtonHref';

import ChartPanel from './ChartPanel';
import Criteria, { LocationSpecificTargetType, ReportType } from './Criteria';
import UsersManagement from './UsersManagement';
import { Wrapper, ChartContainer, ReportsTip, ChartDetailStyled } from './styles';

const Reports: React.FC = () => {
  const [dataGeneral, setGeneralData] = useState<ReportGeneralResponse>();
  const [dataDetail, setDetailData] = useState<ReportDetailResponse[]>();
  const [usersMgmntData, setUsersMgmntData] = useState<UsersMgmntReportResponse>();
  const [registerOfNotificationsData, setRegisterOfNotificationsData] =
    useState<ChatReportDetailsResponse[]>();
  const [reportParams, setReportParams] = useState('');
  const [reportType, setReportType] = useState<ReportType | undefined>(undefined);
  const [schoolId, setSchooldId] = useState<LocationSpecificTargetType>();
  const [{ violenceTypes }] = useApp();
  const [{ profile }] = useApp();
  const t = usePrefix('Reports');

  const isDirector = profile?.role === userRoles.director;

  const urlAdmin = getApiUrl('/reports');
  const urlDirector = getApiUrl('/director/me/reports');

  const url = isDirector ? `${urlDirector}/${schoolId}` : urlAdmin;
  const urlRegisterOfNotifications = isDirector ? `${urlDirector}` : urlAdmin;

  const handleDetailData = (data?: ReportDetailResponse[]) => {
    setDetailData(data);
    setGeneralData(undefined);
    setUsersMgmntData(undefined);
    setRegisterOfNotificationsData(undefined);
    setReportType('licence');
  };

  const handleGeneralData = (data?: ReportGeneralResponse) => {
    setGeneralData(data);
    setDetailData(undefined);
    setUsersMgmntData(undefined);
    setRegisterOfNotificationsData(undefined);
    setReportType('violence');
  };

  const handleUsersMgmntData = (data?: UsersMgmntReportResponse) => {
    setGeneralData(undefined);
    setDetailData(undefined);
    setUsersMgmntData(data);
    setRegisterOfNotificationsData(undefined);
    setReportType('users_mgmnt');
  };

  const handleRegisterOfNotificationsData = (data?: ChatReportDetailsResponse[]) => {
    setGeneralData(undefined);
    setDetailData(undefined);
    setUsersMgmntData(undefined);
    setRegisterOfNotificationsData(data);
    setReportType('chats');
  };

  if (!violenceTypes.length) return null;

  // eslint-disable-next-line no-magic-numbers
  const isTransitionState =
    (dataGeneral ? 1 : 0) + (dataDetail ? 1 : 0) + (usersMgmntData ? 1 : 0) > 1;

  const title = dataGeneral
    ? t('violence_types')
    : dataDetail
    ? t('licence_use')
    : usersMgmntData
    ? t('users_mgmnt')
    : registerOfNotificationsData
    ? t('register_of_notifications')
    : 'unknown';

  return (
    <Wrapper alignItems="stretch">
      <ChartContainer>
        {!isTransitionState &&
        (dataGeneral || dataDetail || usersMgmntData || registerOfNotificationsData) ? (
          <>
            <FlexDiv justifyContent="space-between" alignItems="center" width="100%">
              <PageTitle title={title} />
              <ButtonHref
                href={`${
                  registerOfNotificationsData ? urlRegisterOfNotifications : url
                }/${reportType}/csv${reportParams}`}
                label={t('download_report')}
                color="primary"
                size="lg"
                download
              />
            </FlexDiv>
            <ChartDetailStyled>
              <ChartPanel
                dataGeneral={dataGeneral}
                dataDetail={dataDetail}
                usersMgmntData={usersMgmntData}
                registerOfNotificationsData={registerOfNotificationsData}
              />
            </ChartDetailStyled>
          </>
        ) : (
          <FlexDiv flexDirection="column" alignItems="center">
            <PageTitle title={t('no_generated_raports')} paddingBottom="70px" />
            <img src={circles} alt={t('chart_placeholder')} />
            <ReportsTip>{t('reports_tip')}</ReportsTip>
          </FlexDiv>
        )}
        {usersMgmntData && <UsersManagement reportParams={reportParams} schoolId={schoolId} />}
        {registerOfNotificationsData && <RegisterOfNotifications reportParams={reportParams} />}
      </ChartContainer>
      <Criteria
        setGeneralData={handleGeneralData}
        setRegisterOfNotificationsData={handleRegisterOfNotificationsData}
        setDetailData={handleDetailData}
        setUsersMgmntData={handleUsersMgmntData}
        setReportType={setReportType}
        setReportParams={setReportParams}
        setSchooldId={setSchooldId}
      />
    </Wrapper>
  );
};

export default Reports;
