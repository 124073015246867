import React, { FC, memo } from 'react';

import image from './images/emptyState.svg';
import { EmptyStateMediaStyled, EmptyStateStyled } from './styles';

interface Props {
  description?: string;
  title: string;
}

const EmptyState: FC<Props> = ({ description, title }) => (
  <EmptyStateStyled>
    <EmptyStateMediaStyled>
      <img src={image} alt="" />
    </EmptyStateMediaStyled>
    {title ? <h3>{title}</h3> : null}
    {description ? <p>{description}</p> : null}
  </EmptyStateStyled>
);

export default memo(EmptyState);
