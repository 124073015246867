import React, { InputHTMLAttributes } from 'react';

import { SwitchTrackStyled, SwitchInputStyled, SwitchStyled } from 'components/Switch/styles';

interface InputProps {
  name: string;
  checked: boolean;
  isDisabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ToggleSwitch: React.FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
  name,
  checked,
  isDisabled,
  onChange,
  ...props
}) => (
  <SwitchStyled>
    <SwitchInputStyled
      type="checkbox"
      name={name}
      checked={checked}
      disabled={isDisabled}
      onChange={onChange}
      {...props}
    />
    <SwitchTrackStyled />
  </SwitchStyled>
);

export default ToggleSwitch;
