import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import queryString from 'query-string';

import { ActionTypes } from 'App/types';
import { useApp } from 'App';
import { getApiUrl, api, ResponseError } from 'utils/api';
import usePrefix from 'utils/usePrefix';
import {
  passwordMinLength,
  upperRegex,
  lowerRegex,
  numberRegex,
  specialCharRegex,
} from 'utils/validation';
import { notificationTypes } from 'utils/constants';

import { AccessPageCardStyled, AccessPageStyled } from 'components/AccessPage/styles';
import AccessPageHeader from 'components/AccessPage/AccessPageHeader';

import Form from './Form';
import SavedPasswordPrompt from './SavedPasswordPrompt';
import InvalidTokenPrompt from './InvalidTokenPrompt';

const url = getApiUrl(`/users/password-token`);

const validationSchema = (ty: (key: string, val?: object) => string) =>
  Yup.object().shape({
    password: Yup.string()
      .min(passwordMinLength)
      .matches(upperRegex)
      .matches(lowerRegex)
      .matches(numberRegex)
      .matches(specialCharRegex)
      .required(ty('field_required')),
    password_verify: Yup.string()
      .oneOf([Yup.ref('password')], ty('passwords_not_match'))
      .required(ty('field_required')),
  });

export interface FormValues {
  password: string;
  password_verify: string;
}

const ResetPassword: React.FC = () => {
  const location = useLocation();
  const [{ isLoggedIn }, dispatch] = useApp();
  const [state, setState] = useState<{ success: boolean; tokenIsValid: boolean }>({
    success: false,
    tokenIsValid: false,
  });
  const t = usePrefix('Login');
  const ty = usePrefix('YupErrors');
  const parsedQuery = queryString.parse(location.search);

  const verifyToken = async () => {
    try {
      const response = await api(`${url}/${parsedQuery.token}`, {
        method: 'GET',
      });

      if (response) {
        setState((prevState) => ({ ...prevState, tokenIsValid: true }));
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn || !('token' in parsedQuery && !!parsedQuery.token)) {
    return <Navigate to="/app" />;
  }

  const submit = async (values: FormValues) => {
    try {
      const response = await api(`${url}/${parsedQuery.token}`, {
        method: 'PUT',
        payload: { password: values.password },
      });

      if (response) {
        setState((prevState) => ({ ...prevState, success: true }));
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <AccessPageStyled>
      <AccessPageCardStyled>
        <AccessPageHeader title={t('set_password')} />
        {state.tokenIsValid ? (
          !state.success ? (
            <Formik
              initialValues={{ password: '', password_verify: '' }}
              onSubmit={submit}
              validationSchema={() => validationSchema(ty)}
            >
              {({ errors, touched, isSubmitting, values }) => (
                <Form
                  errors={errors}
                  touched={touched}
                  isSubmitting={isSubmitting}
                  values={values}
                />
              )}
            </Formik>
          ) : (
            <SavedPasswordPrompt />
          )
        ) : (
          <InvalidTokenPrompt />
        )}
      </AccessPageCardStyled>
    </AccessPageStyled>
  );
};

export default ResetPassword;
