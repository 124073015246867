import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';
import { ctaStyles } from 'components/_Redesign/CallToAction/styles';
import { ctaSizes } from 'components/_Redesign/CallToAction/sizes';
import { ctaColors, primaryHoverColors } from 'components/_Redesign/CallToAction/colors';

export const CtaUpload = styled.div`
  ${ctaStyles};
  ${ctaSizes.lg};
  ${ctaColors.primary};
  opacity: 1;
`;

export const Wrapper = styled(FlexDiv)`
  position: relative;
  padding: 24px 0 0;
  input {
    position: absolute;
    top: 0;
    right: 0;
    width: 200%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
    @media (hover: hover) {
      &:hover {
        + ${CtaUpload} {
          ${primaryHoverColors}
        }
      }
    }
  }
`;

export const Upload = styled.div`
  position: relative;
  overflow: hidden;
`;
