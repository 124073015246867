import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Line, Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

import usePrefix from 'utils/usePrefix';
import {
  ReportGeneralResponse,
  ReportDetailResponse,
  UsersMgmntReportResponse,
  ChatReportDetailsResponse,
} from 'utils/api/report';
import { getCssVariableValue } from 'utils/misc';
import { useApp } from 'App';

import EmptyState from 'components/EmptyState';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
);

const chartOptionsDoughnut = ({ text }: { text: string }): ChartOptions<'doughnut'> => ({
  responsive: true,
  aspectRatio: 2,
  plugins: {
    legend: {
      position: 'right',
      labels: {
        boxWidth: 60,
        color: getCssVariableValue('--chart-label-txt'),
        font: {
          size: 16,
        },
      },
    },
    title: {
      display: true,
      text,
    },
  },
  cutout: '75%',
  radius: '90%',
  offset: 0,
});

interface Props {
  dataDetail?: ReportDetailResponse[];
  dataGeneral?: ReportGeneralResponse;
  usersMgmntData?: UsersMgmntReportResponse;
  registerOfNotificationsData?: ChatReportDetailsResponse[];
}

const ChartPanel: React.FC<Props> = ({
  dataDetail,
  dataGeneral,
  usersMgmntData,
  registerOfNotificationsData,
}) => {
  const [reportData, setReportData] = useState<ChartData | undefined>();
  const [report2Data, setReport2Data] = useState<ChartData | undefined>();

  const [{ violenceTypes }] = useApp();

  const t = usePrefix('Reports');
  const tv = usePrefix('Violence');

  useEffect(() => {
    if (!!dataDetail || !!dataGeneral || !!usersMgmntData || !!registerOfNotificationsData) {
      if (dataGeneral) {
        const intervenerViolenceStats = dataGeneral.intervener_violation_stats
          .map((stat) => {
            const vType = violenceTypes.find((violence) => violence.id === stat.violence_id);
            return { label: vType ? tv(vType.key) : t('invalid_key'), occurence: stat.occurrence };
          })
          .filter((stat) => !!stat.occurence);
        setReportData(
          intervenerViolenceStats.length
            ? {
                labels: intervenerViolenceStats.map((stat) => stat.label),
                datasets: [
                  {
                    label: t('intervener_violation_stats'),
                    data: intervenerViolenceStats.map((stat) => stat.occurence),
                    backgroundColor: [
                      getCssVariableValue('--chart-bg--1'),
                      getCssVariableValue('--chart-bg--2'),
                      getCssVariableValue('--chart-bg--3'),
                      getCssVariableValue('--chart-bg--4'),
                      getCssVariableValue('--chart-bg--5'),
                    ],
                  },
                ],
              }
            : undefined,
        );
        const reporterViolenceStats = dataGeneral.reporter_violation_stats
          .map((stat) => {
            const vType = violenceTypes.find((violence) => violence.id === stat.violence_id);
            return { label: vType ? tv(vType.key) : t('invalid_key'), occurence: stat.occurrence };
          })
          .filter((stat) => !!stat.occurence);
        setReport2Data(
          reporterViolenceStats.length
            ? {
                labels: reporterViolenceStats.map((stat) => stat.label),
                datasets: [
                  {
                    label: t('reporter_violation_stats'),
                    data: reporterViolenceStats.map((stat) => stat.occurence),
                    backgroundColor: [
                      getCssVariableValue('--chart-bg--1'),
                      getCssVariableValue('--chart-bg--2'),
                      getCssVariableValue('--chart-bg--3'),
                      getCssVariableValue('--chart-bg--4'),
                      getCssVariableValue('--chart-bg--5'),
                    ],
                  },
                ],
              }
            : undefined,
        );
      } else if (dataDetail) {
        if (!dataDetail.length) {
          setReportData(undefined);
          setReport2Data(undefined);
          return;
        }
        setReportData({
          labels: dataDetail.map((stat) => stat.date),
          datasets: [
            {
              label: t('max_licence_limit'),
              data: dataDetail.map((stat) => stat.max_number_of_reporters),
              backgroundColor: getCssVariableValue('--chart-bg--1'),
              borderColor: getCssVariableValue('--chart-bg--1'),
              fill: 'disabled',
            },
            {
              label: t('licence_use'),
              data: dataDetail.map((stat) => stat.number_of_licensed_reporters),
              backgroundColor: getCssVariableValue('--chart-bg--2'),
              borderColor: getCssVariableValue('--chart-bg--2'),
              fill: 'disabled',
            },
            {
              label: t('anonim_chat_number'),
              data: dataDetail.map((stat) => stat.number_of_anonymous_chats),
              backgroundColor: getCssVariableValue('--chart-bg--3'),
              borderColor: getCssVariableValue('--chart-bg--3'),
              fill: 'disabled',
            },
            {
              label: t('nonanonim_chat_number'),
              data: dataDetail.map((stat) => stat.number_of_regular_chats),
              backgroundColor: getCssVariableValue('--chart-bg--4'),
              borderColor: getCssVariableValue('--chart-bg--4'),
              fill: 'disabled',
            },
          ],
        });
        setReport2Data(undefined);
      } else if (usersMgmntData) {
        setReportData(undefined);
        setReport2Data(undefined);
        return;
      } else if (registerOfNotificationsData) {
        setReportData(undefined);
        setReport2Data(undefined);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetail, dataGeneral]);

  return reportData || report2Data || usersMgmntData || registerOfNotificationsData ? (
    <>
      {dataGeneral ? (
        <>
          {reportData && (
            <Doughnut
              data={reportData as ChartData<'doughnut'>}
              options={chartOptionsDoughnut({ text: t('intervener_violation_stats') })}
            />
          )}
          {report2Data && (
            <Doughnut
              data={report2Data as ChartData<'doughnut'>}
              options={chartOptionsDoughnut({ text: t('reporter_violation_stats') })}
            />
          )}
        </>
      ) : (
        reportData && <Line data={reportData as ChartData<'line'>} />
      )}
    </>
  ) : (
    <EmptyState title={t('no_data')} />
  );
};

export default ChartPanel;
