import styled from 'styled-components';

import { InputProps } from '.';

export const CheckboxInputStyled = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  padding-left: 12px;
  padding-right: 12px;
  background: var(--radio-bg);
`;

export const ValueStyled = styled.span`
  color: var(--radio-txt);
`;

export const Checkmark = styled.span`
  width: 14px;
  height: 14px;
  border: 1px solid var(--radio-check-border);
`;

export const StyledInput = styled.input<InputProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid ${({ error }) => (error ? 'var(--state-error)' : 'transparent')};

  &:hover:not(:disabled) {
    & ~ ${Checkmark} {
      border-color: var(--radio-check-border--hover);
      background: var(--radio-check-bg--hover);
    }
  }

  &:checked {
    & ~ ${Checkmark} {
      border-color: var(--radio-check-border--active);
      background: var(--radio-check-bg--active);
    }
  }

  &:disabled {
    cursor: default;
    &:not(:checked) {
      cursor: default;
      & ~ ${ValueStyled} {
        color: var(--radio-txt--disabled);
      }
      & ~ ${Checkmark} {
        border-color: var(--radio-check-border--disabled);
        background: var(--radio-check-bg--disabled);
      }
    }
  }
`;
