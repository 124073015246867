import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';
import { TableContainerNotificationsStyled, UsersList } from 'components/TableContainer/styles';

export const Wrapper = styled(FlexDiv)`
  grid-area: main;
  height: 100%;
  overflow: hidden;
`;

export const ChartContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  overflow: auto;
  padding: 20px;
`;

export const ReportsTip = styled.div`
  text-align: center;
  font-size: 16px;
  padding-top: 40px;
  color: var(--reports-txt--secondary);
  white-space: pre-line;
`;

export const ChartDetailStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  width: 100%;

  &:empty {
    display: none;
  }

  > img {
    margin-top: 56px;
  }
`;

export const Content = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  margin-top: 20px;
  width: 100%;

  ${UsersList} {
    > div {
      > div {
        margin: 0 auto;
        padding: 0;
        max-width: 880px;
        width: 100%;
      }
    }
  }

  ${TableContainerNotificationsStyled} {
    > div {
      > div {
        margin: 0 auto;
        padding: 0;
        max-width: 900px;
        width: 100%;
      }
    }
  }
`;
