const STATUS_CODE_FROM = 200;
const STATUS_CODE_TO = 300;
export const STATUS_CODE_BAD_REQUEST = 400;
export const STATUS_CODE_UNAUTHORIZED = 401;
export const STATUS_CODE_NOT_PERMITTED = 403;
export const STATUS_CODE_NOT_FOUND = 404;

export const parseJSON = async (response: Body) => {
  const text = await response.text();
  return text ? JSON.parse(text) : {};
};

export const api = async (endpoint: string, options: Options = {}) => {
  const defaults = {
    headers: {
      ...(!options.formData && {
        'Content-Type': 'application/json',
      }),
    },
  };

  const response = await fetch(`${endpoint}`, {
    ...{
      ...options,
      body: options.formData || (options.payload && JSON.stringify(options.payload)),
      credentials: 'include',
    },
    headers: { ...defaults.headers, ...options.headers },
  });

  if (response.status >= STATUS_CODE_FROM && response.status < STATUS_CODE_TO) {
    if (options.responseType === 'blob') return response.blob();
    return parseJSON(response);
  } else {
    const error = new Error(response.statusText) as ResponseError;
    error.response = response;
    error.parsed = await parseJSON(response);
    throw error;
  }
};

const apiUrl = process.env.REACT_APP_API_URL || '/api/backoffice';

export const getApiUrl = (postfix: string) => `${apiUrl}${postfix}`;

export interface Options {
  body?: Body;
  cache?: RequestCache;
  credentials?: RequestCredentials;
  headers?: HeadersInit | Record<string, undefined>;
  integrity?: string;
  keepalive?: boolean;
  method?: string;
  mode?: RequestMode;
  redirect?: RequestRedirect;
  referrer?: string;
  referrerPolicy?: ReferrerPolicy;
  signal?: AbortSignal | null;
  payload?: any;
  responseType?: XMLHttpRequestResponseType;
  formData?: FormData;
}

export interface ResponseErrorBody extends Body {
  code?: string;
}

export interface ResponseError extends Error {
  response: Response;
  parsed: ResponseErrorBody;
}

export interface PaginatedList<T> {
  content: T;
  number: number;
  size: number;
  totalElements: number;
}

export interface SubCat {
  id: number;
  key: string;
  subcategories?: [];
}

export interface Violence {
  id: number;
  key: string;
  subcategories: SubCat[] | null;
}

export type ChatListTypes = 'IMPORTANT' | 'ASSIGNED' | 'ALL' | 'TAGGED';
