import React, { TextareaHTMLAttributes } from 'react';
import { FieldProps, Field } from 'formik';

import { StyledTextArea, Wrapper } from './styles';

export interface InputProps {
  name: string;
  placeholder: string;
  height?: string;
}

const TextArea: React.FC<InputProps & TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
  name,
  placeholder,
  disabled,
  ...props
}) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <Wrapper justifyContent="flex-start" alignItems="flex-start">
        <StyledTextArea
          {...field}
          {...props}
          name={name}
          value={!disabled ? field.value : ''}
          placeholder={placeholder}
          disabled={disabled}
        />
      </Wrapper>
    )}
  </Field>
);

export default TextArea;
