import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';
import { DataRowStyled } from 'components/DataRow/styles';

import { ButtonsContainer } from '../styles';

const PAGE_PADDING = '24px';

export const SubHeader = styled(FlexDiv)`
  padding: 0 0 ${PAGE_PADDING};
`;

export const Subtitle = styled.h3`
  flex: 0 0 168px;
  align-self: center;
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--target-txt);
`;

export const Value = styled.span`
  color: var(--target-value-txt);
`;

export const ListWrapper = styled.div`
  flex: 1;
  margin: 0;
  width: 100%;
`;

export const SelectedList = styled.div`
  margin: 0;
  width: 100%;
  max-height: 480px;
  border: 1px solid var(--target-border--selected);
  overflow-y: auto;
`;

export const SearchList = styled.div<{ height: string }>`
  margin: 0;
  padding: 0;
  width: 100%;
  height: ${({ height }) => height};
  max-height: 480px;
  border: 1px solid var(--target-border);
  overflow-y: auto;
  list-style: none;
  ul {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  > div {
    > div {
      padding: 0;
      scrollbar-width: thin;
      overflow-y: auto;
    }
  }
  ${DataRowStyled} {
    border-width: 0;
    &:not(:last-child) {
      border-bottom-width: 1px;
    }
  }
`;

export const ListTitle = styled.h4`
  margin: 16px 0 8px;
`;

export const ToolbarBottomStyled = styled.div`
  position: sticky;
  bottom: 0;
  transform: translateY(24px);
  padding-block: 24px;
  width: 100%;
  border-top: 1px solid var(--modal-line);
  background: var(--modal-bg);
  z-index: 10;

  ${ButtonsContainer} {
    > button {
      margin-left: 0;
    }
  }
`;

export const ToggleIconStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  path {
    fill: inherit;
  }
`;
