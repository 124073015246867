import React, { InputHTMLAttributes } from 'react';
import { FieldProps, Field } from 'formik';

import { Wrapper, Checkmark, StyledInput, ValueStyled } from './styles';

export interface InputProps {
  name: string;
  value: string;
  placeholder?: string;
  error?: boolean;
  label?: string;
}

const RadioInput: React.FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
  name,
  value,
  placeholder,
  label,
  error = false,
  ...props
}) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <Wrapper>
        <StyledInput
          {...field}
          type="radio"
          name={name}
          value={value}
          error={error}
          placeholder={placeholder}
          checked={field.value === value}
          {...props}
        />
        <ValueStyled>{label}</ValueStyled>
        <Checkmark />
      </Wrapper>
    )}
  </Field>
);

export default RadioInput;
