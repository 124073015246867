import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';

export const NotesScreenStyled = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  height: 100dvh;
`;

export const NoteSectionStyled = styled.div`
  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
  grid-template-columns: 1fr;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 48px;
  }
`;

// TODO - do poprawy przy redesign
export const TextArea = styled.textarea`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 11px 12px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--form-field-border);
  background: var(--form-field-bg);
  color: var(--form-field-txt);
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  user-select: none;
  vertical-align: middle;
  box-shadow: 0 6px 20px 0 var(--form-field-shadow);
  appearance: none;
  font-size: 16px;
  line-height: 20px;
  resize: none;
`;

export const TakenActionStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  width: fit-content;
`;

export const SingleNoteStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 11px 12px;
  width: 100%;
  border: 1px solid var(--form-field-border);
  border-radius: 8px;
  box-shadow: 0 6px 20px 0 var(--form-field-shadow);
`;

export const SingleNoteContentStyled = styled.div`
  width: 100%;
  min-width: 0;
  font-size: 16px;
  line-height: 20px;
  color: var(--form-field-txt);
  white-space: pre-line;
`;

export const SingleNoteDateStyled = styled.div`
  width: 100%;
  min-width: 0;
  font-size: 12px;
  line-height: 15px;
  color: var(--notice-txt--secondary);
`;

export const SingleNoteSwitchStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 16px;
`;

export const SingleNoteSwitchLabelStyled = styled.span`
  text-align: right;
`;
