import { assertUnreachable } from 'utils/misc';
import { ResponseError } from 'utils/api';

export const reducer =
  <T>() =>
  (state: FetchState<T>, action: FetchAction<T>): FetchState<T> => {
    switch (action.type) {
      case 'FETCH_INIT':
        return {
          ...state,
          isLoading: true,
          error: undefined,
        };
      case 'FETCH_SUCCESS':
        return {
          ...state,
          isLoading: false,
          loaded: true,
          error: undefined,
          data: action.payload,
        };
      case 'FETCH_FAILURE':
        return {
          ...state,
          isLoading: false,
          loaded: false,
          error: action.payload,
        };
      default:
        return assertUnreachable(action);
    }
  };

interface FetchState<T> {
  data: T | undefined;
  isLoading: boolean;
  loaded: boolean;
  error: ResponseError | undefined;
}

export type FetchAction<T> =
  | {
      type: 'FETCH_INIT';
    }
  | { type: 'FETCH_SUCCESS'; payload: T }
  | { type: 'FETCH_FAILURE'; payload: ResponseError };
