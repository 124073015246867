import styled, { css } from 'styled-components';

import { screenXlgAbove } from 'styles/breakpoinst';
import FlexDiv from 'components/FlexDiv';

import { ICON_HEIGHT } from './MetaSection';
import { CHAT_META_WIDTH, CHAT_META_WIDTH_MD } from '../styles';

export const Wrapper = styled.div`
  flex: 0 0 ${CHAT_META_WIDTH};
  padding: 16px 24px;
  height: 100%;
  overflow-y: auto;
`;

const sectionAbsoluteStyles = css`
  position: absolute;
  top: 0;
  right: ${CHAT_META_WIDTH_MD};
  margin-bottom: 0;
  border-right: 1px solid var(--chat-meta-border);
  border-left: 1px solid var(--chat-meta-border);
  width: 330px;
  height: 100%;
  background: var(--chat-meta-bg);

  @media screen and (min-width: ${screenXlgAbove}) {
    right: ${CHAT_META_WIDTH};
  }
`;

export const SectionWrapper = styled(FlexDiv)<{ absolute?: boolean }>`
  width: 100%;
  margin-bottom: 15px;
  ${({ absolute }) => absolute && sectionAbsoluteStyles};
  svg {
    flex: 0 0 ${ICON_HEIGHT};
  }
`;

export const Title = styled(FlexDiv)`
  margin-bottom: 15px;
  padding-right: 15px;
  min-height: ${ICON_HEIGHT};
  font-weight: 600;
  font-size: 22px;
  color: var(--chat-meta-txt);
`;

const isContentAbsoluteStyles = css`
  padding: 16px 24px;
  overflow-y: auto;
`;

export const Content = styled.div<{ absolute?: boolean }>`
  flex: 1;
  min-width: 0;
  ${({ absolute }) => absolute && isContentAbsoluteStyles};
`;

export const DetailTitle = styled.div`
  color: var(--chat-meta-txt);
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;

const detailTransparentStyles = css`
  background-color: var(--chat-meta-toolbar-bg--transparent);
  border: 1px solid var(--chat-meta-toolbar-border--transparent);
  color: var(--chat-meta-toolbar-txt--transparent);
`;

export const Detail = styled.div<{ transparent?: boolean }>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  padding: 15px;
  width: 100%;
  border-radius: 4px;
  background: var(--chat-meta-toolbar-bg);
  font-size: 16px;
  line-height: 20px;
  color: var(--chat-meta-toolbar-txt);
  ${({ transparent }) => (transparent ? detailTransparentStyles : '')};
`;

export const Label = styled.div`
  flex: 1;
  padding-right: 8px;
`;

export const ViolenceTypesList = styled.ul`
  margin: 8px 0 0;
  padding: 0;
  width: 100%;
  min-width: 0;
  list-style: none;
`;

export const TypeItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
  min-width: 0;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid var(--chat-meta-item-border);
  background: var(--chat-meta-item-bg);
  color: var(--chat-meta-item-txt);

  & > span {
    margin-right: 14px;
  }
`;

export const SwitchContainer = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 20px;
`;

export const PlusContainer = styled.div``;

export const ActionsTakenDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 15px;
  padding: 15px;
  width: 100%;
  border-radius: 4px;
  background: var(--chat-meta-toolbar-bg);
  font-size: 16px;
  line-height: 20px;
  color: var(--chat-meta-toolbar-txt);
`;

export const ActionsTakenDetailsSwitch = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ActionsTakenDetailsAuthor = styled.div`
  color: var(--chat-meta-txt);
  font-size: 14px;
  line-height: 21px;
`;
