/* eslint-disable no-magic-numbers */
import styled from 'styled-components';

import { calcSize } from 'styles/calcSize';

export const LoadingStateStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;
  padding: 32px;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background: var(--app-bg);
  color: var(--app-txt);

  h1 {
    font-weight: 700;
    font-size: ${calcSize(22)};
    line-height: 1.5;
  }
`;

export const LoadingStateImageStyled = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  width: 100%;
  max-width: 240px;
  max-height: 200px;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
