import React, { FC, memo } from 'react';

import LoaderBouncing from 'components/LoaderBouncing';
import usePrefix from 'utils/usePrefix';

import { LoadingStateImageStyled, LoadingStateStyled } from './styles';
import { IMAGE } from './image';

const LoadingState: FC = () => {
  const t = usePrefix('General');

  return (
    <LoadingStateStyled>
      <LoadingStateImageStyled>
        <img src={IMAGE} alt="" />
      </LoadingStateImageStyled>
      <LoaderBouncing />
      <h1>{t('please_wait_fetching_title')}</h1>
    </LoadingStateStyled>
  );
};

export default memo(LoadingState);
