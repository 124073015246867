import styled from 'styled-components';

export const AssignmentsPageStyled = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-area: main;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  height: 100dvh;
`;

export const AssignmentsHeaderStyled = styled.div`
  padding: 24px;
`;

export const AssignmentsContentStyled = styled.div`
  padding: 0 24px 24px;
  height: 100%;
  overflow-y: auto;
`;

export const CompanyContainer = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid var(--data-item-border);
  border-radius: 5px;
`;

export const CompanyTitleStyled = styled.h3`
  margin: 0;
  padding-bottom: 5px;
  font-size: 18px;
`;
