import styled, { css } from 'styled-components';

import { ANIMATION } from 'styles/variables';

import Icon from 'components/Icons';

export const ButtonStyles = css`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  min-width: 0;
  height: 50px;
  border: 0;
  border-radius: 4px;
  background: var(--context-menu-btn-bg);
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: var(--context-menu-btn-txt);
  text-align: left;
  cursor: pointer;
  transition: background ${ANIMATION};
  outline: none;
  overflow: hidden;
  svg {
    flex: 0 0 16px;
  }
`;

export const Wrapper = styled.ul`
  margin: 16px 0;
  padding: 0;
  width: 100%;
  list-style: none;
`;

export const GroupList = styled.ul`
  position: relative;
  margin: 0;
  padding: 0 0 0 24px;
  list-style: none;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 16px;
    left: 12px;
    border-left: 1px dashed var(--context-menu-border);
  }
`;

export const GroupItem = styled.li`
  position: relative;
  margin-bottom: 8px;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -12px;
    width: 8px;
    border-top: 1px dashed var(--context-menu-border);
    transform: translateY(-50%);
  }
`;

const buttonSelectActiveStyles = css`
  background: var(--context-menu-btn-bg--active);
  color: var(--context-menu-btn-txt--active);
  cursor: default;
`;

const buttonSelectHoverStyles = css`
  @media (hover: hover) {
    &:hover {
      background: var(--context-menu-btn-bg--hover);
      color: var(--context-menu-btn-txt--hover);
    }
  }
`;

export const ButtonSelect = styled.button<{ selected: boolean; marginBot?: string }>`
  ${ButtonStyles};
  ${({ marginBot }) =>
    marginBot &&
    css`
      margin-bottom: ${marginBot};
    `}

  ${({ selected }) => (selected ? buttonSelectActiveStyles : '')};
  ${({ selected }) => (!selected ? buttonSelectHoverStyles : '')};
`;

const buttonSelectIconSelectedStyles = css`
  transform: rotate(180deg);
`;

export const ButtonSelectIcon = styled(Icon)<{ isSelected?: boolean }>`
  justify-self: flex-end;
  margin-left: auto;
  color: var(--app-txt--primary);
  ${({ isSelected }) => (isSelected ? buttonSelectIconSelectedStyles : '')}
`;

export const Label = styled.span`
  display: inline-flex;
  justify-content: flex-start;
  padding-right: 8px;
  min-width: 0;
  width: calc(100% - 16px);
  > span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
