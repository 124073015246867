import i18n from 'i18next';

import { Violence } from 'utils/api';

export const assertUnreachable = (x: never): never => {
  throw new Error(`${x} ${i18n.t('common:unreachable')}`);
};

export const findViolenceTypeKey = (violenceTypes: Violence[], typeId: number | null): string => {
  const typeKey = violenceTypes.find((type) => type.id === typeId)?.key;

  if (!typeKey) return 'UNDEFINED';
  return typeKey;
};

export const getCssVariableValue = (variableName: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
