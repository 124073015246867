import styled, { css } from 'styled-components';

export const LoaderBouncingDotsStyled = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  width: 96px;
  height: 96px;
`;

export const loaderDotStyles = css`
  display: block;
  margin: 0 2px;
  border-radius: 50%;
  opacity: 1;
  animation-duration: 600ms;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  &:nth-child(2) {
    animation-delay: 200ms;
  }
  &:nth-child(3) {
    animation-delay: 400ms;
  }
`;

export const BouncingDotStyled = styled('div')`
  ${loaderDotStyles};
  width: 16px;
  height: 16px;
  background: var(--loader-dot-bg);
  transform: translate3d(0, 8px, 0);
  animation-name: bouncing-loader-lg;
  @keyframes bouncing-loader-lg {
    to {
      opacity: 0.5;
      transform: translate3d(0, -8px, 0);
    }
  }
`;
