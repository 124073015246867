import React, { memo } from 'react';

import { IconName } from 'components/Icons';

import { NavigationItemStyled, NavItem, StyledIcon } from './styles';

interface Props {
  icon: IconName;
  to: string;
  label?: string;
}

const NavigationItem: React.FC<Props> = ({ to, icon, label }) => (
  <NavigationItemStyled>
    <NavItem to={to} title={label}>
      <StyledIcon type={icon} />
      <span>{label}</span>
    </NavItem>
  </NavigationItemStyled>
);

export default memo(NavigationItem);
