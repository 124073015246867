import React from 'react';

import { PageTitleStyled } from './styles';

interface Props {
  title: string;
  clickable?: boolean;
  paddingBottom?: string;
}

const PageTitle: React.FC<Props> = ({ title, ...props }) => (
  <PageTitleStyled {...props}>{title}</PageTitleStyled>
);

export default PageTitle;
