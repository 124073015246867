import React, { FC, memo } from 'react';

import { BouncingDotStyled, LoaderBouncingDotsStyled } from './styles';

const LoaderBouncingDots: FC = () => (
  <LoaderBouncingDotsStyled>
    <BouncingDotStyled />
    <BouncingDotStyled />
    <BouncingDotStyled />
  </LoaderBouncingDotsStyled>
);

export default memo(LoaderBouncingDots);
