import styled from 'styled-components';

export const AccessPageStyled = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background: var(--access-page-bg);
  overflow: auto;
`;

export const AccessPageCardStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  justify-content: center;
  align-items: flex-start;
  margin-inline: auto;
  padding: 60px;
  border-radius: 12px;
  width: 100%;
  min-width: 0;
  max-width: 576px;
  background: var(--access-page-card-bg);
  box-shadow: 0 6px 20px 0 var(--access-page-card-shadow);
`;
