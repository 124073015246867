import React, { useState } from 'react';

import { Violence } from 'utils/api';
import { ViolenceCategoryResponse } from 'utils/api/notice';

import { Wrapper } from './styles';
import Category from './Category';

interface Props {
  onSelect: (id: number) => void;
  violenceTypes: Violence[];
  selectedId?: ViolenceCategoryResponse | number | null | undefined;
}

const Dropdown: React.FC<Props> = ({ onSelect, violenceTypes, selectedId }) => {
  const [categoryId, setCategoryId] = useState<number | null>(null);

  const handleSetCategoryId = (id: number | null) => setCategoryId(id);

  return (
    <Wrapper>
      {violenceTypes.map((type) => (
        <li key={type.id}>
          <Category
            type={type}
            onSelect={onSelect}
            selectedId={selectedId}
            setCategoryId={handleSetCategoryId}
            categoryId={categoryId}
          />
        </li>
      ))}
    </Wrapper>
  );
};
export default Dropdown;
