import React from 'react';

import usePrefix from 'utils/usePrefix';

import { Wrapper, Upload, CtaUpload } from './styles';

interface Props {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileUpload: React.FC<Props> = ({ onChange }) => {
  const t = usePrefix('Notices');

  return (
    <Wrapper justifyContent="flex-end" alignItems="flex-start">
      <Upload>
        <input type="file" onChange={onChange} />
        <CtaUpload>{t('cta_add_file')}</CtaUpload>
      </Upload>
    </Wrapper>
  );
};

export default FileUpload;
