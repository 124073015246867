import React, { FC, memo, ReactNode } from 'react';

import { IconName } from 'components/Icons';
import { CtaIcon, CtaLabelStyled, CtaStyledProps } from 'components/_Redesign/CallToAction/styles';
import { CtaColor, CtaShape, CtaSize, CtaVariant } from 'components/_Redesign/CallToAction/types';
import { setIconSize } from 'components/_Redesign/CallToAction/setIconSize';

import { ButtonHrefStyled } from './styles';

export interface ButtonHrefProps extends CtaStyledProps {
  children?: ReactNode;
  className?: string;
  color?: CtaColor;
  download?: boolean;
  icon?: IconName;
  id?: string;
  label?: string;
  role?: string;
  size?: CtaSize;
  shape?: CtaShape;
  tabIndex?: number;
  title?: string;
  href?: string;
  variant?: CtaVariant;
}

const ButtonHref: FC<ButtonHrefProps> = ({
  children,
  className,
  color,
  download,
  href,
  icon,
  id,
  label,
  role,
  shape,
  size,
  tabIndex,
  title,
  variant,
}) => (
  <ButtonHrefStyled
    $color={color}
    $shape={shape}
    $size={size}
    $variant={variant}
    className={className}
    download={download}
    href={href}
    id={id}
    role={role}
    tabIndex={tabIndex}
    title={title}
  >
    {!!icon && <CtaIcon type={icon} width={setIconSize(size)} height={setIconSize(size)} />}
    {!!label && <CtaLabelStyled>{label}</CtaLabelStyled>}
    {children}
  </ButtonHrefStyled>
);

export default memo(ButtonHref);
