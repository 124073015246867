import React, { FC, memo } from 'react';

import usePrefix from 'utils/usePrefix';
import LoaderBouncing from 'components/LoaderBouncing';

import { FetchingMessageStyled } from './styles';

interface Props {
  title?: string;
}

const FetchingMessage: FC<Props> = ({ title }) => {
  const t = usePrefix('Reports');

  return (
    <FetchingMessageStyled>
      <LoaderBouncing />
      <h3>{title ? title : t('fetching_report')}</h3>
    </FetchingMessageStyled>
  );
};

export default memo(FetchingMessage);
