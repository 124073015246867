import { css } from 'styled-components';

import { ModalDialogStyledProps } from './styles';
import { ModalContent } from './types';

export const autoContent = css``;

export const fullContent = css`
  height: 100%;
`;

export const modalContents: Record<ModalContent, any> = {
  auto: autoContent,
  full: fullContent,
};

export const modalContentsFunc = ({
  $content,
}: {
  $content?: ModalDialogStyledProps['$content'];
}) => ($content ? modalContents[$content] : modalContents.auto);
