import styled, { css } from 'styled-components';
import { Form } from 'formik';

import InputField from 'components/InputField';
import FormField from 'components/FormField';
import FlexDiv from 'components/FlexDiv';

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;

export const Content = styled(Form)`
  ${contentStyles}
`;

export const ButtonsContainer = styled(FlexDiv)`
  > button {
    margin-left: 20px;
  }
`;

const INPUT_GROUP_WIDTH = '372px';

export const StyledFormField = styled(FormField)`
  margin-bottom: 24px;
  width: 100%;
  max-width: ${INPUT_GROUP_WIDTH};
`;

export const StyledInputField = styled(InputField)`
  margin-bottom: 24px;
  width: 100%;
  max-width: ${INPUT_GROUP_WIDTH};
`;

export const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  width: 100%;
  max-width: 880px;
`;

export const CategoriesStyled = styled.aside`
  flex: 0 0 320px;
  margin-top: 24px;
  padding-left: 24px;
  min-width: 0;
`;

export const Title = styled.h3`
  margin: 0 0 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--notice-txt--primary);
`;

const editorWrapperStyles = css`
  .tox {
    border-color: var(--state-error);
  }
`;

export const EditorWrapper = styled.div<{ error: boolean }>`
  width: 100%;
  ${({ error }) => (error ? editorWrapperStyles : '')};
`;

export const FieldTitle = styled(FlexDiv)`
  margin-bottom: 4px;
  font-size: 11px;
`;

export const FormRowStyled = styled(FlexDiv)`
  gap: 24px;
`;

export const Label = styled.label`
  margin-left: 12px;
`;

export const EditorMessage = styled.div`
  color: var(--state-error);
  &:before {
    content: '\\00a0';
  }
`;
