import React from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import dayjs from 'dayjs';

import { getApiUrl } from 'utils/api';
import { useApi } from 'utils/api/useApi';
import { GetReportUsersMgmntResponse } from 'utils/api/report';
import usePrefix from 'utils/usePrefix';
import { userRoles } from 'utils/userRoles';

import { useApp } from 'App';
import FlexDiv from 'components/FlexDiv';
import Row from 'components/Row';
import { UsersList, UsersWrapper } from 'components/TableContainer/styles';
import Loader from 'components/Loader';
import EmptyState from 'components/EmptyState';

import { Content } from '../styles';
import { LocationSpecificTargetType } from '../Criteria';

interface Props {
  reportParams: string;
  schoolId?: LocationSpecificTargetType;
}

const Users: React.FC<Props> = ({ reportParams, schoolId }) => {
  const t = usePrefix('Reports');

  const [{ profile }] = useApp();

  const isDirector = profile?.role === userRoles.director;

  const urlAdmin = getApiUrl('/reports/users_mgmnt');
  const urlDirector = getApiUrl(`/director/me/reports/${schoolId}/users_mgmnt`);

  const url = isDirector ? urlDirector : urlAdmin;

  const RECORD_COUNT = 9999999;

  const { data, isLoading } = useApi<GetReportUsersMgmntResponse[]>(
    `${url}${reportParams}&page=0&size=${RECORD_COUNT}`,
    {
      method: 'GET',
    },
  );

  const getItemSize = (index: number, data: any[]): number => {
    const item = data[index];

    if (item.action_type === 'CHANGE_USER_PERMISSIONS') {
      // eslint-disable-next-line no-magic-numbers
      const textLength = item?.permission_description?.length || 20;
      // eslint-disable-next-line no-magic-numbers
      const lines = Math.ceil(textLength / 50);
      // eslint-disable-next-line no-magic-numbers
      return 48 + lines * 20;
    }
    // eslint-disable-next-line no-magic-numbers
    return 48;
  };

  return (
    <FlexDiv
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="flex-start"
      flexDirection="column"
      flex="1"
      width="100%"
    >
      <Content>
        <UsersWrapper>
          {isLoading && <Loader label={t('fetching_users')} />}
          {!isLoading && !data?.length && <EmptyState title={t('no_users')} />}
          {!!data?.length && (
            <UsersList>
              <AutoSizer disableWidth>
                {({ height }: { height: number }) => (
                  <List
                    height={height}
                    width="100%"
                    itemCount={data.length}
                    itemSize={(index) => getItemSize(index, data)}
                    innerElementType="ul"
                  >
                    {({ index, ...props }) => (
                      <Row
                        title={`${data[index].action_on_user_first_name} ${data[index].action_on_user_last_name}`}
                        labels={[
                          t(`${data[index].action_type}`),
                          `${dayjs(new Date(data[index].action_date_time)).format(
                            'YYYY-MM-DD',
                          )}, ${dayjs(new Date(data[index].action_date_time)).format('HH:mm')}`,
                          data[index].action_type === 'CHANGE_USER_PERMISSIONS' &&
                          data[index]?.permission_description
                            ? `${data[index]?.permission_description}`
                            : `${data[index].action_on_user_first_name} ${data[index].action_on_user_last_name}`,
                        ]}
                        alignItems={'normal'}
                        whiteSpace={'pre-line'}
                        truncateText={false}
                        {...props}
                      />
                    )}
                  </List>
                )}
              </AutoSizer>
            </UsersList>
          )}
        </UsersWrapper>
      </Content>
    </FlexDiv>
  );
};

export default Users;
