import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';

export const LoaderStyled = styled(FlexDiv)`
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 24px;
  background: var(--loader-msg-bg);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--loader-msg-txt);
  box-shadow: 0 2px 4px 0 var(--app-shadow);
  z-index: 2050;
`;
