import styled from 'styled-components';

export const DotStyled = styled.div`
  display: inline-flex;
  flex: 0 0 8px;
  margin-top: 6px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: var(--list-item-dot-bg);
`;
