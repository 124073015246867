import React, { FC, memo } from 'react';

import usePrefix from 'utils/usePrefix';
import { SET_PASSWORD_URL } from 'App/constants';
import ButtonLink from 'components/_Redesign/ButtonLink';

const ForgotPasswordButton: FC = () => {
  const t = usePrefix('Login');

  return (
    <ButtonLink
      color="text-primary"
      label={t('forgotPassword')}
      size="md"
      url={`/app/${SET_PASSWORD_URL}`}
      variant="text"
    />
  );
};

export default memo(ForgotPasswordButton);
