import { createGlobalStyle } from 'styled-components';

import { ANIMATION, FONT_PRIMARY } from './variables';
import { resetStyles } from './reset';
import { appColors } from './rootColors';
import { LAYER_MODAL, LAYER_SIDE_PANEL } from './layers';

const GlobalStyle = createGlobalStyle`
  ${resetStyles};
  ${appColors};
  
  html, #root {
    width: 100%;
    height: 100%;
    background: var(--app-bg);
    color: var(--app-txt--primary);
    -webkit-scrollbar-width: thin;
    scrollbar-width: thin;
  }

  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: ${FONT_PRIMARY}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  
  .tox {
    width: 100%;
  }

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 8px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: var(--scrollbar-track-bg);
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--scrollbar-thumb-bg);
      transition: background ${ANIMATION};
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: var(--scrollbar-thumb-bg--hover);
    }
  }
  
  .app-modals {
    position: relative;
    z-index: ${LAYER_MODAL};
  }
  
  .app-side-panels {
    position: relative;
    z-index: ${LAYER_SIDE_PANEL};
  }
`;

export default GlobalStyle;
