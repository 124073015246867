import { css } from 'styled-components';

import { ActionsAlignment } from './types';
import { ActionsStyledProps } from './styles';

export const alignLeft = css`
  justify-content: flex-start;
`;

export const alignCenter = css`
  justify-content: center;
`;

export const alignRight = css`
  justify-content: flex-end;
`;

export const actionsAlign: Record<ActionsAlignment, any> = {
  left: alignLeft,
  center: alignCenter,
  right: alignRight,
};

export const actionsAlignmentFunc = ({ $align }: { $align?: ActionsStyledProps['$align'] }) =>
  $align ? actionsAlign[$align] : actionsAlign.right;
