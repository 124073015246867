import React, { memo, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Navigate } from 'react-router-dom';

import { api, getApiUrl, ResponseError } from 'utils/api';
import { useApp } from 'App';
import usePrefix from 'utils/usePrefix';
import { ActionTypes } from 'App/types';
import { notificationTypes } from 'utils/constants';
import AccessPageHeader from 'components/AccessPage/AccessPageHeader';
import { AccessPageCardStyled, AccessPageStyled } from 'components/AccessPage/styles';
import {
  AccessPageFormActionsItemStyled,
  AccessPageFormActionsStyled,
} from 'components/AccessPage/AccessPageForm/styles';

import Form from './Form';
import BackToLoginButton from './BackToLoginButton';
import RetrieveLinkSentPrompt from './RetrieveLinkSentPrompt';

const url = getApiUrl('/users/password-token');

const validationSchema = (ty: (key: string, vars?: object) => string) =>
  Yup.object().shape({
    email: Yup.string().email(ty('invalid_email')).required(ty('field_required')),
  });

export interface FormValues {
  email: string;
}

const RetrievePassword: React.FC = () => {
  const [{ isLoggedIn }, dispatch] = useApp();
  const [sent, setSent] = useState(false);
  const t = usePrefix('Login');
  const ty = usePrefix('YupErrors');

  const submit = async (values: FormValues) => {
    try {
      const response = await api(url, {
        method: 'POST',
        payload: { ...values },
      });

      if (response) {
        setSent(true);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/app" />;
  }

  return (
    <AccessPageStyled>
      <AccessPageCardStyled>
        <AccessPageHeader title={t('retrieve_password')} />
        {!sent ? (
          <Formik
            initialValues={{ email: '' }}
            onSubmit={submit}
            validationSchema={() => validationSchema(ty)}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form errors={errors} touched={touched} isSubmitting={isSubmitting} />
            )}
          </Formik>
        ) : (
          <RetrieveLinkSentPrompt />
        )}
        <AccessPageFormActionsStyled>
          <AccessPageFormActionsItemStyled>
            <BackToLoginButton />
          </AccessPageFormActionsItemStyled>
        </AccessPageFormActionsStyled>
      </AccessPageCardStyled>
    </AccessPageStyled>
  );
};

export default memo(RetrievePassword);
