/* eslint-disable no-magic-numbers */
import React, { memo, useMemo, useState } from 'react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import usePrefix from 'utils/usePrefix';
import { useApi, getApiUrl } from 'utils/api/useApi';
import { PaginatedList, api, ResponseError } from 'utils/api';
import { SchoolResponse, VoivodeshipResponse, CityResponse } from 'utils/api/schools';
import { NoticeListResponse } from 'utils/api/notice';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { notificationTypes } from 'utils/constants';

import Row from 'components/Row';
import EmptyState from 'components/EmptyState';

import { FormValues } from '..';
import List from './List';
import { ListWrapper, SearchList, SelectedList, ListTitle } from './styles';
import ToolbarBottom from './ToolbarBottom';
import ToggleIcon from './ToggleIcon';

const url = getApiUrl('/schools');
const urlSendNotice = getApiUrl('/notices');

const RECORD_COUNT = 9999999;

interface Props {
  id?: number;
  noticeDetails: FormValues;
  noticeId: number;
  onClose: () => void;
  fetchData: () => Promise<PaginatedList<NoticeListResponse[]> | void>;
  isNoticeChanged: boolean;
}

interface TargetTypes {
  voivodeships: string[];
  localities: string[];
  schools: SchoolResponse[];
  removedSchools: SchoolResponse[];
}

const Target: React.FC<Props> = ({
  noticeDetails,
  noticeId,
  onClose,
  fetchData,
  isNoticeChanged,
}) => {
  const [, dispatch] = useApp();

  const t = usePrefix('Notices');
  const [nameFilter, setNameFilter] = useState({ school: '', city: '' });
  const [targets, setTargets] = useState<TargetTypes>({
    voivodeships: [],
    localities: [],
    schools: [],
    removedSchools: [],
  });

  const MIN_QUERY_LENGTH = 3;

  const { data: dataVoivodeships } = useApi<VoivodeshipResponse[]>(`${url}/voivodeshipList`, {
    method: 'GET',
  });

  const { data: dataSchools } = useApi<PaginatedList<SchoolResponse[]>>(
    nameFilter.school.length < MIN_QUERY_LENGTH
      ? `${url}?page=0&size=${RECORD_COUNT}`
      : `${url}?name=${nameFilter.school}&page=0&size=${RECORD_COUNT}`,
    {
      method: 'GET',
    },
  );

  const [sentToSchools, availableSchools] = useMemo(() => {
    if (Array.isArray(dataSchools?.content)) {
      const sentToSchools: SchoolResponse[] = [];
      const availableSchools: SchoolResponse[] = [];

      dataSchools!.content.forEach((school) => {
        if (
          (noticeDetails?.sent_to_schools?.findIndex(
            (id: number | SchoolResponse) => id === school.id,
          ) ?? -1) >= 0
        ) {
          sentToSchools.push(school);
        } else if (school.valid_licence === true) {
          availableSchools.push(school);
        }
      });

      return [sentToSchools, availableSchools];
    }

    return [[], []];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSchools, noticeDetails.sent_to_schools]);

  const { data: dataLocalities } = useApi<PaginatedList<CityResponse[]>>(
    nameFilter.city.length < MIN_QUERY_LENGTH
      ? `${url}/cities?page=0&size=${RECORD_COUNT}`
      : `${url}/cities?pattern=${nameFilter.city}&page=0&size=${RECORD_COUNT}`,
    {
      method: 'GET',
    },
  );

  const onSelectSchool = (school: SchoolResponse) => {
    const indexToRemove = targets.schools.findIndex(
      (targetSchool) => targetSchool.id === school.id,
    );
    const newTarget = [...targets.schools];
    if (indexToRemove > -1) newTarget.splice(indexToRemove, 1);

    setTargets((prevState) => ({
      ...prevState,
      schools: indexToRemove > -1 ? [...newTarget] : [...prevState.schools, school],
    }));
  };

  const onSelectCity = (city: string) => {
    const indexToRemove = targets.localities.indexOf(city);
    const newTarget = [...targets.localities];
    if (indexToRemove > -1) newTarget.splice(indexToRemove, 1);

    setTargets((prevState) => ({
      ...prevState,
      localities: indexToRemove > -1 ? [...newTarget] : [...prevState.localities, city],
    }));
  };

  const onSelectVoivodeship = (voivodeship: string) => {
    const indexToRemove = targets.voivodeships.indexOf(voivodeship);
    const newTarget = [...targets.voivodeships];
    if (indexToRemove > -1) newTarget.splice(indexToRemove, 1);

    setTargets((prevState) => ({
      ...prevState,
      voivodeships: indexToRemove > -1 ? [...newTarget] : [...prevState.voivodeships, voivodeship],
    }));
  };

  const onRemoveSchool = (school: SchoolResponse) => {
    const indexToRemove = targets.removedSchools.findIndex(
      (targetSchool) => targetSchool.id === school.id,
    );
    const newTarget = [...targets.removedSchools];
    if (indexToRemove > -1) newTarget.splice(indexToRemove, 1);

    setTargets((prevState) => ({
      ...prevState,
      removedSchools: indexToRemove > -1 ? [...newTarget] : [...prevState.removedSchools, school],
    }));
  };

  const onChangeFilter = (filter: object) => {
    setNameFilter((prevState) => ({ ...prevState, ...filter }));
  };

  const submitData = async () => {
    if (targets.removedSchools.length > 0) {
      unsentRemovedData();
    }

    if ([...targets.schools, ...targets.voivodeships, ...targets.localities].length > 0) {
      sendData();
    }
  };

  const sendData = async () => {
    try {
      const response = await api(`${urlSendNotice}/${noticeId}/send`, {
        method: 'POST',
        payload: {
          voivodeships: targets.voivodeships,
          localities: targets.localities,
          schoolIds: targets.schools.map((school) => school.id),
        },
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { code: 'NOTICE_SENT', type: notificationTypes.success },
        });
        fetchData();
        onClose();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const unsentRemovedData = async () => {
    try {
      const response = await api(`${urlSendNotice}/${noticeId}/unsent`, {
        method: 'POST',
        payload: {
          voivodeships: [],
          localities: [],
          schoolIds: targets.removedSchools.map((school) => school.id),
        },
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { code: 'NOTICE_SENT', type: notificationTypes.success },
        });
        fetchData();
        onClose();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const isVoivodeshipsSelected = targets.voivodeships.length > 0;
  const isLocalitiesSelected = targets.localities.length > 0;
  const isSchoolRemoved = targets.schools.length > 0;
  const isSchoolSelected = targets.removedSchools.length > 0;
  const isAnyChecked =
    isVoivodeshipsSelected || isLocalitiesSelected || isSchoolSelected || isSchoolRemoved;

  const LIST_ITEM_HEIGHT = 48;

  return (
    <>
      <hr />
      <List
        title={t('voivodeship')}
        selected={targets.voivodeships.length}
        placeholder={t('search')}
      >
        {dataVoivodeships?.length ? (
          <ListWrapper>
            {targets.voivodeships.length > 0 && (
              <>
                <ListTitle>{t('selected')}</ListTitle>
                <SelectedList>
                  {targets.voivodeships.map((voivodeship) => (
                    <Row
                      key={voivodeship}
                      title={voivodeship}
                      labels={[voivodeship]}
                      onClick={() => onSelectVoivodeship(voivodeship)}
                      selected
                      cellChildren={<ToggleIcon image={'minus'} />}
                    />
                  ))}
                </SelectedList>
              </>
            )}
            <ListTitle>{t('optional')}</ListTitle>
            <SearchList height={`${dataVoivodeships.length * LIST_ITEM_HEIGHT}px`}>
              <AutoSizer disableWidth>
                {() => (
                  <>
                    <FixedSizeList
                      height={dataVoivodeships.length * LIST_ITEM_HEIGHT}
                      width="100%"
                      itemCount={dataVoivodeships.length}
                      itemSize={LIST_ITEM_HEIGHT}
                      innerElementType="ul"
                    >
                      {({ index, ...props }) => (
                        <Row
                          title={dataVoivodeships[index].voivodeship}
                          labels={[dataVoivodeships[index].voivodeship]}
                          onClick={() => onSelectVoivodeship(dataVoivodeships[index].voivodeship)}
                          disabled={
                            !!targets.voivodeships.find(
                              (voivodeship) => voivodeship === dataVoivodeships[index].voivodeship,
                            )
                          }
                          cellChildren={
                            <ToggleIcon
                              image={
                                targets.voivodeships.find(
                                  (voivodeship) =>
                                    voivodeship === dataVoivodeships[index].voivodeship,
                                )
                                  ? 'check'
                                  : 'plus'
                              }
                            />
                          }
                          {...props}
                        />
                      )}
                    </FixedSizeList>
                  </>
                )}
              </AutoSizer>
            </SearchList>
          </ListWrapper>
        ) : (
          <EmptyState title={t('no_locations')} />
        )}
      </List>
      <hr />
      <List
        title={t('localities')}
        name="localities"
        selected={targets.localities.length}
        placeholder={t('search')}
        onChange={(e) => onChangeFilter({ city: e.target.value })}
        resetFilter={() => onChangeFilter({ city: '' })}
        value={nameFilter.city}
        isSearchClearDisabled={nameFilter.city.length < 1}
      >
        {dataLocalities?.content.length ? (
          <ListWrapper>
            {targets.localities.length > 0 && (
              <>
                <ListTitle>{t('selected')}</ListTitle>
                <SelectedList>
                  {targets.localities.map((city) => (
                    <Row
                      key={city}
                      title={city}
                      labels={[city]}
                      onClick={() => onSelectCity(city)}
                      selected
                      cellChildren={<ToggleIcon image={'minus'} />}
                    />
                  ))}
                </SelectedList>
              </>
            )}
            <ListTitle>{t('optional')}</ListTitle>
            <SearchList height={`${dataLocalities.content.length * LIST_ITEM_HEIGHT}px`}>
              <AutoSizer disableWidth>
                {() => (
                  <>
                    <FixedSizeList
                      height={dataLocalities.content.length * LIST_ITEM_HEIGHT}
                      width="100%"
                      itemCount={dataLocalities.content.length}
                      itemSize={LIST_ITEM_HEIGHT}
                      innerElementType="ul"
                    >
                      {({ index, ...props }) => (
                        <Row
                          title={dataLocalities.content[index].city}
                          labels={[dataLocalities.content[index].city]}
                          onClick={() => onSelectCity(dataLocalities.content[index].city)}
                          disabled={
                            !!targets.localities.find(
                              (city) => city === dataLocalities.content[index].city,
                            )
                          }
                          cellChildren={
                            <ToggleIcon
                              image={
                                targets.localities.find(
                                  (city) => city === dataLocalities.content[index].city,
                                )
                                  ? 'check'
                                  : 'plus'
                              }
                            />
                          }
                          {...props}
                        />
                      )}
                    </FixedSizeList>
                  </>
                )}
              </AutoSizer>
            </SearchList>
          </ListWrapper>
        ) : (
          <EmptyState title={t('no_locations')} />
        )}
      </List>
      <hr />
      <List
        title={t('schools')}
        name="schools"
        selected={targets.schools.length}
        placeholder={t('search')}
        onChange={(e) => onChangeFilter({ school: e.target.value })}
        resetFilter={() => onChangeFilter({ school: '' })}
        value={nameFilter.school}
        isSearchClearDisabled={nameFilter.school.length < 1}
      >
        <ListWrapper>
          {targets.removedSchools.length > 0 && (
            <>
              <ListTitle>{t('removed')}</ListTitle>
              <SelectedList>
                {targets.removedSchools.map((school) => (
                  <>
                    <Row
                      key={school.id}
                      title={school.name}
                      labels={[
                        school.name,
                        school.voivodeship,
                        school.locality,
                        school.street,
                        school.number,
                      ]}
                      onClick={() => onRemoveSchool(school)}
                      cellChildren={<ToggleIcon image={'plus'} />}
                      selected
                    />
                  </>
                ))}
              </SelectedList>
            </>
          )}
          {sentToSchools.length > 0 && (
            <>
              <ListTitle>{t('sent_to')}</ListTitle>
              <SelectedList>
                {sentToSchools.map((school, index) => (
                  <Row
                    key={school.id}
                    title={school.name}
                    labels={[
                      school.name,
                      school.voivodeship,
                      school.locality,
                      school.street,
                      school.number,
                    ]}
                    disabled={
                      !!targets.removedSchools.find(
                        (school) => school.id === sentToSchools[index].id,
                      ) || sentToSchools[index].valid_licence !== true
                    }
                    onClick={() => onRemoveSchool(school)}
                    cellChildren={
                      targets.removedSchools.find(
                        (school) => school.id === sentToSchools[index].id,
                      ) ? (
                        <ToggleIcon image={'close'} />
                      ) : (
                        <ToggleIcon image={'minus'} />
                      )
                    }
                    clickable={true}
                  />
                ))}
              </SelectedList>
            </>
          )}
        </ListWrapper>

        {availableSchools?.length ? (
          <ListWrapper>
            {targets.schools.length > 0 && (
              <>
                <ListTitle>{t('selected')}</ListTitle>
                <SelectedList>
                  {targets.schools.map((school) => (
                    <Row
                      key={school.id}
                      title={school.name}
                      labels={[
                        school.name,
                        school.voivodeship,
                        school.locality,
                        school.street,
                        school.number,
                      ]}
                      onClick={() => onSelectSchool(school)}
                      cellChildren={<ToggleIcon image={'minus'} />}
                      selected
                    />
                  ))}
                </SelectedList>
              </>
            )}
            <ListTitle>{t('optional')}</ListTitle>
            <SearchList height={`${availableSchools.length * LIST_ITEM_HEIGHT}px`}>
              <AutoSizer disableWidth>
                {() => (
                  <>
                    <FixedSizeList
                      height={availableSchools.length * LIST_ITEM_HEIGHT}
                      width="100%"
                      itemCount={availableSchools.length}
                      itemSize={LIST_ITEM_HEIGHT}
                      innerElementType="ul"
                    >
                      {({ index, ...props }) => (
                        <Row
                          title={availableSchools[index].name}
                          labels={[
                            availableSchools[index].name,
                            availableSchools[index].voivodeship,
                            availableSchools[index].locality,
                            availableSchools[index].street,
                            availableSchools[index].number,
                          ]}
                          onClick={() => onSelectSchool(availableSchools[index])}
                          disabled={
                            !!targets.schools.find(
                              (school) => school.id === availableSchools[index].id,
                            )
                          }
                          cellChildren={
                            <ToggleIcon
                              image={
                                targets.schools.find(
                                  (school) => school.id === availableSchools[index].id,
                                )
                                  ? 'check'
                                  : 'plus'
                              }
                            />
                          }
                          {...props}
                        />
                      )}
                    </FixedSizeList>
                  </>
                )}
              </AutoSizer>
            </SearchList>
          </ListWrapper>
        ) : (
          <EmptyState title={t('no_schools')} />
        )}
      </List>
      <ToolbarBottom
        onSubmit={submitData}
        disabled={isNoticeChanged || !isAnyChecked}
        label={t('send_notice')}
      />
    </>
  );
};

export default memo(Target);
