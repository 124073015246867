import React from 'react';

import { DetailsTitleStyled } from './styles';

const DetailsTitle: React.FC<{ title: string; empty?: boolean }> = ({ title, empty, ...props }) => (
  <DetailsTitleStyled {...props} empty={empty}>
    {title}
  </DetailsTitleStyled>
);

export default DetailsTitle;
