import { StylesConfig } from 'react-select';

export const customSelectStyles: StylesConfig = {
  container: (provided) => ({
    ...provided,
    marginBottom: '16px',
    width: '100%',
  }),
  option: (provided, state: { isSelected: boolean }) => ({
    ...provided,
    cursor: state.isSelected ? 'default' : 'pointer',
    color: 'var(--select-option-txt)',
    backgroundColor: state.isSelected
      ? 'var(--select-option-bg--selected)'
      : 'var(--select-option-bg)',
    fontSize: '16px',
    minHeight: '30px',
    lineHeight: '16px',
    ':hover': {
      backgroundColor: state.isSelected
        ? 'var(--select-option-bg--selected--hover)'
        : 'var(--select-option-bg--hover)',
    },
  }),
  control: (provided) => ({
    ...provided,
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px 12px',
    width: '100%',
    minWidth: 0,
    minHeight: 0,
    height: '48px',
    border: `1px solid var(--select-border)`,
    borderRadius: `4px`,
    backgroundColor: 'var(--select-bg)',
    color: 'var(--select-txt)',
    boxShadow: '0 6px 20px 0 var(--select-shadow)',
    cursor: 'pointer',
    outline: 'none',
    ':hover': {
      borderColor: 'var(--select-border--hover)',
      backgroundColor: 'var(--select-bg--hover)',
      color: 'var(--select-txt--hover)',
      boxShadow: '0 6px 20px 0 var(--select-shadow)',
    },
  }),
  menu: (provided) => ({
    ...provided,
    padding: 0,
    borderRadius: `4px`,
    border: `1px solid var(--select-border)`,
    boxShadow: '0 6px 20px 0 var(--select-shadow)',
  }),
  menuList: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    backgroundColor: 'var(--select-option-bg)',
  }),
  singleValue: (provided) => ({
    ...provided,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: 'var(--select-option-txt)',
    fontSize: '16px',
    lineHeight: '20px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  indicatorsContainer: () => ({ height: 16 }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'var(--select-option-txt)',
    padding: 0,
    width: 16,
    height: 16,
    ':hover': {
      color: 'var(--select-option-txt)',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    padding: 0,
  }),
  input: () => ({ margin: 0 }),
  placeholder: () => ({ fontSize: '16px', color: 'var(--select-option-txt--placeholder)' }),
};
