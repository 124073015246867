import React, { useState, ChangeEvent, memo } from 'react';
import dayjs from 'dayjs';

import { ActionTypes } from 'App/types';
import { useApp } from 'App';
import usePrefix from 'utils/usePrefix';
import { useApi, getApiUrl } from 'utils/api/useApi';
import { api, ResponseError } from 'utils/api';
import { ChatDetailsResponse } from 'utils/api/chats';
import { notificationTypes } from 'utils/constants';

import Button from 'components/_Redesign/Button';
import RowShort from 'components/RowShort';
import ToggleSwitch from 'components/ToggleSwitch';
import EmptyState from 'components/EmptyState';
import SidePanel from 'components/SidePanel';
import { SidePanelContentStyled, SidePanelHeaderStyled } from 'components/SidePanel/styles';
import FetchingMessage from 'components/FetchingMessage';
import { ActionsStyled, FormContentStyled } from 'components/Form/styles';

import { Label } from '../styles';
import {
  TextArea,
  TakenActionStyled,
  SingleNoteStyled,
  SingleNoteContentStyled,
  SingleNoteDateStyled,
  SingleNoteSwitchStyled,
  SingleNoteSwitchLabelStyled,
  NotesScreenStyled,
  NoteSectionStyled,
} from './styles';

const url = getApiUrl('/interveners/me/chats');

interface Props {
  chatId: number;
  closeNotesScreen: () => void;
}

const NotesScreen: React.FC<Props> = ({ chatId, closeNotesScreen }) => {
  const t = usePrefix('Chat');
  const [note, setNote] = useState('');
  const [descriptionOfActionsTaken, setDescriptionOfActionsTaken] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedNoteId, setSelectedNoteId] = useState<number>();

  const { data, isLoading, fetchData } = useApi<ChatDetailsResponse>(`${url}/${chatId}`, {
    method: 'GET',
  });

  const [, dispatch] = useApp();

  const isValueNotChanged = (note: string) =>
    !!selectedNoteId &&
    note === data?.chat.notes.find((noteData) => noteData.id === selectedNoteId)?.note;

  const submit = async () => {
    setIsSubmitting(true);

    try {
      const response = await api(`${url}/${chatId}/notes`, {
        method: 'POST',
        payload: { note, is_description_of_actions_taken: descriptionOfActionsTaken },
      });

      if (response) {
        setNote('');
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        fetchData();
        setIsSubmitting(false);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      setIsSubmitting(false);
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const selectedNote = data?.chat.notes.find((note) => note.id === selectedNoteId);

  return (
    <SidePanel onClose={closeNotesScreen}>
      <NotesScreenStyled>
        <SidePanelContentStyled>
          <NoteSectionStyled>
            <SidePanelHeaderStyled>
              <h3>{t('add_new_note')}</h3>
            </SidePanelHeaderStyled>
            {!isLoading ? (
              <>
                <FormContentStyled>
                  <TextArea
                    placeholder={t('provide_notes')}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNote(e.target.value)}
                    value={note}
                    autoFocus
                  />
                  <TakenActionStyled>
                    <Label>{t('taken_action')}</Label>
                    <ToggleSwitch
                      name="is_description_of_actions_taken"
                      checked={descriptionOfActionsTaken}
                      onChange={(e) => setDescriptionOfActionsTaken(e.target.checked)}
                    />
                  </TakenActionStyled>
                </FormContentStyled>
                <ActionsStyled>
                  <Button
                    label={t('save')}
                    isDisabled={isValueNotChanged(note) || !note || isSubmitting}
                    color="primary"
                    size="lg"
                    onClick={() => submit()}
                  />
                </ActionsStyled>
              </>
            ) : (
              <FetchingMessage title={t('fetching_meta_data')} />
            )}
          </NoteSectionStyled>

          {selectedNote && (
            <NoteSectionStyled>
              <SidePanelHeaderStyled>
                <h3>{t('selected_note_content')}</h3>
              </SidePanelHeaderStyled>
              <SingleNoteStyled>
                <SingleNoteSwitchStyled>
                  <SingleNoteSwitchLabelStyled>{t('taken_action')}</SingleNoteSwitchLabelStyled>
                  <ToggleSwitch
                    name="is_description_of_actions_taken"
                    checked={selectedNote.is_description_of_actions_taken}
                    onChange={(e) => setDescriptionOfActionsTaken(e.target.checked)}
                    isDisabled
                  />
                </SingleNoteSwitchStyled>
                <SingleNoteContentStyled>{selectedNote.note}</SingleNoteContentStyled>
                <SingleNoteDateStyled>
                  {dayjs(selectedNote.creation_time).format('H:mm DD.MM.YYYY')}{' '}
                  {selectedNote.author}
                </SingleNoteDateStyled>
              </SingleNoteStyled>
            </NoteSectionStyled>
          )}
        </SidePanelContentStyled>
        <SidePanelContentStyled $isSeparator>
          <SidePanelHeaderStyled>
            <h3>{t('notes')}</h3>
          </SidePanelHeaderStyled>
          <div>
            {!isLoading && !!data ? (
              <>
                {data.chat.notes.map((note) => (
                  <RowShort
                    key={note.id}
                    title={note.note}
                    subtitle={dayjs(note.creation_time).format('H:mm DD.MM.YYYY')}
                    onClick={() => setSelectedNoteId(note.id)}
                    selected={!!selectedNoteId && note.id === selectedNoteId}
                    unread={note.is_description_of_actions_taken}
                  />
                ))}
                {!data.chat.notes.length && <EmptyState title={t('no_notes_to_display')} />}
              </>
            ) : (
              <FetchingMessage title={t('fetching_meta_data')} />
            )}
          </div>
        </SidePanelContentStyled>
      </NotesScreenStyled>
    </SidePanel>
  );
};

export default memo(NotesScreen);
