import React, { useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { getApiUrl, PaginatedList } from 'utils/api';
import { useApi } from 'utils/api/useApi';
import usePrefix from 'utils/usePrefix';
import { NoticeListResponse } from 'utils/api/notice';
import { findViolenceTypeKey } from 'utils/misc';
import { useApp } from 'App';

import FlexDiv from 'components/FlexDiv';
import TableContainer from 'components/TableContainer';
import Row from 'components/Row';
import FetchingMessage from 'components/FetchingMessage';

import Notice from './Notice';

const url = getApiUrl('/notices');

const RECORD_COUNT = 9999999;

export interface ModalState {
  isOpen: boolean;
  noticeId?: number;
}

const Notices: React.FC = () => {
  const t = usePrefix('Notices');
  const tv = usePrefix('Violence');

  const [{ violenceTypes }] = useApp();

  const [modal, setModal] = useState<ModalState>({ isOpen: false });
  const [nameFilter, setNameFilter] = useState('');

  const MIN_QUERY_LENGTH = 3;

  const { data, isLoading, fetchData } = useApi<PaginatedList<NoticeListResponse[]>>(
    nameFilter.length < MIN_QUERY_LENGTH
      ? `${url}?page=0&size=${RECORD_COUNT}`
      : `${url}?page=0&size=${RECORD_COUNT}&name=${nameFilter}`,
    {
      method: 'GET',
    },
  );

  const handleFilterReset = () => {
    setNameFilter('');
  };

  if (!data) return <FetchingMessage title={t('fetching_notices')} />;

  return (
    <FlexDiv
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="flex-start"
      flexDirection="column"
      flex={1}
    >
      <TableContainer
        title={t('notices')}
        isLoading={isLoading}
        isData={!!data.content.length}
        setFilter={setNameFilter}
        labelLoader={t('fetching_notices')}
        labelNoResults={t('no_notices')}
        labelButton={t('add_notice')}
        filter={nameFilter}
        setModal={setModal}
        resetFilter={handleFilterReset}
        isSearchClearDisabled={nameFilter.length < 1}
      >
        <AutoSizer disableWidth>
          {({ height }: { height: number }) => (
            <List
              height={height}
              width="100%"
              itemCount={data.content.length}
              itemSize={48}
              innerElementType="ul"
            >
              {({ index, ...props }) => (
                <Row
                  title={data.content[index].name}
                  labels={[
                    data.content[index].name,
                    t(data.content[index].type),
                    data.content[index].numberOfSends.toString(),
                    data.content[index].violenceId
                      ? tv(findViolenceTypeKey(violenceTypes, data.content[index].violenceId))
                      : t('na'),
                  ]}
                  onClick={() => setModal({ isOpen: true, noticeId: data.content[index].id })}
                  {...props}
                />
              )}
            </List>
          )}
        </AutoSizer>
      </TableContainer>

      {modal.isOpen && (
        <Notice
          notices={data.content}
          noticeId={modal.noticeId}
          onClose={() => setModal({ isOpen: false })}
          fetchData={fetchData}
          isOpen={modal.isOpen}
        />
      )}
    </FlexDiv>
  );
};

export default Notices;
