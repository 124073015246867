import { map, join, LodashMap, isObject } from 'lodash/fp';
import i18n from 'i18next';

const customMap = map as CustomMap; // Because convert is missing in lodash types
const uncappedMap = customMap.convert({ cap: false });

export const passwordMinLength = 8;
export const upperRegex = /(?=[A-Z])/;
export const lowerRegex = /(?=[a-z])/;
export const numberRegex = /(?=[0-9])/;
export const specialCharRegex = /(?=[!@#$%^&*(),.?"';:{}|<>])/;
export const filenameFromPathRegex = /[^/]+$/;

const joinErrors = (errors: any): string =>
  `${join(
    ', ',
    uncappedMap(
      (value: string, key: string) => `${key}: ${isObject(value) ? joinErrors(value) : value}`,
      errors,
    ),
  )}`;

/* TODO -> sprawdzić, czy funkcja do usunięcia */
export const parseErrorsToMessage = (errors: any): string =>
  `${i18n.t('common:hasErrors')}${joinErrors(errors)}`;

interface CustomMap extends LodashMap {
  convert: (arg: Convert) => LodashMap;
}

type Convert = {
  cap?: boolean;
};
