import React, { FC, memo, ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconName } from 'components/Icons';
import { CtaIcon, CtaLabelStyled, CtaStyledProps } from 'components/_Redesign/CallToAction/styles';
import { CtaColor, CtaShape, CtaSize, CtaVariant } from 'components/_Redesign/CallToAction/types';
import { setIconSize } from 'components/_Redesign/CallToAction/setIconSize';

import { ButtonLinkStyled } from './styles';

export interface ButtonLinkProps extends CtaStyledProps {
  $isActive?: boolean;
  children?: ReactNode;
  className?: string;
  color?: CtaColor;
  icon?: IconName;
  id?: string;
  isDisabled?: boolean;
  isFetching?: boolean;
  label?: string;
  role?: string;
  size?: CtaSize;
  shape?: CtaShape;
  tabIndex?: number;
  title?: string;
  url: string;
  variant?: CtaVariant;
}

const ButtonLink: FC<ButtonLinkProps> = ({
  $isActive,
  children,
  className,
  color,
  icon,
  id,
  isDisabled,
  label,
  url,
  role,
  shape,
  size,
  tabIndex,
  title,
  variant,
}) => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    if (url) {
      navigate(url);
    }
  }, [url, navigate]);

  return (
    <ButtonLinkStyled
      $color={color}
      $isActive={$isActive}
      $shape={shape}
      $size={size}
      $variant={variant}
      className={className}
      disabled={isDisabled}
      id={id}
      onClick={onClick}
      role={role}
      tabIndex={tabIndex}
      title={title}
      type="button"
    >
      {!!icon && <CtaIcon type={icon} width={setIconSize(size)} height={setIconSize(size)} />}
      {!!label && <CtaLabelStyled>{label}</CtaLabelStyled>}
      {children}
    </ButtonLinkStyled>
  );
};

export default memo(ButtonLink);
