import styled from 'styled-components';
import { ActionsAlignment } from './types';
import { actionsAlignmentFunc } from './align';

export interface ActionsStyledProps {
  $align?: ActionsAlignment;
}

export const FormContentStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100;
`;

export const ActionsStyled = styled.div<ActionsStyledProps>`
  display: flex;
  align-items: center;
  column-gap: 24px;
  margin-top: 24px;
  width: 100%;
  min-width: 0;

  ${actionsAlignmentFunc};
`;
