import React, { memo, useEffect, useState } from 'react';

import { getApiUrl } from 'utils/api';
import usePrefix from 'utils/usePrefix';
import { useApi } from 'utils/api/useApi';
import { UserCompanyPermissions, UserPermissionRequest, UserType } from 'utils/api/assignments';

import SidePanel from 'components/SidePanel';
import { SidePanelContentStyled } from 'components/SidePanel/styles';
import FetchingMessage from 'components/FetchingMessage';

import AssignmentForm from './AssignmentForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  userFirstName: string | null;
  userLastName: string | null;
  userType: UserType | null;
  userId?: number;
  companyId?: number;
}

const AssignmentDetails: React.FC<Props> = ({
  isOpen,
  onClose,
  userId,
  companyId,
  userType,
  userFirstName,
  userLastName,
}) => {
  const t = usePrefix('General');
  const url = getApiUrl('/director/me/permissions/company');

  const { data, isLoading } = useApi<UserCompanyPermissions>(`${url}/${companyId}/user/${userId}`, {
    method: 'GET',
  });

  const [initialValues, setInitialValues] = useState<UserPermissionRequest>({
    user_type: userType,
    only_assigned_to_me: false,
    can_change_role: false,
    assigned_categories: [],
  });

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setInitialValues({
        user_type: data.user_type,
        only_assigned_to_me: data.only_assigned_to_me,
        can_change_role: data.can_change_role,
        assigned_categories: data.assigned_categories,
      });
    }
  }, [data]);

  return isOpen ? (
    <SidePanel onClose={onClose}>
      <SidePanelContentStyled>
        {!isLoading && data ? (
          <AssignmentForm
            isNew={!companyId}
            onClose={onClose}
            values={initialValues}
            companyId={companyId}
            userId={userId}
            userFirstName={userFirstName}
            userLastName={userLastName}
          />
        ) : (
          <FetchingMessage title={t('fetching')} />
        )}
      </SidePanelContentStyled>
    </SidePanel>
  ) : null;
};

export default memo(AssignmentDetails);
