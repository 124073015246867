import { css } from 'styled-components';

import { ModalDialogStyledProps } from './styles';
import { ModalAlign } from './types';

export const alignLeft = css`
  text-align: left;
`;

export const alignCenter = css`
  text-align: center;
`;

export const alignRight = css`
  text-align: right;
`;

export const modalAligns: Record<ModalAlign, any> = {
  left: alignLeft,
  center: alignCenter,
  right: alignRight,
};

export const modalAlignsFunc = ({ $align }: { $align?: ModalDialogStyledProps['$align'] }) =>
  $align ? modalAligns[$align] : modalAligns.center;
