/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';

import { screenMdUnder, screenXlgAbove } from 'styles/breakpoinst';
import { calcSize } from 'styles/calcSize';
import { focusVisibleStyles } from 'styles/commonStyles';

import FlexDiv from 'components/FlexDiv';

export const Wrapper = styled(FlexDiv)`
  flex: 0 0 280px;
  height: 100%;
  overflow: hidden;
  border-right: 1px solid var(--chat-list-border);
  > div {
    flex: 1;
    width: 100%;
  }
  @media screen and (min-width: ${screenXlgAbove}) {
    flex: 0 0 360px;
  }
  @media screen and (max-width: ${screenMdUnder}) {
    display: none;
  }
`;

export const CategoriesWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding: 0 20px;
`;

export const ChatTabsStyled = styled.div`
  border-bottom: 1px solid var(--chat-tab-border);
`;

const chatTabActiveStyles = css`
  color: var(--chat-tab-txt--active);
  cursor: default;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid currentColor;
  }
`;

export const ChatTabStyled = styled.button<{ $isActive: boolean }>`
  position: relative;
  flex: 1;
  padding: 8px 16px;
  min-width: 0;
  font-size: ${calcSize(12)};
  color: var(--chat-tab-txt);
  text-align: left;
  cursor: pointer;
  white-space: pre-line;

  &:not(:first-child) {
    &:before {
      content: '';
      position: absolute;
      top: 8px;
      bottom: 8px;
      left: 0;
      width: 1px;
      background: var(--chat-tab-border);
    }
  }

  &:focus-visible {
    ${focusVisibleStyles};
  }

  ${({ $isActive }) => $isActive && chatTabActiveStyles};
`;
