/* eslint-disable no-magic-numbers */
import { css } from 'styled-components';

import { calcSize } from 'styles/calcSize';

import { FormFieldStyledProps } from './styles';
import { FormFieldSize } from './types';

export const sizeSm = css`
  // TODO
`;

export const sizeMd = css`
  padding: 11px 12px;
  height: 48px;
  font-weight: 400;
  font-size: ${calcSize(14)};
  line-height: 1.2;
`;

export const sizeLg = css`
  // TODO
`;

export const formFieldSizes: Record<FormFieldSize, any> = {
  sm: sizeSm,
  md: sizeMd,
  lg: sizeLg,
};

export const formFieldSizesFunc = ({
  $sizeField,
}: {
  $sizeField?: FormFieldStyledProps['$sizeField'];
}) => ($sizeField ? formFieldSizes[$sizeField] : 'md');
