import React, { FC, memo } from 'react';

import { RequirementProps, RequirementItemStyled, RequirementItemIcon } from './styles';

const RequirementItem: FC<RequirementProps> = ({ touched, error, children }) => (
  <RequirementItemStyled touched={touched} error={error}>
    {touched ? (
      <RequirementItemIcon
        error={error}
        type={error ? 'closeCircleFill' : 'checkCircleFill'}
        width="16px"
        height="16px"
      />
    ) : (
      <span>&#8226;</span>
    )}
    <span>{children}</span>
  </RequirementItemStyled>
);

export default memo(RequirementItem);
