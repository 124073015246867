import React, { FC, memo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { getApiUrl, ResponseError } from 'utils/api';
import { notificationTypes } from 'utils/constants';
import usePrefix from 'utils/usePrefix';

import FormField from 'components/FormField';
import InputField from 'components/InputField';
import ButtonHref from 'components/_Redesign/ButtonHref';
import Modal from 'components/_Redesign/Modal';
import {
  ModalActionsStyled,
  ModalBodyStyled,
  ModalHeaderStyled,
  ModalIcon,
} from 'components/_Redesign/Modal/styles';
import { MODAL_ICON_SIZE_CSS } from 'components/_Redesign/Modal/constants';
import Button from 'components/_Redesign/Button';

import { DateCell, DateRow } from '../styles';

const url = getApiUrl('/interveners/me/chats');

const validationSchema = (ty: (key: string, vars?: object) => string) =>
  Yup.object().shape({
    date_from: Yup.date()
      .min('2020-01-01', ty('chat_min_date_from'))
      .max(Yup.ref('date_to'), ty('chat_max_date_from'))
      .required(),
    date_to: Yup.date()
      .min(Yup.ref('date_from'), ty('chat_min_date_to'))
      .max(dayjs().format('YYYY-MM-DD'), ty('chat_max_date_to'))
      .required(),
  });

interface FormValues {
  date_from: string;
  date_to: string;
}

interface Props {
  chatId: number;
  chatCreationTime?: string;
  isOpen: boolean;
  onClose: () => void;
}

const ChatDownloadModal: FC<Props> = ({ chatId, chatCreationTime, onClose, isOpen }) => {
  const [, dispatch] = useApp();

  const t = usePrefix('Chat');
  const tg = usePrefix('General');
  const ty = usePrefix('YupErrors');

  const initialValues: FormValues = {
    date_from: dayjs(chatCreationTime).format('YYYY-MM-DD'),
    date_to: dayjs().format('YYYY-MM-DD'),
  };

  const submit = async (values: FormValues) => {
    try {
      // eslint-disable-next-line no-console
      console.log(values);
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return isOpen ? (
    <Modal onClose={onClose} align="center" size="sm">
      <ModalHeaderStyled>
        <ModalIcon
          type="download"
          width={MODAL_ICON_SIZE_CSS}
          height={MODAL_ICON_SIZE_CSS}
          $state="info"
        />
        <h3>{t('chat_download_title')}</h3>
      </ModalHeaderStyled>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={() => validationSchema(ty)}
        enableReinitialize={true}
      >
        {({ values, touched, errors }) => (
          <Form autoComplete="off">
            <ModalBodyStyled>
              <DateRow>
                <DateCell>
                  <FormField label={t('chat_download_from')} margin="0">
                    <InputField
                      name="date_from"
                      error={touched.date_to && !!errors.date_to}
                      autoFocus
                      type="date"
                      dimension="lg"
                    />
                  </FormField>
                </DateCell>
                <DateCell>
                  <FormField label={t('chat_download_to')} margin="0">
                    <InputField
                      name="date_to"
                      error={touched.date_to && !!errors.date_to}
                      autoFocus
                      type="date"
                      dimension="lg"
                    />
                  </FormField>
                </DateCell>
              </DateRow>
            </ModalBodyStyled>
            <ModalActionsStyled>
              <Button onClick={onClose} color="secondary" size="lg" label={tg('cancel')} />
              <ButtonHref
                href={`${url}/${chatId}/pdf?date_from=${values.date_from}&date_to=${values.date_to}`}
                download
                label={t('chat_download_button')}
                color="primary"
                size="lg"
              />
            </ModalActionsStyled>
          </Form>
        )}
      </Formik>
    </Modal>
  ) : null;
};

export default memo(ChatDownloadModal);
