import { css } from 'styled-components';

export const focusVisibleStyles = css`
  outline: 2px solid var(--focus-state);
  outline-offset: 2px;
`;

export const focusVisibleInsideStyles = css`
  outline: 2px solid var(--focus-state);
  outline-offset: -2px;
`;
