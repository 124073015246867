import { css } from 'styled-components';

import { ModalIconProps } from './styles';
import { ModalState } from './types';

export const errorState = css`
  fill: var(--state-error);
`;

export const successState = css`
  fill: var(--state-success);
`;

export const infoState = css`
  fill: var(--state-info);
`;

export const modalStates: Record<ModalState, any> = {
  error: errorState,
  success: successState,
  info: infoState,
};

export const modalStatesFunc = ({ $state }: { $state?: ModalIconProps['$state'] }) =>
  $state ? modalStates[$state] : modalStates.info;
