import React, { InputHTMLAttributes } from 'react';
import { FieldProps, Field } from 'formik';

import { SwitchTrackStyled, SwitchInputStyled, SwitchStyled } from 'components/Switch/styles';

interface InputProps {
  name: string;
  checked: boolean;
}

const ToggleSwitchFormik: React.FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
  name,
  checked,
  ...props
}) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <SwitchStyled>
        <SwitchInputStyled {...field} type="checkbox" name={name} checked={checked} {...props} />
        <SwitchTrackStyled />
      </SwitchStyled>
    )}
  </Field>
);

export default ToggleSwitchFormik;
