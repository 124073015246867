/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';

import { screenMdAbove } from 'styles/breakpoinst';
import { LAYER_SIDE_PANEL } from 'styles/layers';

import Button from 'components/_Redesign/Button';
import { SIDE_PANEL_SPACE_CSS } from './constants';
import { calcSize } from '../../styles/calcSize';

export const SidePanelStyled = styled('aside')`
  position: fixed;
  top: 0;
  right: 0;
  z-index: ${LAYER_SIDE_PANEL};
  display: grid;
  grid-template-columns: 64px 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80vw;
  max-width: 1232px;
  height: 100%;
  border-radius: 12px 0 0 12px;
  background: var(--modal-bg);
  color: var(--modal-txt);
  box-shadow: 0 0 24px var(--modal-shadow);
  overflow: hidden;

  hr {
    margin: 48px 0;
    border-top: 1px solid var(--modal-line);
  }
`;

const sidePanelContentSeparatorStyles = css`
  border-left: 1px solid var(--modal-line);
`;

interface SidePanelContentStyledProps {
  $isSeparator?: boolean;
}

export const SidePanelContentStyled = styled('div')<SidePanelContentStyledProps>`
  ${({ $isSeparator }) => ($isSeparator ? sidePanelContentSeparatorStyles : '')};
  padding: ${SIDE_PANEL_SPACE_CSS};
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

// TODO - do późniejszego wykorzystania
export const SidePanelHeaderStyled = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h3 {
    font-weight: 600;
    font-size: ${calcSize(22)};
  }
`;

export const SidePanelCloseStyled = styled('div')`
  padding: ${SIDE_PANEL_SPACE_CSS} 0 ${SIDE_PANEL_SPACE_CSS} ${SIDE_PANEL_SPACE_CSS};
`;

export const SidePanelButtonClose = styled(Button)`
  position: sticky;
  top: 0;
  left: 0;
  justify-content: center;
  padding: 4px;
  border-radius: 0;
  border-width: 0;
  width: 40px;
  height: 40px;
  background: var(--modal-bg);
  color: var(--modal-txt);

  @media (hover: hover) {
    &:hover:not(:disabled) {
      color: var(--modal-txt--secondary);
    }
  }
`;

// TODO - do późniejszego wykorzystania
export const SidePanelActionsStyled = styled('div')`
  position: sticky;
  bottom: -${SIDE_PANEL_SPACE_CSS};
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  margin: 16px -${SIDE_PANEL_SPACE_CSS} -${SIDE_PANEL_SPACE_CSS};
  padding: ${SIDE_PANEL_SPACE_CSS};
  width: calc(100% + 2 * ${SIDE_PANEL_SPACE_CSS});
  background: var(--modal-bg);
`;
