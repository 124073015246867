import React from 'react';

import Button from 'components/_Redesign/Button';

import { FieldsContainer, ButtonsContainer } from '../styles';
import { ToolbarBottomStyled } from './styles';

interface Props {
  onSubmit?: () => void;
  disabled?: boolean;
  label: string;
}

const ToolbarBottom: React.FC<Props> = ({ onSubmit, disabled, label }) => (
  <ToolbarBottomStyled>
    <FieldsContainer>
      <ButtonsContainer justifyContent="flex-end">
        <Button label={label} onClick={onSubmit} isDisabled={disabled} color="primary" size="lg" />
      </ButtonsContainer>
    </FieldsContainer>
  </ToolbarBottomStyled>
);

export default ToolbarBottom;
