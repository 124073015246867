import styled from 'styled-components';
import Button from 'components/_Redesign/Button';

export const SearchStyled = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
`;

export const ButtonReset = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  aspect-ratio: 1 / 1;
  justify-content: center;
  padding-inline: 8px;
  height: 100%;
  border-radius: 4px;
  border: 0;

  &:disabled {
    visibility: hidden;
    cursor: default;
  }
`;
