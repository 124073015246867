import React, { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';

import { ChatMessageResponse, ChatWithLastMessageResponse } from 'utils/api/chats';
import { useApi, getApiUrl } from 'utils/api/useApi';
import { PaginatedList } from 'utils/api';
import Loader from 'components/Loader';
import usePrefix from 'utils/usePrefix';

import MessageForm from './MessageForm';
import Message from './Message';
import {
  PanelWrapper,
  StyledScroll,
  MessageWrapper,
  MessageContainer,
  MessagesPanel,
  MessageData,
  MessageBottom,
  MessageDate,
} from './styles';
import { IS_SYSTEM_MESSAGE_CLASS, IS_USER_MESSAGE_CLASS } from './constants';

const url = getApiUrl('/interveners/me/chats');

interface Props {
  chatId: number;
  anonymous?: boolean;
  deactivationTime: string | null;
  chatList: ChatWithLastMessageResponse[];
  onClearAndReset: () => void;
  nickname: string;
}

interface ChatState {
  chatsMessages: ChatMessageResponse[];
  page: number;
  size: number;
}

const Panel: React.FC<Props> = ({
  chatId,
  deactivationTime,
  anonymous,
  chatList,
  onClearAndReset,
  nickname,
}) => {
  const t = usePrefix('Chat');

  const PAGE_START = 0;
  const PAGE_SIZE = 20;
  const [state, setState] = useState<ChatState>({
    chatsMessages: [],
    page: PAGE_START,
    size: PAGE_SIZE,
  });

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { data, isLoading, fetchData } = useApi<PaginatedList<ChatMessageResponse[]>>(
    `${url}/${chatId}/messages?page=${state.page}&size=${state.size}`,
    {
      method: 'GET',
    },
  );

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView();
  };

  useEffect(() => {
    if (!state.page) scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.chatsMessages.length]);

  useEffect(() => {
    if (data?.content?.length) {
      setState((prevState) => ({
        ...prevState,
        chatsMessages: state.page
          ? [...prevState.chatsMessages, ...data.content]
          : [...data.content],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const clearState = () =>
    setState((prevState) => ({ ...prevState, page: PAGE_START, chatsMessages: [] }));

  useEffect(() => {
    if (!isLoading && state.page === PAGE_START && !state.chatsMessages.length) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.chatsMessages]);

  useEffect(() => {
    clearState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, chatList]);

  const loadMore = () => {
    if (!!messagesEndRef && !isLoading)
      // eslint-disable-next-line no-magic-numbers
      setState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
  };

  return (
    <MessagesPanel
      justifyContent="flex-end"
      alignItems="flex-start"
      alignContent="flex-start"
      flexDirection="column"
      flex={1}
    >
      <PanelWrapper>
        {isLoading && <Loader label={t('fetching_messages')} />}
        {!!data && !!state.chatsMessages.length && (
          <StyledScroll
            pageStart={PAGE_START}
            loadMore={loadMore}
            // eslint-disable-next-line no-magic-numbers
            hasMore={data.totalElements > PAGE_SIZE * (state.page + 1)}
            threshold={50}
            useWindow={false}
            isReverse={true}
            initialLoad={false}
          >
            <div ref={messagesEndRef} />
            {!!deactivationTime && (
              <MessageWrapper
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="flex-start"
                className={`${IS_USER_MESSAGE_CLASS} ${IS_SYSTEM_MESSAGE_CLASS}`}
              >
                <MessageData>
                  <MessageContainer>
                    {t('user_unregister', {
                      date: dayjs(new Date(deactivationTime)).format('YYYY-MM-DD'),
                      time: dayjs(new Date(deactivationTime)).format('H:mm'),
                    })}
                    <MessageBottom>
                      <MessageDate>{dayjs(deactivationTime).format('H:mm DD.MM.YYYY')}</MessageDate>
                    </MessageBottom>
                  </MessageContainer>
                </MessageData>
              </MessageWrapper>
            )}
            {state.chatsMessages.map((msg) => (
              <Message key={msg.id} chatId={chatId} response={msg} nickname={nickname} />
            ))}
          </StyledScroll>
        )}
      </PanelWrapper>
      <MessageForm
        chatId={chatId}
        disabled={anonymous || !!deactivationTime}
        onClearPanel={clearState}
        onClearAndReset={onClearAndReset}
      />
    </MessagesPanel>
  );
};

export default Panel;
