import React from 'react';

import Icon from 'components/Icons';
import { ToggleIconStyled } from './styles';

interface Props {
  image: 'minus' | 'plus' | 'check' | 'paperPlane' | 'close';
}

const ToggleIcon: React.FC<Props> = ({ image }) => (
  <ToggleIconStyled>
    <Icon type={image} width="24" height="24" />
  </ToggleIconStyled>
);

export default ToggleIcon;
