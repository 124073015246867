import styled from 'styled-components';

export const DotSeparator = styled.span`
  display: inline-block;
  margin: 0 5px;
  height: 4px;
  width: 4px;
  background: var(--chat-msg-dot-bg);
  border-radius: 50%;
`;
