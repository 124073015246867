import React from 'react';
import dayjs from 'dayjs';

import { useApp } from 'App';

import { getApiUrl } from 'utils/api';
import { useApi } from 'utils/api/useApi';
import { ChatReportDetailsResponse } from 'utils/api/report';
import usePrefix from 'utils/usePrefix';
import { userRoles } from 'utils/userRoles';

import FlexDiv from 'components/FlexDiv';
import Loader from 'components/Loader';
import EmptyState from 'components/EmptyState';

import { Content } from '../styles';
import { ChatsTableStyled, ChatsStyled, RegisterOfNotificationsStyled } from './styles';

interface Props {
  reportParams: string;
}

const RegisterOfNotifications: React.FC<Props> = ({ reportParams }) => {
  const t = usePrefix('Reports');
  const tv = usePrefix('Violence');

  const [{ profile }] = useApp();
  const isDirector = profile?.role === userRoles.director;

  const urlAdmin = getApiUrl('/reports/chats');
  const urlDirector = getApiUrl(`/director/me/reports/chats`);

  const url = isDirector ? urlDirector : urlAdmin;

  const RECORD_COUNT = 9999999;

  const { data, isLoading } = useApi<ChatReportDetailsResponse[]>(
    `${url}${reportParams}&page=0&size=${RECORD_COUNT}`,
    {
      method: 'GET',
    },
  );

  return (
    <FlexDiv
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="flex-start"
      flexDirection="column"
      flex="1"
      width="100%"
    >
      <Content>
        <RegisterOfNotificationsStyled>
          {isLoading && <Loader label={t('fetching_users')} />}
          {!isLoading && !data?.length && <EmptyState title={t('no_generated_raports')} />}
          {!!data?.length && (
            <ChatsStyled>
              <ChatsTableStyled>
                <thead>
                  <tr>
                    <th>{t('chats_table_case_number')}</th>
                    <th>{t('chats_table_category')}</th>
                    <th>{t('chats_table_reporter_name')}</th>
                    <th>{t('chats_table_contact')}</th>
                    <th>{t('chats_table_creation_time')}</th>
                    <th>{t('chats_table_actions_taken')}</th>
                    <th>{t('chats_table_close_time')}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.chat_case_number || '-'}</td>
                      <td>{tv(`${item.violence_category || '-'}`)}</td>
                      <td>{item.reporter_name || '-'}</td>
                      <td>{item.reporter_email || '-'}</td>
                      <td>
                        {item.creation_time
                          ? `${dayjs(new Date(item.creation_time)).format('YYYY-MM-DD')}, ${dayjs(
                              new Date(item.creation_time),
                            ).format('HH:mm')}`
                          : '-'}
                      </td>
                      <td>{item.actions_taken || '-'}</td>
                      <td>
                        {item.close_time
                          ? `${dayjs(new Date(item.close_time)).format('YYYY-MM-DD')}, ${dayjs(
                              new Date(item.close_time),
                            ).format('HH:mm')}`
                          : '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </ChatsTableStyled>
            </ChatsStyled>
          )}
        </RegisterOfNotificationsStyled>
      </Content>
    </FlexDiv>
  );
};

export default RegisterOfNotifications;
