import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getApiUrl } from 'utils/api';
import { useApi } from 'utils/api/useApi';
import { GetUsersResponse } from 'utils/api/users';
import { languages, parseLang } from 'utils/languages';

const url = getApiUrl('/users/me');

const SetLanguage: React.FC = () => {
  const { i18n } = useTranslation();

  const { data } = useApi<GetUsersResponse>(url, {
    method: 'GET',
  });

  const lang = parseLang(data?.user_language || languages.pl).toLowerCase();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  return null;
};

export default SetLanguage;
