import React, { memo } from 'react';

import { useApp } from 'App';
import { ChatViolenceStatsResponse } from 'utils/api/chats';
import usePrefix from 'utils/usePrefix';

import Dropdown from 'components/Dropdown';

import SidePanel from 'components/SidePanel';
import { SidePanelContentStyled, SidePanelHeaderStyled } from 'components/SidePanel/styles';

interface Props {
  dataViolence?: ChatViolenceStatsResponse;
  isLoadingViolence?: boolean;
  closeViolenceScreen: () => void;
  onViolenceSelect: (violenceId: number) => void;
}

const ViolenceScreen: React.FC<Props> = ({ onViolenceSelect, closeViolenceScreen }) => {
  const [{ violenceTypes }] = useApp();
  const t = usePrefix('Chat');

  return (
    <SidePanel onClose={closeViolenceScreen}>
      <SidePanelContentStyled>
        <SidePanelHeaderStyled>
          <h3>{t('select_violence')}</h3>
        </SidePanelHeaderStyled>
        <Dropdown onSelect={onViolenceSelect} violenceTypes={violenceTypes} />
      </SidePanelContentStyled>
    </SidePanel>
  );
};

export default memo(ViolenceScreen);
