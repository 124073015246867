import React, { FC, memo, useCallback } from 'react';

import usePrefix from 'utils/usePrefix';
import { api, getApiUrl } from 'utils/api';

import { useApp } from 'App';
import { ActionTypes } from 'App/types';

import { NavButton, NavigationItemStyled, NavigationMenuStyled, StyledIcon } from './styles';

const MainMenu: FC = () => {
  const [, dispatch] = useApp();
  const t = usePrefix('Navigation');

  const onLogout = useCallback(() => {
    const url = getApiUrl('/users/me/logout');
    api(`${url}`, { method: 'POST' }).then();

    dispatch({ type: ActionTypes.LOGOUT });
  }, [dispatch]);

  return (
    <NavigationMenuStyled $isBottomAlign>
      <NavigationItemStyled>
        <NavButton type="button" onClick={onLogout}>
          <StyledIcon type="signOut" />
          <span>{t('logout')}</span>
        </NavButton>
      </NavigationItemStyled>
    </NavigationMenuStyled>
  );
};

export default memo(MainMenu);
