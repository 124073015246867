import styled from 'styled-components';

// TODO - do przemyślenia przy redesign
export const SwitchStyled = styled.label`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 24px;
`;

export const SwitchTrackStyled = styled.span`
  position: relative;
  display: inline-flex;
  width: 44px;
  height: 24px;
  border-radius: 12px;
  border: 2px solid var(--switch-track-border);
  background: var(--switch-track-bg);
  transition: 0.4s;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    background: var(--switch-dot-bg);
    border-radius: 50%;
    transition: 0.4s;
    cursor: pointer;
  }
`;

export const SwitchInputStyled = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  &:checked + ${SwitchTrackStyled} {
    border-color: var(--switch-track-border--active);
    background: var(--switch-track-bg--active);

    &:before {
      transform: translate(18px, -50%);
      background: var(--switch-dot-bg--active);
    }
  }
  &:disabled {
    cursor: default;

    + ${SwitchTrackStyled} {
      border-color: var(--switch-track-border--disabled);
      background: var(--switch-track-bg--disabled);
      cursor: default;

      &:before {
        background: var(--switch-dot-bg--disabled);
        cursor: default;
      }
    }
  }
`;
