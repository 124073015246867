import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';

export const DataCellStyled = styled(FlexDiv)<{ cols: number; whiteSpace: string }>`
  padding: 0 8px;
  width: calc(100% / ${({ cols }) => cols});
  white-space: ${({ whiteSpace }) => whiteSpace};
  min-width: 0;
  color: inherit;
`;

export const Value = styled.span<{ truncateText: boolean }>`
  word-wrap: break-word;
  ${({ truncateText }) =>
    truncateText &&
    `
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `}
`;
