import React from 'react';

import { SectionWrapper, Content, SectionTitle } from './styles';

const Section: React.FC<{ title: string; form?: boolean; children?: React.ReactNode }> = ({
  title,
  form,
  children,
}) =>
  form ? (
    <Content autoComplete="off">
      <SectionTitle>{title}</SectionTitle>
      {children}
    </Content>
  ) : (
    <SectionWrapper>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </SectionWrapper>
  );

export default Section;
