import React, { FC, memo } from 'react';
import usePrefix from 'utils/usePrefix';
import dayjs from 'dayjs';

import { DotSeparator } from 'pages/Chat/ChatMeta/GeneralScreen/styles';

import { ChatUserDataStyled } from './styles';

interface Props {
  name?: string;
  email?: string;
  creationTime?: string;
}

const ChatUserData: FC<Props> = ({ name, email, creationTime }) => {
  const t = usePrefix('Chat');
  const formattedCreationTime = creationTime && dayjs(creationTime).format('YYYY-MM-DD HH:mm');

  return name || email || formattedCreationTime ? (
    <ChatUserDataStyled>
      {formattedCreationTime || '-'}
      <DotSeparator />
      {name || '-'}
      <DotSeparator />
      {email || '-'}
    </ChatUserDataStyled>
  ) : (
    t('not_provided')
  );
};

export default memo(ChatUserData);
