import React, { FC, memo } from 'react';

import { ChatListTypes } from 'utils/api';

import { ChatTabStyled } from './styles';

interface Props {
  value: ChatListTypes;
  text: string;
  listFilter: ChatListTypes;
  onClick: (filter: ChatListTypes) => void;
}

const ChatTab: FC<Props> = ({ value, text, listFilter, onClick }) => (
  <ChatTabStyled $isActive={listFilter === value} onClick={() => onClick(value)}>
    {text}
  </ChatTabStyled>
);

export default memo(ChatTab);
