/* eslint-disable no-magic-numbers */
import styled from 'styled-components';

import { calcSize } from 'styles/calcSize';

export const AccessPageHeaderStyled = styled.header`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  justify-content: center;
  align-items: flex-start;

  h1 {
    font-weight: 700;
    font-size: ${calcSize(22)};
    line-height: 1.65;
    letter-spacing: ${calcSize(0.4)};
    color: var(--access-page-card-txt);
    text-align: center;
  }
`;

export const AccessPageLogoStyled = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    max-width: 136px;
    max-height: 31px;
    object-fit: contain;
  }
`;

export const AccessPageImageStyled = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    max-width: 234px;
    max-height: 198px;
    object-fit: contain;
  }
`;
