import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';
import { CHAT_META_WIDTH } from 'pages/Chat/styles';

export const Wrapper = styled.section`
  position: relative;
  flex: 0 0 ${CHAT_META_WIDTH};
  padding: 16px 24px;
  height: 100%;
  border-left: 1px solid var(--criteria-border);
  overflow-y: auto;
`;

export const DateRow = styled(FlexDiv)`
  flex: 1;
  margin: 0 -8px;
  padding-bottom: 20px;
`;

export const DateCell = styled.div`
  padding: 0 8px;
  width: 50%;
`;

export const Separator = styled.div`
  margin-bottom: 15px;
  width: 100%;
  height: 1px;
  background-color: var(--criteria-border);
`;
