import { assertUnreachable } from 'utils/misc';
import { PostUserLoginResponse } from 'utils/api/users';

import { ActionTypes } from './types';
import { Violence } from 'utils/api';
import { NotificationType, notificationTypes } from 'utils/constants';

export interface NotificationsTypes {
  type: NotificationType;
  code?: string;
}

export interface NotificationsListTypes {
  id: number;
  notification: NotificationsTypes;
}

export interface SchoolsList {
  id: number;
  name: string;
}

export interface State {
  isLoggedIn: boolean;
  profile: PostUserLoginResponse | null;
  violenceTypes: Violence[];
  notifications: NotificationsListTypes[];
  schools: SchoolsList[];
}

export type Action =
  | {
      type: ActionTypes.LOGIN;
      payload: PostUserLoginResponse;
    }
  | { type: ActionTypes.LOGOUT }
  | {
      type: ActionTypes.GET_VIOLENCE_TYPES;
      payload: Violence[];
    }
  | {
      type: ActionTypes.SET_NOTIFICATION_CODE;
      payload?: NotificationsTypes;
    }
  | {
      type: ActionTypes.GET_SCHOOLS;
      payload: SchoolsList[];
    }
  | {
      type: ActionTypes.REMOVE_NOTIFICATION;
      payload: number;
    };

export const initialState = {
  isLoggedIn: false,
  profile: null,
  violenceTypes: [],
  notifications: [],
  schools: [],
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return { ...state, profile: action.payload, isLoggedIn: true };
    case ActionTypes.GET_VIOLENCE_TYPES:
      return { ...state, violenceTypes: action.payload };
    case ActionTypes.SET_NOTIFICATION_CODE:
      return {
        ...state,
        notifications: action.payload
          ? [
              ...state.notifications,
              {
                notification: action.payload,
                id: Date.now(),
              },
            ]
          : // eslint-disable-next-line no-magic-numbers
            state.notifications.slice(1),
      };
    case ActionTypes.LOGOUT:
      return {
        ...initialState,
        notifications: [
          ...state.notifications,
          {
            notification: { type: notificationTypes.success, code: 'LOGOUT' },
            id: Date.now(),
          },
        ],
      };
    case ActionTypes.GET_SCHOOLS:
      return { ...state, schools: action.payload };
    case ActionTypes.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload,
        ),
      };
    default:
      return assertUnreachable(action);
  }
};

export default reducer;
