import styled, { css } from 'styled-components';

import { ANIMATION, FONT_PRIMARY } from 'styles/variables';
import { focusVisibleStyles } from 'styles/commonStyles';
import Icon from 'components/Icons';

import { ctaColorsFunc } from './colors';
import { ctaShapesFunc } from './shapes';
import { ctaSizesFunc } from './sizes';
import { CtaColor, CtaShape, CtaSize, CtaVariant } from './types';
import { ctaVariantsFunc } from './variants';

export interface CtaStyledProps {
  $color?: CtaColor;
  $size?: CtaSize;
  $shape?: CtaShape;
  $variant?: CtaVariant;
  $isActive?: boolean;
}

export const isActiveStyles = css`
  cursor: default;
  pointer-events: none;
`;

export const ctaStyles = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  max-width: 100%;
  border: 1px solid transparent;
  font-family: ${FONT_PRIMARY};
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  user-select: none;
  vertical-align: middle;
  box-shadow: none;
  transition:
    background ${ANIMATION},
    border-color ${ANIMATION},
    color ${ANIMATION};

  &:hover,
  &:active {
    outline: none;
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
    opacity: 1;
  }

  &:focus-visible {
    ${focusVisibleStyles};
  }
`;

export const ctaModifyStyles = css<CtaStyledProps>`
  ${ctaColorsFunc};
  ${ctaSizesFunc};
  ${ctaShapesFunc};
  ${ctaVariantsFunc};
  ${({ $isActive }) => $isActive && isActiveStyles};
`;

export const CtaIcon = styled(Icon)`
  fill: currentColor;
`;

export const CtaLabelStyled = styled('span')`
  flex: 1;
  max-width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
