import React, { memo } from 'react';
import dayjs from 'dayjs';

import { ReporterDataEditingHistoryResponse } from 'utils/api/chats';

import Row from 'components/Row';

interface Props {
  reporterDataEditingHistory: ReporterDataEditingHistoryResponse;
}

const ReporterDataHistoryRow: React.FC<Props> = ({ reporterDataEditingHistory }) => {
  const formattedReporterChangeTime = dayjs(reporterDataEditingHistory.change_time).format(
    'YYYY-MM-DD HH:mm',
  );

  return (
    <Row
      title={`${reporterDataEditingHistory.name_and_last_name || '-'}`}
      labels={[
        `${formattedReporterChangeTime || '-'}`,
        `${reporterDataEditingHistory.name_and_last_name || '-'}`,
        `${reporterDataEditingHistory.email || '-'}`,
      ]}
      clickable={false}
    />
  );
};

export default memo(ReporterDataHistoryRow);
