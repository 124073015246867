import styled from 'styled-components';

export const UploadFileRowStyled = styled.div`
  display: grid;
  grid-template-columns: 320px auto;
  grid-column-gap: 24px;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const UploadInputStyled = styled.input`
  padding: 8px;
  width: 100%;
  max-width: 600px;
  height: 48px;
  background: var(--app-bg);
  border-radius: 4px;
  border: 1px solid var(--cta-primary-border);
  // TODO -> kwestia nazewnictwa kolorów
  &::file-selector-button {
    margin-right: 16px;
    padding: 8px 16px;
    border-radius: 20px;
    border: none;
    background: var(--cta-primary-bg);
    color: var(--cta-primary-txt--hover);
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }

  &::file-selector-button:hover {
    background: var(--cta-primary-bg--hover);
  }
`;

export const UploadActionStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  height: 48px;
`;
