import React, { FC, memo } from 'react';
import { ChatCaseNumberStyled } from './styles';

interface Props {
  number?: string;
}

const ChatCaseNumber: FC<Props> = ({ number }) =>
  number ? <ChatCaseNumberStyled>{number}</ChatCaseNumberStyled> : null;

export default memo(ChatCaseNumber);
