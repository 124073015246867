import React, { FC, memo } from 'react';

import Logo from 'images/signalink_logo.svg';
import Graph from 'images/signalink_graph.png';

import { AccessPageHeaderStyled, AccessPageImageStyled, AccessPageLogoStyled } from './styles';

interface Props {
  title?: string;
}

const AccessPageHeader: FC<Props> = ({ title }) => (
  <AccessPageHeaderStyled>
    <AccessPageLogoStyled>
      <img src={Logo} alt="Logo Signalink" />
    </AccessPageLogoStyled>
    <AccessPageImageStyled>
      <img src={Graph} alt="" />
    </AccessPageImageStyled>
    {title ? <h1>{title}</h1> : null}
  </AccessPageHeaderStyled>
);

export default memo(AccessPageHeader);
