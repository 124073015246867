import { LanguagesList } from 'utils/api/users';

const PL: LanguagesList = 'PL';
const EN: LanguagesList = 'EN';

export const parseLang = (lang: LanguagesList): string =>
  ({
    PL: 'pl',
    EN: 'en',
  })[lang];

export const languages = {
  pl: PL,
  en: EN,
};
