/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import ReactSelect from 'react-select';

import { api, getApiUrl, PaginatedList, ResponseError } from 'utils/api';
import { CityResponse, SchoolResponse, VoivodeshipResponse } from 'utils/api/schools';
import {
  ChatReportDetailsResponse,
  ReportDetailResponse,
  ReportGeneralResponse,
  UsersMgmntReportResponse,
} from 'utils/api/report';
import { userRoles } from 'utils/userRoles';
import usePrefix from 'utils/usePrefix';
import { useApp } from 'App';
import { notificationTypes } from 'utils/constants';
import { ActionTypes } from 'App/types';

import FormField from 'components/FormField';
import InputField from 'components/InputField';
import PageTitle from 'components/PageTitle';
import Button from 'components/_Redesign/Button';
import { customSelectStyles } from 'components/Select/styles';

import FetchingMessage from '../FetchingMessage';
import { DateCell, DateRow, Separator, Wrapper } from './styles';

const urlSchools = getApiUrl('/director/me/schools');
const urlSchoolsAdmin = getApiUrl('/schools');

const urlReports = getApiUrl('/director/me/reports');
const urlReportsAdmin = getApiUrl('/reports');

const validationSchema = (ty: (key: string, vars?: object) => string) =>
  Yup.object().shape({
    type: Yup.object().shape({
      value: Yup.string().required(ty('field_required')),
      label: Yup.string().required(ty('field_required')),
    }),
    locationTarget: Yup.object().shape({
      value: Yup.boolean().required(ty('field_required')),
      label: Yup.string().required(ty('field_required')),
    }),
    date_from: Yup.date()
      .min('2020-01-01', ty('reports_min_date_from'))
      .max(Yup.ref('date_to'), ty('reports_max_date_from'))
      .required(),
    date_to: Yup.date()
      .min(Yup.ref('date_from'), ty('reports_min_date_to'))
      .max(dayjs().endOf('day').format('YYYY-MM-DD'), ty('reports_max_date_to'))
      .required(),
  });

export type ReportType = 'violence' | 'licence' | 'users_mgmnt' | 'chats';
export type LocationType = 'school' | 'locality' | 'voivodeship';

interface TypeOption {
  value: ReportType;
  label: string;
}

interface LocationTypeOption {
  value: LocationType;
  label: string;
}

interface LocationTargetOption {
  value: boolean;
  label: string;
}

export type LocationSpecificTargetType = number | string | null;

interface LocationSpecificTargetOption {
  value: LocationSpecificTargetType;
  label: string;
}

interface FormValues {
  type: TypeOption;
  locationTarget: LocationTargetOption;
  locationTargetSpecific?: LocationSpecificTargetOption;
  date_from: string;
  date_to: string;
}

interface Props {
  setGeneralData: (data?: ReportGeneralResponse) => void;
  setDetailData: (data?: ReportDetailResponse[]) => void;
  setUsersMgmntData: (data?: UsersMgmntReportResponse) => void;
  setRegisterOfNotificationsData: (data?: ChatReportDetailsResponse[]) => void;
  setReportParams: (params: string) => void;
  setReportType: (params: ReportType | undefined) => void;
  setSchooldId: (schoolId: LocationSpecificTargetType | undefined) => void;
}

const Criteria: React.FC<Props> = ({
  setGeneralData,
  setDetailData,
  setUsersMgmntData,
  setRegisterOfNotificationsData,
  setReportParams,
  setReportType,
  setSchooldId,
}) => {
  const PAGE_START = 0;
  const PAGE_SIZE = 20;

  const [{ profile, schools }, dispatch] = useApp();

  const isDirector = profile?.role === userRoles.director;

  const t = usePrefix('Reports');
  const ty = usePrefix('YupErrors');

  const typeOptions: TypeOption[] = isDirector
    ? [
        { value: 'violence', label: t('violence_types') },
        { value: 'users_mgmnt', label: t('users_mgmnt') },
        { value: 'chats', label: t('register_of_notifications') },
      ]
    : [
        { value: 'violence', label: t('violence_types') },
        { value: 'licence', label: t('licence_use') },
        { value: 'users_mgmnt', label: t('users_mgmnt') },
        { value: 'chats', label: t('register_of_notifications') },
      ];

  const locationTypeOptions: LocationTypeOption[] = [
    { value: 'school', label: t('school') },
    { value: 'locality', label: t('locality') },
    { value: 'voivodeship', label: t('voivodeship') },
  ];

  const locationTargetOptions: LocationTargetOption[] = [
    { value: false, label: t('as_choosen') },
    { value: true, label: t('all') },
  ];

  const [locationFilter, setLocationFilter] = useState('');
  const [locationType, setLocationType] = useState<LocationTypeOption>(locationTypeOptions[0]);
  const [locationSpecificTargetOptions, setLocationSpecificTargetOptions] = useState<
    LocationSpecificTargetOption[]
  >([]);
  const [reportsFetching, setReportsFetching] = useState(false);

  const initialValues: FormValues = {
    type: typeOptions[0],
    locationTarget: locationTargetOptions[0],
    date_from: '2024-08-01',
    date_to: dayjs().format('YYYY-MM-DD'),
  };

  const getSchools = async () => {
    const postfix = locationFilter ? `?name=${locationFilter}` : '';
    if (!postfix) {
      if (schools?.length) {
        setLocationSpecificTargetOptions(
          schools.map((school) => ({ value: school.id, label: school.name })),
        );
        return;
      }
    }

    try {
      const response: SchoolResponse[] = await api(`${urlSchools}${postfix}`, {
        method: 'GET',
      });

      if (response?.length)
        setLocationSpecificTargetOptions(
          response.map((school) => ({ value: school.id, label: school.name })),
        );
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const getAdminSchools = async () => {
    try {
      const response: PaginatedList<SchoolResponse[]> = await api(
        `${urlSchoolsAdmin}?page=${PAGE_START}&size=${PAGE_SIZE}&name=${locationFilter}`,
        {
          method: 'GET',
        },
      );

      if (response?.content.length)
        setLocationSpecificTargetOptions(
          response.content.map((school) => ({ value: school.id, label: school.name })),
        );
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const getCities = async () => {
    try {
      const response: PaginatedList<CityResponse[]> = await api(
        `${urlSchoolsAdmin}/cities?page=${PAGE_START}&size=${PAGE_SIZE}&pattern=${locationFilter}`,
        {
          method: 'GET',
        },
      );

      if (response?.content.length) {
        setLocationSpecificTargetOptions(
          response.content.map((city) => ({ value: city.city, label: city.city })),
        );
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const getVoivodeships = async () => {
    try {
      const response: VoivodeshipResponse[] = await api(`${urlSchoolsAdmin}/voivodeshipList`, {
        method: 'GET',
      });

      if (response.length)
        setLocationSpecificTargetOptions(
          response.map((voiv) => ({ value: voiv.voivodeship, label: voiv.voivodeship })),
        );
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  useEffect(() => {
    if (!!locationType && 'value' in locationType) {
      if (profile?.role === 'DIRECTOR') {
        if (!locationFilter || locationFilter.length > 2) getSchools();
        return;
      }

      switch (locationType.value) {
        case 'voivodeship':
          getVoivodeships();
          break;
        case 'locality':
          if (locationFilter.length > 2) getCities();
          break;
        case 'school':
          if (locationFilter.length > 2) getAdminSchools();
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationFilter, locationType]);

  const handleLocationTypeChange = (
    setFieldValue: (
      field: string,
      value: LocationTypeOption | null,
      shouldValidate?: boolean,
    ) => void,
    value: LocationTypeOption,
  ) => {
    setFieldValue('locationTargetSpecific', null);
    setLocationSpecificTargetOptions([]);
    setLocationFilter('');
    setLocationType(value);

    if (!!value && 'value' in value && value.value === 'voivodeship') getVoivodeships();
  };

  const getGeneralReport = async (url: string, reportType: ReportType, queryStrings: string) => {
    try {
      const response: ReportGeneralResponse = await api(`${url}/${reportType}${queryStrings}`, {
        method: 'GET',
      });

      if (response) {
        setGeneralData(response);
        setReportsFetching(false);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
      setReportsFetching(false);
    }
  };

  const getDetailReport = async (url: string, reportType: ReportType, queryStrings: string) => {
    try {
      const response: ReportDetailResponse[] = await api(`${url}/${reportType}${queryStrings}`, {
        method: 'GET',
      });

      if (response) {
        setDetailData(response);
        setReportsFetching(false);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
      setReportsFetching(false);
    }
  };

  const getUsersMgmntReport = async (url: string, reportType: ReportType, queryStrings: string) => {
    try {
      const response: UsersMgmntReportResponse = await api(`${url}/${reportType}${queryStrings}`, {
        method: 'GET',
      });

      if (response) {
        setUsersMgmntData(response);
        setReportsFetching(false);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
      setReportsFetching(false);
    }
  };

  const getRegisterOfNotificationsReport = async (
    url: string,
    reportType: ReportType,
    queryStrings: string,
  ) => {
    try {
      const response: ChatReportDetailsResponse[] = await api(
        `${url}/${reportType}${queryStrings}`,
        {
          method: 'GET',
        },
      );

      if (response) {
        setRegisterOfNotificationsData(response);
      }
      setReportsFetching(false);
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
      setReportsFetching(false);
    }
  };

  const submit = async (values: FormValues) => {
    if (
      (!values.locationTarget && !values.locationTargetSpecific) ||
      !locationType ||
      (locationType && !('value' in locationType)) ||
      (isDirector && values.locationTargetSpecific && !('value' in values.locationTargetSpecific))
    )
      return;

    setReportsFetching(true);
    const isChatsRequest = values.type.value === 'chats';

    const url = isDirector
      ? `${urlReports}${isChatsRequest ? '' : '/' + values.locationTargetSpecific?.value}`
      : urlReportsAdmin;
    const dateQueryString = `?date_from=${values.date_from}&date_to=${values.date_to}`;
    const locationQuery =
      isDirector || values.locationTarget.value
        ? ''
        : locationType.value === 'voivodeship'
        ? `&voivodeship=${values?.locationTargetSpecific?.value}`
        : locationType.value === 'locality'
        ? `&city=${values?.locationTargetSpecific?.value}`
        : `&schoolId=${values?.locationTargetSpecific?.value}`;

    if (isDirector) setSchooldId(values.locationTargetSpecific?.value);

    let queryStrings = `${dateQueryString}${locationQuery}`;

    if (isDirector && isChatsRequest) {
      queryStrings += `&schoolId=${values?.locationTargetSpecific?.value}`;
    }

    setReportParams(queryStrings);
    setReportType(values.type.value);

    if (values.type.value === 'violence') {
      getGeneralReport(url, values.type.value, queryStrings);
    } else if (values.type.value === 'licence') {
      getDetailReport(url, values.type.value, queryStrings);
    } else if (values.type.value === 'users_mgmnt') {
      getUsersMgmntReport(url, values.type.value, queryStrings);
    } else if (values.type.value === 'chats') {
      getRegisterOfNotificationsReport(url, values.type.value, queryStrings);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={() => validationSchema(ty)}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, touched, errors }) => (
        <Wrapper>
          <Form autoComplete="off">
            <PageTitle title={t('criteria')} paddingBottom="20px" />
            <ReactSelect
              onChange={(val) => setFieldValue('type', val)}
              options={typeOptions}
              value={values.type}
              isSearchable={false}
              styles={customSelectStyles}
            />
            {!isDirector && (
              <>
                {!values.locationTarget.value && (
                  <ReactSelect
                    onChange={(val) =>
                      handleLocationTypeChange(setFieldValue, val as LocationTypeOption)
                    }
                    options={locationTypeOptions}
                    value={locationType}
                    isSearchable={false}
                    styles={customSelectStyles}
                  />
                )}
                <ReactSelect
                  onChange={(val) => setFieldValue('locationTarget', val)}
                  options={locationTargetOptions}
                  value={values.locationTarget}
                  isSearchable={false}
                  styles={customSelectStyles}
                />
              </>
            )}
            {!values.locationTarget.value && (
              <ReactSelect
                onChange={(val) => setFieldValue('locationTargetSpecific', val)}
                options={locationSpecificTargetOptions}
                value={values.locationTargetSpecific}
                placeholder={
                  !!locationType && 'value' in locationType
                    ? locationType.value === 'locality'
                      ? t('choose_locality')
                      : locationType.value === 'school'
                      ? t('choose_school')
                      : t('choose_voivodeship')
                    : t('as_choosen')
                }
                isSearchable={true}
                styles={customSelectStyles}
                onInputChange={(newValue: string) => setLocationFilter(newValue)}
                noOptionsMessage={() => t('no_options_to_display')}
              />
            )}
            <Separator />
            <DateRow>
              <DateCell>
                <FormField label={t('from')} margin="0">
                  <InputField
                    name="date_from"
                    error={touched.date_to && !!errors.date_to}
                    autoFocus
                    type="date"
                    dimension="lg"
                  />
                </FormField>
              </DateCell>
              <DateCell>
                <FormField label={t('to')} margin="0">
                  <InputField
                    name="date_to"
                    error={touched.date_to && !!errors.date_to}
                    autoFocus
                    type="date"
                    dimension="lg"
                  />
                </FormField>
              </DateCell>
            </DateRow>
            <Separator />
            <Button
              shape="full"
              color="primary"
              size="lg"
              label={t('generate_report')}
              type="submit"
              isDisabled={
                !!Object.keys(errors).length ||
                (!values.locationTarget.value && !values.locationTargetSpecific?.value) ||
                reportsFetching
              }
            />
          </Form>
          {reportsFetching ? <FetchingMessage /> : null}
        </Wrapper>
      )}
    </Formik>
  );
};

export default Criteria;
