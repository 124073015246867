import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import InfiniteScroll from 'react-infinite-scroller';
import { Form } from 'formik';

import { ANIMATION } from 'styles/variables';
import { screenSmAbove } from 'styles/breakpoinst';

import { TEXT_AREA_HEIGHT_CSS } from 'components/TextArea/constants';
import FlexDiv from 'components/FlexDiv';

import {
  IS_INTERVENT_MESSAGE_CLASS,
  IS_SYSTEM_MESSAGE_CLASS,
  IS_USER_MESSAGE_CLASS,
} from './constants';

const chatNotesOpenStyles = css`
  padding-right: 0;
`;

export const Wrapper = styled(FlexDiv)<{ chatPadding: boolean }>`
  padding: 0;
  width: 100%;
  ${({ chatPadding }) => chatPadding && chatNotesOpenStyles};

  @media screen and (min-width: ${screenSmAbove}) {
    border-right: 1px solid var(--list-border);
  }
`;

export const MessagesPanel = styled(FlexDiv)`
  width: 100%;
  overflow: hidden;
`;

export const MessageWrapper = styled(FlexDiv)`
  margin-bottom: 16px;
  width: 100%;
`;

export const MessageBottom = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

export const MessageDate = styled.div`
  padding-top: 2px;

  .${IS_USER_MESSAGE_CLASS} & {
    color: var(--chat-msg-txt--user--secondary);
  }

  .${IS_INTERVENT_MESSAGE_CLASS} & {
    color: var(--chat-msg-txt--intervent--secondary);
  }
`;

export const MessagePatchByTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 4px;
  color: var(--chat-msg-txt--secondary);
`;

const isUserMessageStyles = css`
  border-color: var(--chat-msg-border--user);
  background: var(--chat-msg-bg--user);
  color: var(--chat-msg-txt--user--primary);
`;

const isInterventMessageStyles = css`
  border-color: var(--chat-msg-border--intervent);
  background: var(--chat-msg-bg--intervent);
  color: var(--chat-msg-txt--intervent--primary);
`;

export const MessageContainer = styled.div`
  padding: 8px 16px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
  box-shadow: 0 6px 20px 0 var(--chat-msg-shadow);

  .${IS_USER_MESSAGE_CLASS} & {
    ${isUserMessageStyles};
  }

  .${IS_INTERVENT_MESSAGE_CLASS} & {
    ${isInterventMessageStyles};
  }
`;

export const MessageData = styled.div`
  width: 100%;
  max-width: 55%;

  .${IS_SYSTEM_MESSAGE_CLASS} & {
    max-width: none;
  }
`;

export const PatchHistory = styled.div`
  padding-top: 5px;
  font-weight: 500;
  font-size: 12px;
  color: var(--chat-msg-action-txt);
`;

export const MessageAuthor = styled.div`
  margin-bottom: 2px;
  width: 100%;
  min-width: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  .${IS_USER_MESSAGE_CLASS} & {
    color: var(--chat-msg-author-txt--user);
    text-align: left;
  }

  .${IS_INTERVENT_MESSAGE_CLASS} & {
    color: var(--chat-msg-author-txt--intervent);
    text-align: right;
  }
`;
export const MessageIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
`;

export const MessageIcon = styled.div`
  margin-left: auto;
  padding-left: 5px;
  border: none;
  cursor: pointer;

  path {
    transition: fill ${ANIMATION};

    .${IS_USER_MESSAGE_CLASS} & {
      fill: var(--chat-msg-action-txt--user);
    }

    .${IS_INTERVENT_MESSAGE_CLASS} & {
      fill: var(--chat-msg-action-txt--intervent);
    }
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;

    path {
      .${IS_USER_MESSAGE_CLASS} & {
        fill: var(--chat-msg-action-txt--user--disabled);
      }

      .${IS_INTERVENT_MESSAGE_CLASS} & {
        fill: var(--chat-msg-action-txt--intervent--disabled);
      }
    }
  }

  &:hover {
    path {
      .${IS_USER_MESSAGE_CLASS} & {
        fill: var(--chat-msg-action-txt--user--hover);
      }

      .${IS_INTERVENT_MESSAGE_CLASS} & {
        fill: var(--chat-msg-action-txt--intervent--hover);
      }
    }
  }

  &:focus {
    outline: none;
  }
`;

export const MessagePatchForm = styled(Form)`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  background: var(--chat-msg-form-bg);
`;

export const MessagePatchControls = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PanelWrapper = styled.div`
  padding: 8px 24px;
  width: 100%;
  height: calc(100% - ${TEXT_AREA_HEIGHT_CSS});
  overflow: auto;
`;

export const MessageFormStyled = styled.div`
  padding: 16px 24px;
  width: 100%;
`;

export const FormStyled = styled(Form)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
  background: var(--chat-msg-form-bg);
`;

export const StyledScroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column-reverse;
`;

export const NicknameInputContainer = styled.div`
  width: 200px;
`;

export const NicknameContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const ChatActionsStyled = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  column-gap: 16px;
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 180px;
  object-fit: cover;
`;

export const AttachmentLink = styled.a`
  font-size: 13px;
  color: var(--chat-msg-link-txt);
`;

interface ImageProps {
  src: string;
  mimeType: string;
  target: string;
  alt: string;
}

export const AttachmentImage: FC<ImageProps> = ({ src, mimeType, target, alt }) => (
  <a href={src} type={mimeType} target={target}>
    <StyledImage src={src} alt={alt} />
  </a>
);

export const DateRow = styled(FlexDiv)`
  flex: 1;
  margin: 0 -8px;
  padding-bottom: 20px;
`;

export const DateCell = styled.div`
  padding: 0 8px;
  width: 100%;
`;

export const AttachmentAudio = styled.audio`
  width: 250px;
`;

export const AttachmentVideo = styled.video``;

export const AttachmentMediaNotSupported = styled.p``;

export const AttachmentMediaLink = styled.a``;
