import React from 'react';
import dayjs from 'dayjs';

import { ChatMessagePatchInfoResponse } from 'utils/api/chats';
import usePrefix from 'utils/usePrefix';

import { PatchHistory } from './styles';

const MessagePatchHistory: React.FC<{
  data: ChatMessagePatchInfoResponse[];
}> = ({ data }) => {
  const t = usePrefix('Chat');

  return (
    // eslint-disable-next-line no-magic-numbers
    data.length > 0 ? (
      <PatchHistory>
        <div>{t('message_patch_history')}</div>
        {data.map((info) => (
          <div key={info.id}>
            {dayjs(info.patch_time).format('DD-MM-YYYY') + ' ' + info.patch_by}
          </div>
        ))}
      </PatchHistory>
    ) : null
  );
};

export default MessagePatchHistory;
