import React, { useLayoutEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { ChatMessagePatchInfoResponse, ChatMessageResponse } from 'utils/api/chats';

import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { api, getApiUrl, ResponseError } from 'utils/api';
import { notificationTypes } from 'utils/constants';
import usePrefix from 'utils/usePrefix';
import Icon from 'components/Icons';

import MessageAttachment from './MessageAttachment';
import { MessageValue } from './Message';
import MessagePatchHistory from './MessagePatchHistory';
import {
  MessageBottom,
  MessageDate,
  MessageIcon,
  MessageIconsContainer,
  MessagePatchByTitle,
} from './styles';

const url = getApiUrl('/interveners/me/chats');

const MessageView: React.FC<{
  chatId: number;
  msg: ChatMessageResponse;
  setMessageValue: (set: MessageValue) => void;
}> = ({ chatId, msg, setMessageValue }) => {
  const { message, patch_by, attachment } = msg;
  const formattedDate = dayjs(msg.creation_time).format('H:mm DD.MM.YYYY');
  const patch_time = msg.patch_time && dayjs(msg.patch_time).format('YYYY-MM-DD HH:mm');
  const t = usePrefix('Chat');
  const [, dispatch] = useApp();
  const [data, setData] = useState<ChatMessagePatchInfoResponse[]>([]);
  // eslint-disable-next-line no-magic-numbers
  const [height, setHeight] = useState(100);
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight) {
      const height = ref.current.clientHeight;
      setHeight(height);
    }
  }, []);

  /* TODO -> sprawdzić, czy faktycznie do usunięcia */
  const fetchData = async () => {
    try {
      const response: ChatMessagePatchInfoResponse[] = await api(
        `${url}/${chatId}/messages/${msg.id}/patch-info`,
        {
          method: 'GET',
        },
      );

      if (response) {
        setData(response);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return attachment ? (
    <MessageAttachment attachment={attachment} />
  ) : (
    <div ref={ref}>
      {patch_time && patch_by && (
        <MessagePatchByTitle>
          {t('message_patch_by_title', { patch_time, patch_by })}
        </MessagePatchByTitle>
      )}
      <div>{message}</div>
      <MessageBottom>
        <MessageDate>{formattedDate}</MessageDate>
        <MessageIconsContainer>
          <MessageIcon onClick={() => setMessageValue({ editMode: true, msg, height })}>
            <Icon type="pencil" width="16" height="16" />
          </MessageIcon>
          {/*<MessageIcon onClick={() => fetchData()}>*/}
          {/*  <Icon type="info" width="16" height="16" />*/}
          {/*</MessageIcon>*/}
        </MessageIconsContainer>
      </MessageBottom>
      <MessagePatchHistory data={data} />
    </div>
  );
};
export default MessageView;
