import React, { FC, memo, MouseEvent, useMemo } from 'react';

import { DataRowStyled } from './styles';
import { IS_DISABLED_ROW_CLASS, IS_SELECTED_ROW_CLASS, IS_UNREAD_ROW_CLASS } from './constants';

interface DataRowProps {
  children?: React.ReactNode;
  title?: string;
  clickable?: boolean;
  onClick?: (event: MouseEvent<HTMLLIElement>) => void;
  selected?: boolean;
  disabled?: boolean;
  unread?: boolean;
}

const DataRow: FC<DataRowProps> = ({
  children,
  clickable = false,
  onClick,
  title,
  selected = false,
  disabled = false,
  unread = false,
  ...props
}) => {
  const extraClasses = useMemo(() => {
    let classes = '';

    if (selected) {
      classes = `${classes} ${IS_SELECTED_ROW_CLASS}`;
    }

    if (unread) {
      classes = `${classes} ${IS_UNREAD_ROW_CLASS}`;
    }

    if (disabled) {
      classes = `${classes} ${IS_DISABLED_ROW_CLASS}`;
    }

    return classes;
  }, [selected, unread, disabled]);

  return (
    <DataRowStyled
      className={extraClasses}
      clickable={!disabled && clickable}
      title={title}
      onClick={onClick}
      {...props}
    >
      {children && children}
    </DataRowStyled>
  );
};

export default memo(DataRow);
