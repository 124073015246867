import React, { memo } from 'react';

import usePrefix from 'utils/usePrefix';

import Modal from 'components/_Redesign/Modal';
import Button from 'components/_Redesign/Button';
import { MODAL_ICON_SIZE_CSS } from 'components/_Redesign/Modal/constants';
import {
  ModalActionsStyled,
  ModalHeaderStyled,
  ModalBodyStyled,
  ModalIcon,
} from 'components/_Redesign/Modal/styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => void;
}

const ChatRemovalModal: React.FC<Props> = ({ onClose, isOpen, onRemove }) => {
  const t = usePrefix('Chat');
  const tg = usePrefix('General');

  return isOpen ? (
    <Modal onClose={onClose} align="center" size="sm">
      <ModalHeaderStyled>
        <ModalIcon
          type="trash"
          width={MODAL_ICON_SIZE_CSS}
          height={MODAL_ICON_SIZE_CSS}
          $state="error"
        />
        <h3>{t('chat_delete')}</h3>
      </ModalHeaderStyled>
      <ModalBodyStyled>
        <p>{t('chat_delete_description')}</p>
      </ModalBodyStyled>
      <ModalActionsStyled>
        <Button onClick={onClose} color="secondary" size="lg" label={tg('cancel')} />
        <Button onClick={onRemove} color="error" size="lg" label={tg('delete')} />
      </ModalActionsStyled>
    </Modal>
  ) : null;
};

export default memo(ChatRemovalModal);
