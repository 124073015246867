import React, { memo, useMemo } from 'react';

import TruncatedText from 'components/TruncatedText';

import Dot from './Dot';
import {
  RowShortStyled,
  RowShortContentStyled,
  RowShortTitleStyled,
  RowShortDescStyled,
  RowShortTopStyled,
  RowShortTopDataStyled,
  RowShortAdditionalInfoStyled,
} from './styles';
import { IS_SELECTED_MESSAGE_CLASS, IS_UNREAD_MESSAGE_CLASS } from './constants';

interface Props {
  title: string;
  additionalInfo?: string | null;
  subtitle?: string | null;
  selected?: boolean;
  disabled?: boolean;
  unread?: boolean;
  clickable?: boolean;
  violenceType?: string;
  onClick?: () => void;
  style?: object;
}

const RowShort: React.FC<Props> = ({
  title,
  additionalInfo,
  subtitle,
  onClick,
  selected = false,
  disabled = false,
  unread = false,
  clickable = true,
  style,
}) => {
  const extraClasses = useMemo(() => {
    let classes = '';

    if (selected) {
      classes = `${classes} ${IS_SELECTED_MESSAGE_CLASS}`;
    }

    if (unread) {
      classes = `${classes} ${IS_UNREAD_MESSAGE_CLASS}`;
    }

    return classes;
  }, [selected, unread]);

  return (
    <RowShortStyled style={style}>
      <RowShortContentStyled
        onClick={() => !disabled && !!onClick && onClick()}
        className={extraClasses}
        clickable={clickable}
        disabled={disabled}
      >
        <RowShortTopStyled>
          {selected ? <Dot /> : null}
          <RowShortTopDataStyled>
            <RowShortTitleStyled title={title}>{title}</RowShortTitleStyled>
            {additionalInfo && (
              <RowShortAdditionalInfoStyled title={additionalInfo}>
                <TruncatedText maxWidth="100%" text={additionalInfo} />
              </RowShortAdditionalInfoStyled>
            )}
          </RowShortTopDataStyled>
        </RowShortTopStyled>
        <RowShortDescStyled>{subtitle}</RowShortDescStyled>
      </RowShortContentStyled>
    </RowShortStyled>
  );
};

export default memo(RowShort);
