import styled from 'styled-components';

export const TableStyled = styled.table`
  table-layout: fixed;
  margin-top: 10px;
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeaderStyled = styled.th`
  border: 1px solid var(--data-item-border);
  padding: 8px;
  text-align: left;
  background-color: var(--data-item-bg);
  color: var(--data-item-txt);
`;

export const TableRowStyled = styled.tr``;

export const TableDataStyled = styled.td`
  border: 1px solid var(--data-item-border);
  padding: 8px;
`;
