/* eslint-disable no-magic-numbers */
import { css } from 'styled-components';

import { calcSize } from 'styles/calcSize';

import { CtaStyledProps } from './styles';
import { CtaSize } from './types';

export const sizeSm = css`
  padding: 0 4px;
  height: 16px;
  border-radius: 8px;
  font-weight: 400;
  font-size: ${calcSize(12)};
  line-height: 1;
`;

export const sizeMd = css`
  padding: 5px 12px;
  height: 32px;
  border-radius: 16px;
  font-weight: 600;
  font-size: ${calcSize(14)};
  line-height: 1.43;
`;

export const sizeLg = css`
  padding: 11px 24px;
  height: 48px;
  border-radius: 24px;
  font-weight: 600;
  font-size: ${calcSize(16)};
  line-height: 1.5;
`;

export const ctaSizes: Record<CtaSize, any> = {
  sm: sizeSm,
  md: sizeMd,
  lg: sizeLg,
};

export const ctaSizesFunc = ({ $size }: { $size?: CtaStyledProps['$size'] }) =>
  $size ? ctaSizes[$size] : '';
