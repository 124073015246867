import React from 'react';

import { IconName } from 'components/Icons';
import Button from 'components/_Redesign/Button';

import { SectionWrapper, Title, Content, PlusContainer } from './styles';

export const ICON_HEIGHT = '16px';

interface Props {
  children?: React.ReactNode;
  title: string;
  onClick?: () => void;
  iconType?: IconName;
  absolute?: boolean;
}

const MetaSection: React.FC<Props> = ({ title, onClick, iconType, children, absolute }) => (
  <SectionWrapper absolute={absolute}>
    <Content absolute={absolute}>
      <Title alignItems="center" justifyContent="space-between">
        {title}
        {!!iconType && (
          <PlusContainer>
            <Button
              icon={iconType}
              onClick={onClick}
              size="md"
              color="text-primary"
              variant="text"
            />
          </PlusContainer>
        )}
      </Title>
      {children}
    </Content>
  </SectionWrapper>
);

export default MetaSection;
