import React, { useEffect, useState } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { useApp } from 'App';
import { SchoolsList } from 'App/reducer';
import { ActionTypes } from 'App/types';
import {
  ADVICES_URL,
  ASSIGNMENTS_URL,
  CHAT_URL,
  INSTRUCTIONS_URL,
  NOTICES_URL,
  PROFILE_URL,
  REPORTS_URL,
  RESET_PASSWORD_URL,
  SCHOOLS_URL,
  SET_PASSWORD_URL,
  USERS_URL,
} from 'App/constants';

import { notificationTypes } from 'utils/constants';
import { userRoles } from 'utils/userRoles';
import { api, getApiUrl, Violence, ResponseError } from 'utils/api';
import { SchoolResponse } from 'utils/api/schools';
import { BrandingColorScheme } from 'utils/brandingColorScheme';

import PrivateRoute from 'containers/PrivateRoute';

import RetrievePassword from 'pages/RetrievePassword';
import ResetPassword from 'pages/ResetPassword';
import Login from 'pages/Login';
import Users from 'pages/Users';
import Profile from 'pages/Profile';
import Schools from 'pages/Schools';
import Chat from 'pages/Chat';
import Notices from 'pages/Notices';
import Advices from 'pages/Advices';
import Instructions from 'pages/Instructions';
import Reports from 'pages/Reports';
import Assignments from 'pages/Assignments';

import Notifications from 'components/Notifications';
import { LayoutStyled } from 'components/Layout/styles';
import Navigation from 'components/Navigation';
import LoadingState from 'components/LoadingState';
import { THEME } from 'styles/theme';

const url = getApiUrl('/violence-types');
const urlDirectorOrganizations = getApiUrl('/director/me/schools');
const urlInterventOrganizations = getApiUrl('/interveners/me/organizations');

const HomeApp: React.FC = () => {
  const [{ profile, notifications }, dispatch] = useApp();
  const [isLoading, setIsLoading] = useState(false);
  const [logoUri, setLogoUri] = useState<string | undefined>(undefined);

  const isAdmin = profile?.role === userRoles.admin;
  const isDirector = profile?.role === userRoles.director;

  const loadViolenceTypes = async () => {
    try {
      const response: Violence[] = await api(url, {
        method: 'GET',
      });
      if (response) {
        dispatch({ type: ActionTypes.GET_VIOLENCE_TYPES, payload: response });
      }
    } catch (error) {
      setIsLoading(false);
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const getSchools = async () => {
    if (isAdmin) {
      setDefaultBranding();
      return;
    }
    const isDirector = profile?.role === userRoles.director;
    try {
      const response: SchoolResponse[] = await api(
        `${isDirector ? urlDirectorOrganizations : urlInterventOrganizations}`,
        {
          method: 'GET',
        },
      );

      const schools: SchoolsList[] = response.map((item) => ({
        id: item.id,
        name: item.name,
      }));

      if (schools.length === 1) {
        const schoolId = schools[0].id;
        loadBranding(schoolId);
        handleLogoDownload(schoolId);
      } else {
        setDefaultBranding();
      }

      if (schools)
        dispatch({
          type: ActionTypes.GET_SCHOOLS,
          payload: schools,
        });
    } catch (error) {
      setIsLoading(false);
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const loadBranding = async (schoolId: number) => {
    const url = isDirector
      ? getApiUrl('/director/me/www-branding/company/')
      : getApiUrl('/interveners/me/www-branding/company/');

    try {
      const response = await fetch(`${url}${schoolId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onload = (event) => {
        const result = event.target?.result;
        if (typeof result === 'string') {
          const jsonContent = JSON.parse(result);
          applyColors(jsonContent);
        }
      };
      reader.readAsText(blob);
    } catch (error) {
      setIsLoading(false);
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const handleLogoDownload = async (schoolId: number) => {
    const url = isDirector
      ? getApiUrl('/director/me/www-branding/company/')
      : getApiUrl('/interveners/me/www-branding/company/');

    try {
      const response = await fetch(`${url}${schoolId}/logo`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch logo.');
      }

      // eslint-disable-next-line no-magic-numbers
      if (response.status >= 200 && response.status < 300) {
        const jsonData = await response.json();
        const { uri } = jsonData.branding_logo;
        setLogoUri(uri);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const applyColors = (colors: BrandingColorScheme) => {
    Object.entries(colors).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value);
    });
    setIsLoading(false);
  };

  const setDefaultBranding = () => {
    Object.entries(THEME).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    setLogoUri(undefined);
    if (profile) {
      setIsLoading(true);
      loadViolenceTypes();
      getSchools();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return isLoading ? (
    <LoadingState />
  ) : (
    <>
      <LayoutStyled $isLogged={!!profile}>
        {!!profile && <Navigation logoUrl={logoUri} />}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path={`/${SET_PASSWORD_URL}`} element={<RetrievePassword />} />
          <Route path={`/${RESET_PASSWORD_URL}`} element={<ResetPassword />} />
          <Route
            path={`/${REPORTS_URL}`}
            element={
              <PrivateRoute
                Component={Reports}
                requiredRole={[userRoles.admin, userRoles.director]}
              />
            }
          />
          <Route
            path={`/${CHAT_URL}`}
            element={
              <PrivateRoute
                Component={Chat}
                requiredRole={[userRoles.intervener, userRoles.director]}
              />
            }
          />
          <Route
            path={`/${USERS_URL}`}
            element={<PrivateRoute Component={Users} requiredRole={[userRoles.admin]} />}
          />
          <Route
            path={`/${SCHOOLS_URL}`}
            element={<PrivateRoute Component={Schools} requiredRole={[userRoles.admin]} />}
          />
          <Route path={`${PROFILE_URL}`} element={<PrivateRoute Component={Profile} />} />
          <Route
            path={`/${NOTICES_URL}`}
            element={<PrivateRoute Component={Notices} requiredRole={[userRoles.admin]} />}
          />
          <Route
            path={`${ADVICES_URL}`}
            element={
              <PrivateRoute
                Component={Advices}
                requiredRole={[userRoles.intervener, userRoles.director]}
              />
            }
          />
          <Route
            path={`/${INSTRUCTIONS_URL}`}
            element={
              <PrivateRoute
                Component={Instructions}
                requiredRole={[userRoles.intervener, userRoles.director]}
              />
            }
          />
          <Route
            path={`/${ASSIGNMENTS_URL}`}
            element={<PrivateRoute Component={Assignments} requiredRole={[userRoles.director]} />}
          />
          <Route path="*" element={<Navigate to="/app" />} />
        </Routes>
      </LayoutStyled>
      {!!notifications.length && <Notifications notifications={notifications} />}
    </>
  );
};

export default HomeApp;
