import React, { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Prompt from 'components/Prompt';
import usePrefix from 'utils/usePrefix';
import { SET_PASSWORD_URL } from 'App/constants';

const InvalidTokenPrompt: FC = () => {
  const t = usePrefix('Login');
  const navigate = useNavigate();

  const onInvalidToken = useCallback(() => {
    navigate(`/app/${SET_PASSWORD_URL}`);
  }, [navigate]);

  return <Prompt text={t('invalid_token')} onClick={onInvalidToken} label={t('send_link_again')} />;
};

export default memo(InvalidTokenPrompt);
