import styled, { css } from 'styled-components';

import { INPUT_PADDING_INLINE_CSS } from './constants';
import { InputProps } from '.';

export type inputDimension = 'lg' | 'md' | 'sm';

const inputDimension = (dimension: inputDimension) =>
  ({
    lg: css`
      height: 48px;
      padding: 8px ${INPUT_PADDING_INLINE_CSS};
    `,
    md: css`
      height: 40px;
      padding: 8px ${INPUT_PADDING_INLINE_CSS};
    `,
    sm: css`
      height: 32px;
      padding: 4px ${INPUT_PADDING_INLINE_CSS};
    `,
  })[dimension];

const isButtonStyles = css`
  padding-right: 48px;
`;

const placeholderStyles = css`
  color: var(--form-field-txt--placeholder);
  opacity: 1;
`;

export const StyledInput = styled.input<
  InputProps & { textarea?: boolean } & { dimension: inputDimension }
>`
  padding: 0 ${INPUT_PADDING_INLINE_CSS};
  border: 1px solid
    ${({ error }) => (error ? 'var(--form-field-border--error)' : 'var(--form-field-border)')};
  border-radius: 4px;
  width: 100%;
  background: var(--form-field-bg);
  color: var(--form-field-txt);
  outline: none;
  box-shadow: 0 6px 20px 0 var(--form-field-shadow);
  transition: border-color 100ms linear;

  &:focus-visible {
    border-color: var(--form-field-border--focus);
  }

  ${({ dimension }) => inputDimension(dimension)};
  ${({ isButton }) => isButton && isButtonStyles};

  ::placeholder {
    ${placeholderStyles};
  }

  ::-webkit-input-placeholder {
    ${placeholderStyles};
  }

  :-ms-input-placeholder {
    ${placeholderStyles};
  }

  ::-webkit-clear-button,
  ::-ms-clear {
    display: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }

  ${({ textarea }) => textarea && 'height: auto'};
`;
