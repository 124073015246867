/* eslint-disable no-magic-numbers */
import styled from 'styled-components';

import { calcSize } from 'styles/calcSize';
import { EMPTY_STATE_MEDIA_HEIGHT_CSS } from './constants';

export const EmptyStateStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${EMPTY_STATE_MEDIA_HEIGHT_CSS} 1fr;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 32px;
  width: 100%;
  background: var(--status-page-bg);
  text-align: center;

  h3 {
    font-weight: 600;
    font-size: ${calcSize(14)};
    color: var(--status-page-txt--primary);
  }

  p {
    font-weight: 400;
    font-size: ${calcSize(14)};
    color: var(--status-page-txt--secondary);
  }
`;

export const EmptyStateMediaStyled = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${EMPTY_STATE_MEDIA_HEIGHT_CSS};

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
