import styled from 'styled-components';
import { Form } from 'formik';

import FlexDiv from 'components/FlexDiv';
import FormField from 'components/FormField';

export const Wrapper = styled(FlexDiv)`
  grid-area: main;
`;

export const ProfileStyled = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex: 1;
  margin: 0;
  height: 100%;
`;

export const ProfileTitle = styled.div`
  flex: 0 0 192px;
  padding: 24px 0 24px 24px;
`;

export const Content = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  flex: 1;
  padding: 24px 24px 24px 0;
`;

export const InputsContainer = styled.div`
  padding: 0 16px;
  width: 100%;
  max-width: 320px;
`;

export const StyledFormField = styled(FormField)`
  max-width: 320px;
  margin-bottom: 24px;
`;
