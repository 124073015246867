import { css } from 'styled-components';

import { CtaStyledProps } from './styles';
import { CtaShape, CtaSize } from './types';

export const equalSizeStyles = css<{ $size?: CtaSize }>`
  justify-content: center;
  min-width: auto;
  ${({ $size }) => $size === 'sm' && sizeEqualSm};
  ${({ $size }) => $size === 'md' && sizeEqualMd};
  ${({ $size }) => $size === 'lg' && sizeEqualLg};
`;

export const sizeEqualSm = css`
  // TODO
`;

export const sizeEqualMd = css`
  padding: 4px;
  width: 32px;
`;

export const sizeEqualLg = css`
  padding: 4px;
  width: 48px;
`;

export const ctaShapes: Record<CtaShape, any> = {
  full: css`
    justify-content: center;
    width: 100%;
  `,
  square: css`
    ${equalSizeStyles};
  `,
  circle: css`
    ${equalSizeStyles};
    border-radius: 50%;
  `,
};

export const ctaShapesFunc = ({ $shape }: { $shape?: CtaStyledProps['$shape'] }) =>
  $shape ? ctaShapes[$shape] : '';
