import { useEffect, useState } from 'react';

export const useBrowserStorage = <T>(
  key: string,
  initialValue?: T,
  raw?: boolean,
  storage: Storage = sessionStorage,
): [T, (value: T) => void] => {
  const [state, setState] = useState<T>(() => {
    try {
      const storageValue = storage.getItem(key);
      if (typeof storageValue !== 'string') {
        storage.setItem(key, raw ? String(initialValue) : JSON.stringify(initialValue));
        return initialValue;
      } else {
        return raw ? storageValue : JSON.parse(storageValue || 'null');
      }
    } catch (error) {
      // If user is in private mode or has storage restriction
      // storage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      const serializedState = raw ? String(state) : JSON.stringify(state);
      storage.setItem(key, serializedState);
    } catch (error) {
      // If user is in private mode or has storage restriction
      // storage can throw. Also JSON.stringify can throw.
    }
  });

  return [state, setState];
};

export default useBrowserStorage;
