import React, { memo } from 'react';

import { ChatAdditionalDataStyled, ChatUserHistoryStyled } from './styles';
import ChatCaseNumber from './ChatCaseNumber';
import ChatUserData from './ChatUserData';

const ChatAdditionalData: React.FC<{
  reporterNameAndLastName?: string;
  reporterEmail?: string;
  reporterCreationTime?: string;
  chatCaseNumber?: string;
}> = ({ reporterNameAndLastName, reporterEmail, reporterCreationTime, chatCaseNumber }) => (
  <ChatAdditionalDataStyled>
    <ChatUserHistoryStyled>
      <ChatUserData
        name={reporterNameAndLastName}
        email={reporterEmail}
        creationTime={reporterCreationTime}
      />
    </ChatUserHistoryStyled>
    <ChatCaseNumber number={chatCaseNumber} />
  </ChatAdditionalDataStyled>
);

export default memo(ChatAdditionalData);
