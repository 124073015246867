import React from 'react';

import { LoaderStyled } from './styles';

interface LoaderProps {
  label?: string;
}

const Loader: React.FC<LoaderProps> = ({ label }) => (
  <LoaderStyled alignItems="center" justifyContent="center">
    {label}
  </LoaderStyled>
);

export default Loader;
