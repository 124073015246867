import React from 'react';
import styled, { css } from 'styled-components';

import Icon from 'components/Icons';

export const RequirementsListStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: inherit;

  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    color: inherit;
  }
`;

export interface RequirementProps {
  children?: React.ReactNode;
  touched?: boolean;
  error: boolean;
}

export const RequirementItemStyled = styled.div<RequirementProps>`
  display: grid;
  grid-template-columns: 16px 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 4px;
`;

export interface RequirementItemIconProps {
  error: boolean;
}

export const RequirementItemIcon = styled(Icon)<RequirementItemIconProps>`
  ${({ error }) => css`
    path {
      fill: ${error ? 'var(--state-error)' : 'var(--state-success)'};
    }
  `};
`;
