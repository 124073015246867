import React, { memo } from 'react';

import Button from 'components/_Redesign/Button';

import { PromptStyled } from './styles';

interface PromptProps {
  text: string;
  label?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Prompt: React.FC<PromptProps> = ({ text, label, onClick }) => (
  <PromptStyled>
    <p>{text}</p>
    {label && onClick ? (
      <Button color="primary" size="lg" shape="full" label={label} onClick={onClick} />
    ) : null}
  </PromptStyled>
);

export default memo(Prompt);
